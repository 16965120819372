import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  createUpdateState(data: any) {
    return this.http.post(`${this.url}/v1/location/createUpdateState`, data).pipe(catchError(this.errorHandler));
  }

  createUpdateCity(data: any) {
    return this.http.post(`${this.url}/v1/location/createUpdateCity`, data).pipe(catchError(this.errorHandler));
  }

  autoCompleteAddress(input: any) {
    return this.http.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${environment.GAPI_KEY}&input=${input}`).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
