import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  url: string;
  user: any = null;

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  setUser(user: any) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  login(cred: any) {
    return this.http.post(`${this.url}/v1/users/login`, cred).pipe(catchError(this.errorHandler));
  }

  getStates() {
    return this.http.get(`${this.url}/v1/location/getStates`).pipe(catchError(this.errorHandler));
  }

  findAll(data: any) {
    return this.http.get(`${this.url}/v1/users/findAll`, { params: data }).pipe(catchError(this.errorHandler));
  }

  createUpdateUser(data: any) {
    return this.http.post(`${this.url}/v1/users/createUpdateUser`, data).pipe(catchError(this.errorHandler));
  }

  getCities(data: any) {
    return this.http.get(`${this.url}/v1/location/getCities`, { params: data }).pipe(catchError(this.errorHandler));
  }

  forgetPassword(data: any) {
    return this.http.post(`${this.url}/v1/users/forgetPassword`, data).pipe(catchError(this.errorHandler));
  }

  verfifyOtp(data: any) {
    return this.http.post(`${this.url}/v1/users/verfifyOtp`, data).pipe(catchError(this.errorHandler));
  }

  updatePassword(data: any) {
    return this.http.post(`${this.url}/v1/users/updatePassword`, data).pipe(catchError(this.errorHandler));
  }

  sendEmail(data: any) {
    return this.http.post(`${this.url}/v1/users/sendEmail`, data).pipe(catchError(this.errorHandler));
  }

  getMassEmail(data: any) {
    return this.http.get(`${this.url}/v1/users/getMassEmail`, { params: data }).pipe(catchError(this.errorHandler));
  }

  createLoginLog(cred: any) {
    return this.http.post(`${this.url}/v1/users/createLoginLog`, cred).pipe(catchError(this.errorHandler));
  }

  getLoginLog(data: any) {
    return this.http.get(`${this.url}/v1/users/getLoginLog`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
