<div class="container-fluid bg-white">
    <form name="promotionForm" [formGroup]="promotionForm" (ngSubmit)="onSubmit()">
        <h2>Promotion </h2>
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name </mat-label>
                    <input matInput [(ngModel)]="promotion.name" required formControlName="name">
                    <mat-error> Name is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Description </mat-label>
                    <input matInput [(ngModel)]="promotion.desc" formControlName="desc">
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Promotion Valid Date Range</mat-label>
                    <mat-date-range-input [rangePicker]="campaignTwoPicker" [comparisonStart]="promotion.start_date" [comparisonEnd]="promotion.end_date">
                        <input matStartDate placeholder="Start date" formControlName="start" [(ngModel)]="promotion.start_date">
                        <input matEndDate placeholder="End date" formControlName="end" [(ngModel)]="promotion.end_date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="campaignTwoPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #campaignTwoPicker></mat-date-range-picker>
                    <mat-error> Date Range is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Order Amount </mat-label>
                    <input matInput [(ngModel)]="promotion.order_amount" required min="0" type="number" formControlName="order_amount">
                    <mat-error> Order Amount is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Promotion Amount </mat-label>
                    <input matInput (input)="setPriceValidator('price')" [(ngModel)]="promotion.amount" min="0" type="number" formControlName="amount">
                    <mat-error> Promotion Amount is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Promotion Percentage </mat-label>
                    <input matInput (input)="setPriceValidator('percent')" [(ngModel)]="promotion.percentage" min="0" max="100" type="number" formControlName="percentage">
                    <mat-error> Promotion Percentage is Required </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
                <button mat-flat-button type="submit" class="bg-success text-white w-50">Submit </button>
            </div>
        </div>
    </form>
</div>