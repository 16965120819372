import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexTitleSubtitle,
  ApexYAxis
} from "ng-apexcharts";
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from './dashboard.service';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  BarGraphTitle: string = '';

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  cards: any = {
    new_orders: 0,
    orders_amount: 0,
    users: 0,
    cancelled_orders_count: 0,
    last_days_count: 0,
    avg_sale: 0
  }

  constructor(private dashboardService: DashboardService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.fetchDashboardData(4);
  }

  fetchDashboardData(type: number) {
    const data = { type: type };
    this.spinner.show();
    this.dashboardService.fetchDashboardData(data).subscribe((res: any) => {
      console.log(res);
      this.spinner.hide();
      if (res.status) {
        this.cards.new_orders = res.newOrders;
        this.cards.orders_amount = 0;
        this.cards.users = res.users;
        this.cards.cancelled_orders_count = 0;
        this.cards.avg_sale = 0;
        this.cards.last_days_count = res.lastDaysCount;
        this.BarGraphTitle = `Orders for Last ${this.cards.last_days_count} Days`;
        let categories = [];
        let acceptedOrders = [];
        let cancelledOrders = [];
        if (res.data && res.data.length) {
          res.data.map((order: any) => {
            if (order.acceptedOrder || order.cancelledOrder) {
              const month = this.monthList[new Date(order.date).getMonth()];
              categories.push(month.slice(0, 3) + ' ' + new Date(order.date).getDate());
              acceptedOrders.push(order?.acceptedOrder || 0);
              cancelledOrders.push(order?.cancelledOrder || 0);
              if (order.cancelledOrder) this.cards.cancelled_orders_count += order.cancelledOrder;
              if (order.acceptedOrder) this.cards.orders_amount += order.order_total;
            }
          });
        }
        if (this.cards.orders_amount && this.cards.last_days_count) {
          this.cards.avg_sale = this.cards.orders_amount / this.cards.last_days_count;
          this.cards.avg_sale = parseFloat(this.cards.avg_sale).toFixed(2);
        }

        this.chartOptions = {
          series: [
            {
              name: "Cancelled Orders",
              data: cancelledOrders
            },
            {
              name: "Accepted Orders",
              data: acceptedOrders
            }
          ],
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 40
                }
              }
            }
          ],
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            type: "category",
            categories: categories
          },
          legend: {
            position: "right",
            offsetY: 40
          },
          fill: {
            opacity: 1,
            colors: ['#FF565D', '#00E396']
          }
        };
      }
    }, (err: any) => {
      this.spinner.hide();
      console.log(err);
    })
  }

}
