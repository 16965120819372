import { Component, OnInit, QueryList, ViewChild, ViewChildren, ElementRef, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { MatAccordion } from '@angular/material/expansion';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '../../product/category.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  categoryList: any[];
  cateogrypanelFormOpenState: boolean = false;
  subcateogrypanelFormOpenState: boolean = false;
  questpanelFormOpenState: boolean = false;

  cateogrypanelListOpenState: boolean = true;
  subcateogrypanelListOpenState: boolean = false;
  questpanelListOpenState: boolean = false;


  categoryDataSource: any = new MatTableDataSource();
  categoryPage = { length: 0, pageIndex: 0, pageSize: 5 };
  SubcategoryDataSource: any = new MatTableDataSource();
  SubcategoryPage = { length: 0, pageIndex: 0, pageSize: 5 };
  QuestionDataSource: any = new MatTableDataSource();
  QuestionPage = { length: 0, pageIndex: 0, pageSize: 5 };
  productList: any;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  dataSource: any = new MatTableDataSource();
  tabName = '0';
  disableCategorySubmit: boolean = false;
  disableSubCategorySubmit: boolean = false;
  disableQuestionSubmit: boolean = false;


  categoryColumns: string[] = ['sno', 'name','is_active','action'];
  subcategoryColumns: string[] = ['sno','category', 'name','is_active','action'];
  questionColumns: string[] = ['sno','category','subcategory', 'question','answer','is_active','action'];


  private searchSub!: Subject<string>;
  formDirective: any;

  formObj = {
    category: false,
    subcategory: false,
    question: false,
    brand: false,
    menuList: false
  }
  Category: any = {
    name: '',
    is_active: true,
    created_at: '',
    id: null
  };
  CategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    is_active: new FormControl('')
  });
  Subcategory: any = {
    name: '',
    ques_category_id:'',
    is_active: true,
    created_at: '',
    id: null
  };
  SubcategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    ques_category_id: new FormControl('', [Validators.required]),
    is_active: new FormControl('')
  });
  Question: any = {
    question: '',
    answer:'',
    ques_category_id:'',
    ques_subcategory_id:'',
    is_active: true,
    created_at: '',
    id: null
  };
  QuestionForm = new FormGroup({
    question: new FormControl('', [Validators.required]),
    answer: new FormControl('', [Validators.required]),
    ques_category_id: new FormControl('', [Validators.required]),
    ques_subcategory_id: new FormControl('', [Validators.required]),
    is_active: new FormControl('')
  });
  public searchString!: string;
  subcategoryList: any;
  filteredBrandDropList: any;
  filteredCategoryDropList: any;
  filteredSubCategoryDropList: any;
  categoryDropList: any;
  subcategoryDropList: any;
  questionList: any;

  constructor(private appService:AppService,private spinner: NgxSpinnerService, private dialog: MatDialog, private toastr: ToastrService,private categoryService: CategoryService,) { }

  ngOnInit(): void {
    this.getCategoryList()
    this.getSubCategoryList();

    this.searchSub = new Subject();
    this.searchSub?.pipe(debounceTime(500))?.subscribe((res) => {
      if (this.tabName == '0' || this.tabName == '1') {
        this.filterRecords(res);
      }
      else {
        this.searchString = res;
      }
    });
  }
  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 100);
  }

  applyFilter(value: string): void {
    this.searchSub.next(value);
  }
  search(value: string, type: string) {
   
  if (type == 'category') {
      this.filteredCategoryDropList = this.categoryList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
    else {
      this.filteredSubCategoryDropList = this.subcategoryList.filter((city) => {
        let lowerCase = city.name.toLocaleLowerCase();
        return city.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
  }
  getCategoryList() {
    if(this.appService.getRestaurantId())
    var data = {store_id: this.appService.getRestaurantId()}  
    this.spinner.show();
    this.categoryService.findAllQuestCategories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.categoryList = res.data;
        this.categoryDataSource = new MatTableDataSource(this.categoryList);
        this.categoryPage.length = this.categoryList?.length;
        this.getPaginator(this.categoryDataSource, 0);
        this.filteredCategoryDropList = [...this.categoryList]

      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }
  getSubCategoryList() {
    // if(this.appService.getRestaurantId())
    // var data = {store_id: this.appService.getRestaurantId()}  
    this.spinner.show();
    this.categoryService.findAllQuestSubCategories().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.subcategoryList = res.data;
        this.SubcategoryDataSource = new MatTableDataSource(this.subcategoryList);
        this.SubcategoryPage.length = this.subcategoryList?.length;
        this.getPaginator(this.SubcategoryDataSource, 0);
        this.filteredSubCategoryDropList = [...this.subcategoryList]

      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }
  getQuestionList() {
    // if(this.appService.getRestaurantId())
    // var data = {store_id: this.appService.getRestaurantId()}  
    this.spinner.show();
    this.categoryService.findAllQuestion().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.questionList = res.data;
        this.QuestionDataSource = new MatTableDataSource(this.questionList);
        this.QuestionPage.length = this.questionList?.length;
        this.getPaginator(this.QuestionDataSource, 0);
        // this.filteredSubCategoryDropList = [...this.subcategoryList]

      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }
  openTab(val) {
    this.tabName = val;
    if (val == '0')
      this.getCategoryList();
    else if (val == '1') {
      this.getSubCategoryList();
      // this.getCategoryDropList();
    }
    else if (val == '2') {
      this.getQuestionList()
      // this.getBrandList();
      // this.getCategoryDropList();
      // this.formObj.menuList = true;
    }
    // else
      // this.loadBrandList = true;
  }
  private filterRecords(str: string) {
    const trimmeredVal = str?.trim()?.toLowerCase();
    let filtered = [];
    if (this.tabName == '1') {
      filtered = [...this.subcategoryList?.filter(val => val?.name?.toLowerCase()?.includes(trimmeredVal) ||  val?.ques_category?.name?.toLowerCase()?.includes(trimmeredVal))];
      this.SubcategoryDataSource = new MatTableDataSource(filtered);
      this.SubcategoryPage.length = filtered?.length;
    }
    else {
      filtered = [...this.categoryList?.filter(val => val?.name?.toLowerCase()?.includes(trimmeredVal))];
      this.categoryDataSource = new MatTableDataSource(filtered);
      this.categoryPage.length = filtered?.length;
    }
  }
  editCategory(category: any) {
    this.formObj.category = true;
    this.Category = category;
    // this.categoryImage.src = this.Category.picture ? `${this.apiUrl}/${this.Category.picture}` : '';
    this.cateogrypanelFormOpenState = true;
  }
  editsubCategory(category: any) {
    this.formObj.subcategory = true;
    this.Subcategory = category;
    // this.categoryImage.src = this.Category.picture ? `${this.apiUrl}/${this.Category.picture}` : '';
    this.subcateogrypanelFormOpenState = true;
  }
  editQuest(category: any) {
    this.formObj.question = true;
    this.Question = category;
    // this.categoryImage.src = this.Category.picture ? `${this.apiUrl}/${this.Category.picture}` : '';
    this.questpanelFormOpenState = true;
  }
  cateForm() {
    this.formObj.category = !this.formObj.category;
    this.Category.id=''
    this.Category.name=''

  }
  subcateForm() {
    this.formObj.subcategory = !this.formObj.subcategory;
    this.Subcategory.id=''
    this.Subcategory.name=''
    this.Subcategory.ques_category_id=''


  }
  QuestForm() {
    this.formObj.question = !this.formObj.question;
    this.Question.id=''
    this.Question.question=''
    this.Question.answer=''
    this.Question.ques_category_id=''
    this.Question.ques_subcategory_id=''


  }
  submitCategory(formDirective: any) {
    if (this.CategoryForm.invalid)
      return
    else {
      this.disableCategorySubmit = true;
      this.formDirective = formDirective;
      const formData = new FormData();
      // formData.append('Image', this.categoryImage.file);
      // formData.append('data', JSON.stringify(this.Category));
      this.spinner.show();
      this.categoryService.createUpdateQuestCategory(this.Category).subscribe((res: any) => {
        this.formObj.category = false;
        this.disableCategorySubmit = false;
        this.spinner.hide();
        if (res.status) {
          this.toastr.success(res.message, 'Success');
          this.getCategoryList();
          // this.getCategoryDropList();
          this.cateogrypanelListOpenState = true;
          this.formDirective.resetForm();
          this.CategoryForm.reset();
          // this.categoryImage = { name: '', src: '', file: '' };
        }
        else this.toastr.error(res.message, 'Error')
      },
        (err) => {
          this.disableCategorySubmit = false;
          this.spinner.hide();
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }
  submitSubCategory(formDirective: any) {
    if (this.SubcategoryForm.invalid)
      return
    else {
      this.disableSubCategorySubmit = true;
      this.formDirective = formDirective;

      this.spinner.show();
      this.categoryService.createUpdateQuestSubCategory(this.Subcategory).subscribe((res: any) => {
        this.formObj.subcategory = false;
        this.disableSubCategorySubmit = false;
        this.spinner.hide();
        if (res.status) {
          this.toastr.success(res.message, 'Success');
          this.getSubCategoryList();
          // this.getCategoryDropList();
          this.subcateogrypanelListOpenState = true;
          this.formDirective.resetForm();
          this.SubcategoryForm.reset();
          // this.categoryImage = { name: '', src: '', file: '' };
        }
        else this.toastr.error(res.message, 'Error')
      },
        (err) => {
          this.disableSubCategorySubmit = false;
          this.spinner.hide();
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }
  submitQuestion(formDirective: any) {
    if (this.QuestionForm.invalid)
      return
    else {
      this.disableQuestionSubmit = true;
      this.formDirective = formDirective;

      this.spinner.show();
      this.categoryService.createUpdateQuestion(this.Question).subscribe((res: any) => {
        this.formObj.question = false;
        this.disableQuestionSubmit = false;
        this.spinner.hide();
        if (res.status) {
          this.toastr.success(res.message, 'Success');
          this.getQuestionList();
          // this.getCategoryDropList();
          this.questpanelListOpenState = true;
          this.formDirective.resetForm();
          this.QuestionForm.reset();
          // this.categoryImage = { name: '', src: '', file: '' };
        }
        else this.toastr.error(res.message, 'Error')
      },
        (err) => {
          this.disableQuestionSubmit = false;
          this.spinner.hide();
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }
}
