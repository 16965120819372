<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<div class="container-fluid bg-white">
  <form name="restaurantForm" [formGroup]="restaurantForm" (ngSubmit)="onSubmit()">
    <h2>Store Configuration </h2>
    <div class="row mb-2">
      <div class="col-4">
          <h5>Take Online Orders </h5>
      </div>
      <div class="col-8">
          <mat-slide-toggle [(checked)]="resSetting.order_status"
              (change)="resSetting.order_status = !resSetting.order_status" formControlName="order_status">
          </mat-slide-toggle>
      </div>
  </div>
    <div class="border my-5 pt-4 px-3 position-relative">
      <h4 class="position-absolute px-3">General</h4>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name </mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="restaurantForm?.controls?.name?.errors?.required"> Name is Required </mat-error>
            <mat-error *ngIf="restaurantForm?.controls?.name?.errors?.minlength"> Minimum characters should be {{restaurantForm?.controls?.name?.errors?.minlength?.requiredLength}}</mat-error>
            <mat-error *ngIf="restaurantForm?.controls?.name?.errors?.maxlength"> Maximum characters should be {{restaurantForm?.controls?.name?.errors?.maxlength?.requiredLength}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-8">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Description </mat-label>
            <input matInput formControlName="desc">
            <mat-error *ngIf="restaurantForm?.controls?.desc?.errors?.maxlength"> Maximum characters should be {{restaurantForm?.controls?.desc?.errors?.maxlength?.requiredLength}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">

        <!-- <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Phone Number </mat-label>
            <input matInput minlength="7" maxlength="10" type="number" [(ngModel)]="restaurant.phone" required
              formControlName="phone">
            <mat-error> Enter a Valid Phone Number </mat-error>
          </mat-form-field>
        </div> -->

        <div class="col-sm-12 col-md-4">
          <ngx-intl-tel-input class="form-control h-52px mt-1 d-flex align-items-center" matInput
            [cssClass]="'phoneNumberClass'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
            [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10"
            [phoneValidation]="true" [separateDialCode]="false" [numberFormat]="PhoneNumberFormat.National"
            name="phoneno" [(ngModel)]="res_phoneobj" [ngModelOptions]="{standalone: true}">
          </ngx-intl-tel-input>
          <input type="hidden" name="" formControlName="info_mobile">
        </div>

        <div class="col-sm-12 col-md-8">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email </mat-label>
            <input matInput type="email"
              (input)="restaurantForm?.controls?.info_email?.status === 'VALID' && checkAlreadyExists('restaurant', 'info_email', restaurantForm?.value?.info_email, 'info_email')"
              required formControlName="info_email" [readonly]="restaurantId">
            <mat-error *ngIf="restaurantForm?.controls?.email?.errors?.pattern"> Enter a Valid Email </mat-error>
            <mat-error *ngIf="restaurantForm?.controls?.email?.errors?.required"> Email is Required </mat-error>
            <mat-error *ngIf="restaurantForm?.controls?.email?.errors?.minlength"> Minimum characters should be {{restaurantForm?.controls?.email?.errors?.minlength?.requiredLength}}</mat-error>
            <mat-error *ngIf="restaurantForm?.controls?.email?.errors?.maxlength"> Maximum characters should be {{restaurantForm?.controls?.email?.errors?.maxlength?.requiredLength}}</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Address Line 1</mat-label>
            <input matInput required formControlName="address1">
            <mat-error> Address 1 is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Address Line 2</mat-label>
            <input matInput required formControlName="address2">
            <mat-error> Address 2 is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Address Line 3</mat-label>
            <input matInput formControlName="address3">
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Zip Code </mat-label>
            <input matInput (input)="restaurantForm.controls.zip.valid && findStateCityByZip()" required
              formControlName="zip">
            <mat-error>Enter a Valid Zip Code </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Latitude </mat-label>
            <input matInput minlength="2" maxlength="50" type="number" required formControlName="lat">
            <mat-error>Latitude is required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Longitude </mat-label>
            <input matInput minlength="2" maxlength="50" type="number" required formControlName="long">
            <mat-error>Longitude is required </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>State</mat-label>
            <mat-select
              (selectionChange)="restaurantForm?.value?.state_id && getCityList(restaurantForm?.value?.state_id)"
              required formControlName="state_id">
              <input (keyup)="search($event.target.value, 'state')" class="form-control w-100"
                placeholder="Search State">
              <mat-option>Select State</mat-option>
              <mat-option *ngFor="let state of filteredStateList" [value]="state.id">
                {{state.name + ' (' + state.code + ')'}}
              </mat-option>
            </mat-select>
            <mat-error> State is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>City</mat-label>
            <mat-select required formControlName="city_id">
              <input (keyup)="search($event.target.value, 'city')" class="form-control w-100" placeholder="Search City">
              <mat-option>Select City</mat-option>
              <mat-option *ngFor="let city of filteredCityList" [value]="city.id">
                {{city.name}}
              </mat-option>
            </mat-select>
            <mat-error> City is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-2 d-flex align-items-center">
          <mat-checkbox formControlName="is_active">Is Active</mat-checkbox>
        </div>
      </div>
      <div class="row justify-content-start px-3 my-2">
        <div class="col-sm-12 col-md-2">
          <img class="choose-img rounded" [src]="image?.src" (click)="uploadPicture()">
          <input type="file" name="file" #file accept="image/png, image/jpg, image/jpeg" class="d-none"
            (change)="readFile($event)">
        </div>
      </div>
    </div>

    <div class="border my-5 pt-4 px-3 position-relative">
      <h4 class="position-absolute px-3">Alternative Zipcodes</h4>
      <div class="row">
        <div class="col-sm-12 col-md-auto">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Zip Code 1</mat-label>
            <input matInput required formControlName="alter_zip1">
            <mat-error>Enter a Valid Zip Code </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Zip Code 2</mat-label>
            <input matInput required formControlName="alter_zip2">
            <mat-error>Enter a Valid Zip Code </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Zip Code 3</mat-label>
            <input matInput required formControlName="alter_zip3">
            <mat-error>Enter a Valid Zip Code </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Zip Code 4</mat-label>
            <input matInput formControlName="alter_zip4">
            <mat-error>Enter a Valid Zip Code </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Zip Code 5</mat-label>
            <input matInput formControlName="alter_zip5">
            <mat-error>Enter a Valid Zip Code </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="border my-5 pt-4 px-3 position-relative">
      <h4 class="position-absolute px-3">Store Settings</h4>
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Copy Product & Variants From Store </mat-label>
            <mat-select [disabled]="restaurantId" (selectionChange)="updateValidation()" formControlName="store">
              <input (keyup)="search($event.target.value, 'store')" class="form-control w-100"
                placeholder="Search Store" />
              <mat-option>None</mat-option>
              <mat-option *ngFor="let store of filteredStoreList" [value]="store.id">
                {{ store.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Category </mat-label>
            <mat-select [disabled]="restaurantForm?.value?.store && !restaurantId" required formControlName="category" multiple (openedChange)="openedChange($event)">
              <input (keyup)="search($event.target.value, 'category')" class="form-control w-100"
                placeholder="Search Category" />
              <mat-option *ngFor="let category of filteredCategoryList" [value]="category.id">
                {{ category.name }}
              </mat-option>
            </mat-select>
            <mat-error> Category is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Sub Category </mat-label>
            <mat-select [disabled]="restaurantForm?.value?.store && !restaurantId" required formControlName="sub_category" multiple>
              <input (keyup)="search($event.target.value, 'subcategory')" class="form-control w-100"
                placeholder="Search Sub Category" />
              <mat-option *ngFor="let subCategory of filteredSubCategoryList" [value]="subCategory.id">
                {{ subCategory.name }}</mat-option>
            </mat-select>
            <mat-error> Sub Category is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3 d-flex align-items-center">
          <mat-checkbox formControlName="has_delivery">Has Delivery</mat-checkbox>
        </div>
        <!-- <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Plans </mat-label>
            <mat-select [disabled]="restaurantId" required formControlName="plan">
              <input (keyup)="search($event.target.value, 'plan')" class="form-control w-100"
                placeholder="Search Plan" />
              <mat-option *ngFor="let plan of filteredPlanList" [value]="plan.id">
                {{ plan.name }}
              </mat-option>
            </mat-select>
            <mat-error> Category is Required </mat-error>
          </mat-form-field>
        </div> -->
      </div>
    </div>

    <div class="border my-5 pt-4 px-3 position-relative">
      <h4 class="position-absolute px-3">Order Settings</h4>
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Average Time For Order In Minutes</mat-label>
            <input matInput type="number" required formControlName="avg_time_order">
            <mat-error> Average Time is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Average Time For Pending Order In Minutes</mat-label>
            <input matInput type="number" required formControlName="avg_time_pending_order">
            <mat-error> Pending Average Time For is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tax (%)</mat-label>
            <input matInput type="number" required formControlName="tax">
            <mat-error> Tax is Required </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="border my-5 pt-4 px-3 position-relative">
      <h4 class="position-absolute px-3">Admin details</h4>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name </mat-label>
            <input matInput type="text" formControlName="admin_name" required>
            <mat-error> Name is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email </mat-label>
            <input matInput
              (input)="restaurantForm?.controls?.admin_email?.status === 'VALID' && checkAlreadyExists('users', 'email', restaurantForm?.value?.admin_email, 'admin_email')"
              [readonly]="restaurantId" type="email" formControlName="admin_email" required>
            <mat-error>Enter a Valid Email </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-2">
          <ngx-intl-tel-input class="form-control h-52px mt-1 d-flex align-items-center"
            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [phoneValidation]="true"
            [separateDialCode]="false" [numberFormat]="PhoneNumberFormat.National" name="phone_admin"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="admin_phoneobj">
          </ngx-intl-tel-input>
        </div>
      </div>
    </div>

    <!-- <div class="border my-5 pt-4 px-3 position-relative">
      <h4 class="position-absolute px-3">Payment Info</h4>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Bank Name </mat-label>
            <input matInput [(ngModel)]="restaurant.bank_name" type="text" required formControlName="bank_name">
            <mat-error> Bank Name is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Account Holder Name </mat-label>
            <input matInput [(ngModel)]="restaurant.acc_name" type="text" required formControlName="acc_name">
            <mat-error> Account Holder is Required </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Account Number </mat-label>
            <input matInput [(ngModel)]="restaurant.acc_no" required
              formControlName="acc_no">
            <mat-error>Enter a Valid Account Number </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 d-flex align-items-center">
          <label class="mr-4">Payment through third party</label>
          <div>
            <mat-checkbox class="mx-3" [checked]="restaurant.payment"
              (change)="restaurant.payment = !restaurant.payment">Yes</mat-checkbox>
            <mat-checkbox class="mx-3" [checked]="!restaurant.payment"
              (change)="restaurant.payment = !restaurant.payment">No</mat-checkbox>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row justify-content-end">
      <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
        <button mat-flat-button type="submit" class="bg-success text-white w-50">Submit </button>
      </div>
    </div>
  </form>
</div>