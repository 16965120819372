import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextEllipsisModule } from '../text-ellipsis/text-ellipsis.module';
import { GlobalSubCategoryComponent } from './global-sub-category.component';


@NgModule({
    declarations: [GlobalSubCategoryComponent],
    imports: [
        CommonModule,
        SharedModule,
        TextEllipsisModule
    ],
    exports: [GlobalSubCategoryComponent]
})
export class GlobalSubCategoryModule { }
