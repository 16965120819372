import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextEllipsisModule } from '../text-ellipsis/text-ellipsis.module';
import { ProductVariantListComponent } from './product-variant-list.component';


@NgModule({
    declarations: [ProductVariantListComponent],
    imports: [
        CommonModule,
        SharedModule,
        TextEllipsisModule
    ],
    exports: [ProductVariantListComponent]
})
export class ProductVariantListModule { }
