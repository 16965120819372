import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { ResSettingsComponent } from './res-settings/res-settings.component';
import { SharedModule } from '../shared/shared.module';
import { NotficationSettingsComponent } from './notfication-settings/notfication-settings.component';
import { DeliveryPersonListComponent } from './delivery-person-list/delivery-person-list.component';
import { DeliveryPersonFormModule } from '../shared/delivery-person-form/delivery-person-form.module';


@NgModule({
  declarations: [ResSettingsComponent, NotficationSettingsComponent, DeliveryPersonListComponent],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
    DeliveryPersonFormModule
  ]
})
export class SettingsModule { }
