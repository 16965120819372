import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  url: string = '';
  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  generatePaymentBatch(data: any) {
    return this.http.post(`${this.url}/v1/payment/generatePaymentBatch`, data).pipe(catchError(this.errorHandler));
  }

  getPaymentBatch(data: any) {
    return this.http.get(`${this.url}/v1/payment/getPaymentBatch`, { params: data }).pipe(catchError(this.errorHandler));
  }

  getPendingPayments(data: any) {
    return this.http.get(`${this.url}/v1/payment`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
