import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RestaurantService } from '../restaurant/restaurant.service';
import { UserService } from 'src/app/auth/user/user.service';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-mass-communication',
  templateUrl: './mass-communication.component.html',
  styleUrls: ['./mass-communication.component.scss']
})
export class MassCommunicationComponent implements OnInit {

  viewMode: boolean = false;
  spinnerText: string = '';
  attachment: any;
  emailForm = new FormGroup({
    store: new FormControl(0, [Validators.required]),
    role: new FormControl(0, [Validators.required]),
    subject: new FormControl('', [Validators.required]),
    content: new FormControl('', [Validators.required])
  });
  massCommunicationList: any = [];
  restaurantList: any = [];
  filteredRestaurantList: any = [];
  roleList = [
    { id: 0, name: 'All Users' },
    { id: 2, name: 'Admin' },
    { id: 3, name: 'Manager' },
    { id: 4, name: 'Employee' }
  ]
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'restaurant', 'role', 'status', 'recipients', 'subject', 'content', 'created_at', 'action'];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  apiUrl: string = '';
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild('sendMailModal') private sendMailModal!: TemplateRef<any>;
  @ViewChild('file') file!: ElementRef;
  fileName: string = '';
  fileSrc: string = '';

  constructor(private dialog: MatDialog, private restaurantService: RestaurantService, private spinner: NgxSpinnerService, private toastr: ToastrService, private userService: UserService) { }

  ngOnInit(): void {
    this.apiUrl = environment.API_URL;
    this.getMassEmail();
    this.getRestaurantList();
  }

  openEmailModal(element: any) {
    if (element) {
      this.fileSrc = this.apiUrl + '/' + element.picture;
      this.fileName = element.picture ? element.picture.split('uploads\\attachments\\')[1] : '';
      this.viewMode = true;
      this.emailForm.controls.store.patchValue(element.store_id);
      this.emailForm.controls.role.patchValue(element.role_id);
      this.emailForm.controls.subject.patchValue(element.subject);
      this.emailForm.controls.content.patchValue(element.content);
    }
    else {
      this.fileName = '';
      this.viewMode = false;
      this.emailForm.reset();
      this.emailForm.controls.store.patchValue(0);
      this.emailForm.controls.role.patchValue(0);
    }
    this.dialog.open(this.sendMailModal, {
      width: '60vw !important',
      height: '88vh', minWidth: '60vw'
    });
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.attachment = file;
    this.fileName = file.name;
  }

  uploadAttachment() {
    this.file.nativeElement.click();
  }

  getMassEmail() {
    this.spinnerText = 'Fetching Mass Communication Data';
    this.spinner.show();
    this.userService.getMassEmail({}).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.massCommunicationList = res.data;
        this.dataSource = new MatTableDataSource(this.massCommunicationList);
        this.page.length = this.massCommunicationList.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else this.toastr.error(res.message, 'Error');
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    )
  }

  getRestaurantList() {
    this.spinnerText = 'Fetching Restaurant Data';
    this.spinner.show();
    this.restaurantService.findAllRestaurants({}).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.restaurantList = res.data || [];
        this.restaurantList.unshift({ id: 0, name: 'All Stores' });
        this.filteredRestaurantList = this.restaurantList.slice();
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  onSubmit(formDirective: any) {
    if(this.emailForm?.invalid) {
      this.toastr.warning('Please fill the required fields', 'Warning');
      return;
    }
    this.spinnerText = 'Sending Email';
    this.spinner.show();
    const formData = new FormData();
    formData.append('attachment', this.attachment);
    formData.append('data', JSON.stringify(this.emailForm.value));
    this.userService.sendEmail(formData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.dialog.closeAll();
        this.getMassEmail();
        this.toastr.success(res.message, 'Success');
      }
      else this.toastr.error(res.message, 'Error');
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    )
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

}
