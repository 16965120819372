<div class="row mx-0 my-3">
    <div class="col-md-12">
        <form [formGroup]="productVariantForm" (ngSubmit)="onSubmit()">
            <div class="row my-2">
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Unit </mat-label>
                        <mat-select [disabled]="appService?.getUserRole() != 1 && appService?.getUserRole() != 9 && productVariantId" required formControlName="unit">
                            <mat-option value="">Select Unit</mat-option>
                            <mat-option *ngFor="let unit of posssible_variants" [value]="unit">{{unit}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Unit is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Value </mat-label>
                        <input [readonly]="appService?.getUserRole() != 1 && appService?.getUserRole() != 9 && productVariantId" matInput min="0" type="number" formControlName="desc" />
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4" *ngIf="appService?.getUserRole() != 1 && appService?.getUserRole() != 9">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Price $</mat-label>
                        <input matInput type="number" min="0" formControlName="price" />
                        <mat-error>Product Price is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 d-flex align-items-center" *ngIf="appService?.getUserRole() != 1 && appService?.getUserRole() != 9">
                    <mat-checkbox (change)="updateValidation()" formControlName="has_limit">
                    </mat-checkbox>
                    <label class="mr-3 pl-2">Has Daily Limit</label>
                </div>
                <div class="col-sm-12 col-md-4" *ngIf="productVariantForm.value.has_limit && appService?.getUserRole() != 1 && appService?.getUserRole() != 9">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Daily Limit </mat-label>
                        <input matInput (input)="productVariantForm?.controls?.quantity?.patchValue(productVariantForm?.value?.limit)" formControlName="limit" type="number" />
                        <mat-error>Daily Limit is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4" *ngIf="productVariantForm.value.has_limit && appService?.getUserRole() != 1 && appService?.getUserRole() != 9">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Quantity </mat-label>
                        <input matInput formControlName="quantity" type="number" />
                        <mat-error>Quantity is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 d-flex align-items-center">
                    <mat-checkbox formControlName="is_active">
                    </mat-checkbox>
                    <label class="mr-3 pl-2">Is Active</label>
                </div>
            </div>

            <div class="row">
                <!-- <div class="mx-3 image" *ngIf="image && image.src">
                    <img class="w-100 h-100 border-radius-15px" [src]="image.src" [alt]="image.name" />
                    <p class="mt-3">{{ image.name }}</p>
                </div> -->
                <div class="col-sm-12 col-md-2">
                    <img class="rounded choose-img" [src]="image.src" alt="Choose file" (click)="choosePicture()">
                    <!-- <button mat-flat-button color="primary" type="button" (click)="choosePicture()">
                        Upload Product Variant Picture
                    </button> -->
                    <input type="file" #file accept="image/png, image/gif, image/jpeg" class="d-none"
                        (change)="readFile($event)" multiple />
                </div>
            </div>
            <div class="row justify-content-end mt-2">
                <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
                    <button mat-flat-button type="submit" class="bg-success text-white w-50">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>