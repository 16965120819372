import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BrandService } from 'src/app/admin/product/brand.service';
import { CategoryService } from 'src/app/admin/product/category.service';
import { SubcategoryService } from 'src/app/admin/product/subcategory.service';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { AppService } from 'src/app/app.service';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ReportService } from '../report.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-price-report',
  templateUrl: './price-report.component.html',
  styleUrls: ['./price-report.component.scss']
})
export class PriceReportComponent implements OnInit {

  public storeList!: any[];
  public filteredStoreList!: any[];
  public categoryDropList!: any[];
  public filteredCategoryDropList!: any[];
  public subCategoryDropList: any = [];
  public filteredSubCategoryDropList: any = [];
  public filteredBrandDropList: any = [];
  public brandDropList: any = [];
  public filterForm!: FormGroup;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  public dataSource: any = new MatTableDataSource();
  public tableColumns: string[] = ['sno', 'product', 'unit', 'picture', 'brand', 'category', 'sub_category', 'analysis'];
  public selectedStoreName!: string;
  public pageSizeOptions = [5, 10, 20];
  public page = { length: 0, pageIndex: 0, pageSize: 5 };
  public apiUrl!: string;

  constructor(private subcategoryService: SubcategoryService, private spinner: NgxSpinnerService, private categoryService: CategoryService, private brandService: BrandService, private toastr: ToastrService, public appService: AppService, private restaurantService: RestaurantService, private reportService: ReportService) { }

  ngOnInit(): void {
    this.apiUrl = environment.API_URL;
    this.getBrandList();
    this.getDropdownStores();
    this.initializeForm();
  }

  private initializeForm(): void {
    this.filterForm = new FormGroup({
      store_id: new FormControl(null, [Validators.required]),
      brand_id: new FormControl(null),
      category_id: new FormControl(null),
      sub_category_id: new FormControl(null)
    });
  }

  public getPriceReport(): void {
    if (this.filterForm?.invalid) {
      this.toastr.warning('Please choose the required fields !...', 'Warning');
      return;
    }
    this.selectedStoreName = this.storeList?.find(val => val?.id === this.filterForm?.value?.store_id)?.name;
    this.spinner.show();
    this.reportService.getPriceReport(this.filterForm?.value).subscribe((res: any) => {
      if (!res?.status) {
        this.toastr.error(res?.message, 'Error');
      }
      const data = res?.data || []
      this.dataSource = new MatTableDataSource(data);
      this.page.length = data?.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  private getDropdownStores(): void {
    this.spinner.show();
    this.restaurantService.getStoreByZip({}).subscribe((res: any) => {
      if (res?.status) {
        this.storeList = res?.data;
        this.filteredStoreList = [...this.storeList];
      }
      else
        this.toastr.error(res?.message, 'Error');
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  private getBrandList(): void {
    const data = {}
    this.spinner.show();
    this.brandService.findAllBrand(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.brandDropList = res.data;
        this.filteredBrandDropList = [...this.brandDropList];
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  public getSubCategoryDropList(): void {
    const data = {
      store_id: this.filterForm?.value?.store_id,
      category_id: this.filterForm?.value?.category_id
    };
    this.spinner.show();
    this.subcategoryService.findSubCategoryWithTargetStore(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.subCategoryDropList = res.data;
        this.filteredSubCategoryDropList = [...this.subCategoryDropList];
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  public getCategoryDropList(): void {
    const data = {
      store_id: this.filterForm?.value?.store_id
    };
    this.spinner.show();
    this.categoryService.findCategoryWithTargetStore(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.categoryDropList = res.data;
        this.filteredCategoryDropList = [...this.categoryDropList]
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  public search(filter: string, type: string) {
    if (type === 'store') {
      this.filteredStoreList = this.storeList.filter((store) => {
        let lowerCase = store.name.toLocaleLowerCase();
        return store.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else if (type === 'brand') {
      this.filteredBrandDropList = this.brandDropList.filter((brand) => {
        let lowerCase = brand.name.toLocaleLowerCase();
        return brand.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else if (type === 'category') {
      this.filteredCategoryDropList = this.categoryDropList.filter((cate) => {
        let lowerCase = cate.name.toLocaleLowerCase();
        return cate.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else {
      this.filteredSubCategoryDropList = this.subCategoryDropList.filter((subCate) => {
        let lowerCase = subCate.name.toLocaleLowerCase();
        return subCate.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  public customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  public reset(formDirective: FormGroupDirective): void {
    this.filterForm?.reset();
    formDirective?.resetForm();
  }

}
