<div class="container-fluid">
    <form [formGroup]="plan" (ngSubmit)="submit()" class="row">
        <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
                <mat-error> Name is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Posting Allowed</mat-label>
                <input matInput type="number" formControlName="posting">
                <mat-error> Name is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 mb-4">
            <!-- <mat-form-field appearance="outline" class="w-100"> -->
                <mat-label>Advertisement ?</mat-label>
                <div class="d-flex mt-2">
                    <mat-checkbox (change)="updateCheckbox($event, 'advertisementNo')" class="mr-4" formControlName="advertisementYes">Yes</mat-checkbox>
                    <mat-checkbox (change)="updateCheckbox($event, 'advertisementYes')" formControlName="advertisementNo">No</mat-checkbox>
                </div>
            <!-- </mat-form-field> -->
        </div>
        <div class="col-sm-12 mb-4">
            <!-- <mat-form-field appearance="outline" class="w-100"> -->
                <mat-label>Marketing ?</mat-label>
                <div class="d-flex mt-2">
                    <mat-checkbox (change)="updateCheckbox($event, 'marketingNo')" class="mr-4" formControlName="marketingYes">Yes</mat-checkbox>
                    <mat-checkbox (change)="updateCheckbox($event, 'marketingYes')" formControlName="marketingNo">No</mat-checkbox>
                </div>
            <!-- </mat-form-field> -->
        </div>
        <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Quarterly Charge</mat-label>
                <input matInput type="number" formControlName="quarterly_charge">
                <mat-error> Quarterly Charge is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Monthly Charge</mat-label>
                <input matInput type="number" formControlName="monthly_charge">
                <mat-error> Monthly Charge is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 d-flex justify-content-end">
            <button class="btn btn-success">{{ planData?.id ? 'Update' : 'Create' }}</button>
        </div>
    </form>
</div>