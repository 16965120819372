<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>

<div class="container-fluid">
  <div class="my-2 d-flex align-items-center justify-content-between">
    <h2>Table List</h2>
    <button mat-flat-button class="bg-primary text-white" (click)="openPopup()">
      Create Table
    </button>
  </div>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="mat-elevation-z8 w-100"
  >
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ page.pageIndex * page.pageSize + i + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="person">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Person</th>
      <td mat-cell *matCellDef="let element">{{ element?.person }}</td>
    </ng-container>

    <ng-container matColumnDef="picture">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
      <td mat-cell *matCellDef="let element">
        <img
          [src]="apiUrl + '/' + element?.picture"
          alt="restaurant-image"
          *ngIf="element?.picture"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
      <td mat-cell *matCellDef="let element">
        <p style="margin: 5px 0px 0px 0px">
          {{ element.created_at | date: "MMM d, y h:mm a" }}
        </p>
        <!-- <p style="margin: 0">{{element.created_at | date: 'h:mm a'}}</p> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
      <td mat-cell *matCellDef="let element; index as i" class="text-center">
        <i
          class="fa fa-pencil orderEdit"
          (click)="openPopup(element)"
          aria-hidden="true"
        ></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{
          language && language.hasOwnProperty("No data matching")
            ? language["No
                data matching"]
            : "No data matching"
        }}
      </td>
    </tr>
  </table>

  <mat-paginator
    [pageSize]="10"
    [pageIndex]="page.pageIndex"
    [length]="page.length"
    (page)="customePaginator($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
