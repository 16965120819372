import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { BrandService } from 'src/app/admin/product/brand.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {

  pageSizeOptions = [5, 10, 20];
  apiUrl: string = environment.API_URL;
  dataSource: any = new MatTableDataSource();
  brandList: any = [];
  tableColumns: string[] = ['sno', 'name', 'image', 'mob_picture', 'is_active'];
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  @Input() set loadBrandList(value) {
    if (value)
      this.getBrandList();
  }
  @Output() updateBrandList = new EventEmitter();
  @Output() editBrand = new EventEmitter();
  defaultImg: string = '../../../assets/images/placeholder.jpeg';
  @Input() private set setDataTableFilter(value: string) {
    this.filterRecords(value);
  }

  constructor(private brandService: BrandService, private spinner: NgxSpinnerService, private toastr: ToastrService, public appService: AppService) { }

  ngOnInit(): void {
    this.getBrandList();
    if (this.appService.getUserRole() == 1)
      this.tableColumns.push('action');
  }

  getBrandList() {
    const data = {}
    this.spinner.show();
    this.brandService.findAllBrand(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.brandList = res.data;
        this.dataSource = new MatTableDataSource(this.brandList);
        this.updateBrandList.emit();
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  private filterRecords(str: string) {
    const trimmeredVal = str?.trim()?.toLowerCase();
    const filtered = [...this.brandList?.filter(val => val?.name?.toLowerCase()?.includes(trimmeredVal))];
    this.dataSource = new MatTableDataSource(filtered);
    this.page.length = filtered?.length;
  }

}
