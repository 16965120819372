import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/admin/product/product.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { SubcategoryService } from 'src/app/admin/product/subcategory.service';
import { CategoryService } from 'src/app/admin/product/category.service';
import { BrandService } from 'src/app/admin/product/brand.service';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss']
})
export class MenuFormComponent implements OnInit {

  @Input() set Product(value) {
    this.editProduct = value;
    if (this.editProduct?.item_id)
      this.editProduct.id = this.editProduct?.item_id;
    this.ProductForm.controls.category_id.patchValue(value?.category_id ? value?.category_id : '');
    this.ProductForm.controls.sub_category_id.patchValue(value?.sub_category_id ? value?.sub_category_id : '');
    this.ProductForm.controls.brand_id.patchValue(value?.brand_id ? value?.brand_id : '');
    this.ProductForm.controls.name.patchValue(value ? value?.name : '');
    this.ProductForm.controls.desc.patchValue(value ? value?.desc : '');
    this.ProductForm.controls.is_active.patchValue(value?.is_active ? true : false);
    this.ProductForm.controls.posssible_variants.patchValue(value?.posssible_variants ? value?.posssible_variants : [])
    if (value?.productPicture)
      this.image.src = this.apiUrl + '/' + value?.productPicture;
    if (Object.keys(value)?.length)
      this.getSubCategoryDropList();
  };
  @Input() set setImage(path: string) {
    if (path)
      this.image.src = this.apiUrl + '/' + path;
  }
  editProduct: any;
  ProductForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    // code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(6)]),
    desc: new FormControl('', [Validators.minLength(1), Validators.maxLength(200)]),
    brand_id: new FormControl('', [Validators.required]),
    category_id: new FormControl('', [Validators.required]),
    sub_category_id: new FormControl('', [Validators.required]),
    // price: new FormControl('', [Validators.required]),
    is_active: new FormControl(true),
    posssible_variants: new FormControl([], [Validators.required])
    // has_limit: new FormControl(''),
    // limit: new FormControl(''),
    // vegetarian: new FormControl(''),
    // taxable: new FormControl('')
  });
  productList: any = [];
  dataSource: any = new MatTableDataSource();
  apiUrl: string = environment.API_URL;
  formDirective: any;
  @Input() store_id;
  @Input() userRole;
  image: any = { name: '', src: '../../../assets/images/placeholder.jpeg', file: '' };
  @ViewChild('file') file!: ElementRef;
  subCategoryDropList: any = [];
  filteredSubCategoryDropList: any = [];
  categoryDropList: any = [];
  filteredCategoryDropList: any = [];
  brandList: any = [];
  filteredBrandList: any = [];
  @Output() closeDialogModal = new EventEmitter();
  unitsList = [
    { value: 'Kilogram', key: 'kg' },
    { value: 'Litre', key: 'lt' },
    { value: 'Pounds', key: 'lb' },
    { value: 'Count', key: 'cnt' }
  ]
  filteredUnitsList = []
  @Input() public fromPage: string;
  disalbeForm: boolean = false;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, private appService: AppService, private subcategoryService: SubcategoryService, private categoryService: CategoryService, private brandService: BrandService) { }

  ngOnInit(): void {
    this.getBrandList();
    this.getCategoryDropList();
    this.filteredUnitsList = [...this.unitsList];
    if (this.userRole != 1 && this.userRole != 9 && this.editProduct?.id)
      this.disalbeForm = true;
  }

  onSubmit(formDirective: any) {
    if (this.ProductForm.invalid)
      return;
    else {
      this.formDirective = formDirective;
      const data = this.ProductForm.value;
      if (this.editProduct.id)
        data['id'] = this.editProduct.id
      data['posssible_variants'] = data['posssible_variants'].toString();
      const formData = new FormData();
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(data));
      this.spinner.show();
      this.productService.createUpdateMenu(formData).subscribe((res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.editProduct.id = res.data.id;
          if (this.userRole != 1 && this.userRole != 9 && this.editProduct?.id)
            this.disalbeForm = true;
          this.toastr.success(res.message, 'Success');
          // this.formDirective.resetForm();
          // this.ProductForm.reset();
          // this.image = { name: '', src: '', file: '' };
          this.closeDialogModal.emit({ productId: res.data.id, possibleVariants: res.data?.posssible_variants?.split(',') });
        }
        else this.toastr.error(res.message, 'Error')
      },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.image.name = file.name;
    this.image.file = file;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.src = reader.result as string;
      };
    }
  }

  updateValidation() {
    if (this.Product.has_limit) {
      this.ProductForm.controls['limit'].setValidators([Validators.required]);
      this.ProductForm.controls['limit'].updateValueAndValidity();
    }
    else {
      this.ProductForm.controls['limit'].clearValidators();
      this.ProductForm.controls['limit'].updateValueAndValidity();
    }
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }

  getSubCategoryDropList() {
    const data = {
      category_id: this.ProductForm.value.category_id,
      dropDown: true
    };
    this.spinner.show();
    this.subcategoryService.findAllSubCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.subCategoryDropList = res.data;
        this.filteredSubCategoryDropList = [...this.subCategoryDropList];
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getCategoryDropList() {
    const data = {
      store_id: this.store_id,
      dropDown: true
    }
    this.spinner.show();
    this.categoryService.findAllCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.categoryDropList = res.data;
        this.filteredCategoryDropList = [...this.categoryDropList]
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getBrandList() {
    const data = {
    }
    this.spinner.show();
    this.brandService.findAllBrand(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.brandList = res.data;
        this.filteredBrandList = [...this.brandList]
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  search(value: string, type: string) {
    if (type == 'category') {
      this.filteredCategoryDropList = this.categoryDropList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
    else if (type == 'posssible_variants') {
      this.filteredUnitsList = this.unitsList.filter((unit) => {
        let lowerCase = unit.value.toLocaleLowerCase();
        return unit.value.startsWith(value) || lowerCase.startsWith(value);
      });
    }
    else {
      this.filteredSubCategoryDropList = this.subCategoryDropList.filter((city) => {
        let lowerCase = city.name.toLocaleLowerCase();
        return city.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
  }

  closeDialog() {
    this.closeDialogModal.emit(true);
  }

}
