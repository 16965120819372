import { Component, OnInit } from '@angular/core';
import { ResSettingsService } from '../settings/res-settings/res-settings.service';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {

  constructor(private resSettingsService: ResSettingsService) { }

  ngOnInit(): void {
  }

  pay() {
    const data =
    {
      "merchid": "123456789012",
      "orderid": "AB-11-9876",
      "account": "6011000995500000",
      "expiry": "1218",
      "cvv2": "123",
      "postal": "55555",
      "ecomind": "E",
      "amount": "0",
      "name": "TOM JONES",
      "address": "123 MAIN STREET",
      "city": "anytown",
      "region": "NY",
      "country": "US",
      "profile": "Y",
      "capture": "Y",
      "bin": "Y"
    };
    this.resSettingsService.payment(data).subscribe((res: any) => {
      console.log('payment response', res);

    }, (err: any) => {
      console.log('err', err);
    })
  }

}
