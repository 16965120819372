import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { TableService } from '../table.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent implements OnInit {

  tableForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    person: new FormControl('', [Validators.minLength(1), Validators.maxLength(200)])
  });
  image: any = {
    name: '',
    src: '',
    file: ''
  }
  @ViewChild('file') file!: ElementRef;
  apiUrl: string = '';

  constructor(private ProductService: TableService, private toastr: ToastrService, private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TableFormComponent>, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.apiUrl = environment.API_URL;
    this.setFormValues();
  }

  setFormValues() {
    if (this.data && Object.keys(this.data).length) {
      this.tableForm.controls.name.patchValue(this.data.name);
      this.tableForm.controls.person.patchValue(this.data.person);
      this.image.src = this.apiUrl + '/' + this.data.picture;
    }
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.image.name = file.name;
    this.image.file = file;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.src = reader.result as string;
      };
    }
  }

  onSubmit() {
    if (this.tableForm.invalid)
      return
    else {
      const formData = new FormData();
      const data = this.tableForm.value;
      if (this.data && this.data.id)
        data.id = this.data.id;
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(data));
      this.ProductService.createUpdate(formData).subscribe((res: any) => {
        if (res.status)
          this.dialog.closeAll();
        else this.toastr.error(res.message, 'Error')
      },
        (err) => {
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }

}
