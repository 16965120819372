import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { RestaurantComponent } from './restaurant/restaurant/restaurant.component';
import { RestaurantFormComponent } from './restaurant/restaurant-form/restaurant-form.component';
import { LocationComponent } from './location/location/location.component';
import { PromotionComponent } from './promotion/promotion/promotion.component';
import { PromotionFormComponent } from './promotion/promotion-form/promotion-form.component';
import { HelpDeskComponent } from './help-des/help-desk/help-desk.component';
import { HelpDeskFormComponent } from './help-des/help-desk-form/help-desk-form.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { PaymentComponent } from './payment/payment.component';
import { MassCommunicationComponent } from './mass-communication/mass-communication.component';
import { ProductModule } from './product/product.module';
import { MenuModule } from '../shared/menu-list/menu.module';
import { MenuFormModule } from '../shared/menu-form/menu-form.module';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { TableComponent } from './table_/table/table.component';
import { TableFormComponent } from './table_/table-form/table-form.component';
import { ReservationComponent } from './reservation/reservation.component';
import { TextEllipsisModule } from '../shared/text-ellipsis/text-ellipsis.module';
import { LoginLogComponent } from './login-log/login-log.component';
import { PlanModule } from '../plan/plan.module';
import { StorePlanViewComponent } from './store-plan-view/store-plan-view.component';
import { ChatbotComponent } from './Chatbot/chatbot/chatbot.component';
import { ChatbotFormComponent } from './Chatbot/chatbot-form/chatbot-form.component';


@NgModule({
  declarations: [RestaurantComponent, RestaurantFormComponent, LocationComponent, PromotionComponent, PromotionFormComponent, HelpDeskComponent, HelpDeskFormComponent, PaymentHistoryComponent, DashboardComponent, PaymentComponent, MassCommunicationComponent, TransactionHistoryComponent, TableComponent, TableFormComponent, ReservationComponent, LoginLogComponent, StorePlanViewComponent, ChatbotComponent, ChatbotFormComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgApexchartsModule,
    SharedModule,
    ProductModule,
    MenuModule,
    MenuFormModule,
    TextEllipsisModule,
    PlanModule
  ]
})
export class AdminModule { }
