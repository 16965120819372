import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProductService } from 'src/app/admin/product/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-variant-list',
  templateUrl: './product-variant-list.component.html',
  styleUrls: ['./product-variant-list.component.scss']
})
export class ProductVariantListComponent implements OnInit {

  selectedAllVariant: boolean = true;
  VariantPage = { length: 0, pageIndex: 0, pageSize: 5 };
  variantList: any = [];
  variantDataSource: any = new MatTableDataSource();
  variantColumns: string[] = ['sno', 'unit', 'picture'];
  @Output() openProductList = new EventEmitter();
  @Output() editProductVariant = new EventEmitter();
  @Output() listUpdated = new EventEmitter();
  @Input() productId;
  @Input() fromPage: string;
  @Input() set updateList(value) {
    if (value)
      this.getProductList();
  }
  apiUrl: string;
  @Input() showActionColumn: boolean;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, public appService: AppService) { }

  ngOnInit(): void {
    this.apiUrl = environment.API_URL;
    if (this.appService.getUserRole() != 1 && this.appService?.getUserRole() != 9)
      this.variantColumns = ['sno', 'unit', 'picture', 'limit', 'price', 'taxable', 'status'];
    if (this.appService.getUserRole() != 1 && this.appService?.getUserRole() != 9 && this.fromPage !== 'Product List')
      this.variantColumns.unshift('selected');
    if (this.productId && this.productId != 'null' && this.productId != 'undefined')
      this.getProductList();
    if (this.showActionColumn) this.variantColumns.push('action');
  }

  getProductList() {
    this.spinner.show();
    const data = { item_id: this.productId }
    if (this.fromPage === 'Master Config' || this.fromPage === 'Req Products By User')
      data['global'] = true;
    this.productService.findAllVariants(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.variantList = res.data;
        if (this.appService.getUserRole() != 1 && this.appService?.getUserRole() != 9) {
          this.variantList.map(itemVariant => {
            {
              itemVariant.selected = itemVariant.store_item_variants.find(st => st.store_id == this.appService.getRestaurantId()) ? true : false;
              itemVariant.synced = itemVariant.selected;
              itemVariant.status = itemVariant?.store_item_variants?.length ? (itemVariant?.store_item_variants[0].is_active && itemVariant.synced ? 'Activated' : 'Deactivated') : '';
              if (!itemVariant.selected)
                this.selectedAllVariant = itemVariant.selected;
            }
          })
        }
        this.variantDataSource = new MatTableDataSource(this.variantList);
        this.VariantPage.length = this.variantList.length;
        this.listUpdated.emit();
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  selectAll(event) {
    this.variantList.map(product => product.selected = event.checked)
  }

  updateAllCheckbox() {
    this.selectedAllVariant = this.variantList.every(product => product.selected === true);
  }

  updateGlobalList() {
    this.spinner.show();
    const selectedData = [];
    const removedData = [];
    this.variantList.map(itemVariant => {
      if (itemVariant?.selected && !itemVariant?.synced)
        selectedData.push({ item_variant_id: itemVariant?.id });
      else if (!itemVariant?.selected && itemVariant?.synced)
        removedData.push(itemVariant?.id);
    });
    this.productService.updateGlobalProductVariant({ selectedData: selectedData, removedData: removedData, item_id: this.productId }).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        if(this.fromPage === 'Req Products By User') {
          this.getProductList();
        }
        else {
          this.openProductList.emit();
        }
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

}