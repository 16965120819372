<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center">
        <h3>Stores Going To Expire</h3>
        <div class="d-flex justify-content-between align-items-center">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>No Of Days</mat-label>
                <mat-select [(ngModel)]="days" (selectionChange)="getAboutToExpireStores()">
                    <mat-option *ngFor="let day of daysList" [value]="day">
                        {{day}} Days
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">

        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
            <td mat-cell *matCellDef="let element; let i = index">{{(page?.pageIndex *
                categoryPage?.pageSize) + i + 1}}</td>
        </ng-container>

        <ng-container matColumnDef="store">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
            <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Store Email</th>
            <td mat-cell *matCellDef="let element"> {{element?.info_email}}
            </td>
        </ng-container>

        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
            <td mat-cell *matCellDef="let element"> {{element?.city?.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="admin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Store Admin</th>
            <td mat-cell *matCellDef="let element"> {{element?.user_name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Admin Phone</th>
            <td mat-cell *matCellDef="let element"> {{element?.user_phone}}
            </td>
        </ng-container>

        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
            <td mat-cell *matCellDef="let element"> {{element?.store_plan?.plan?.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="start_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
            <td mat-cell *matCellDef="let element"> {{element?.store_plan?.start_date | date: 'dd-MM-YYYY' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="end_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let element"> {{element?.store_plan?.end_date | date: 'dd-MM-YYYY' }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No Record Found</td>
        </tr>
    </table>

    <mat-paginator [pageSize]="page?.pageSize" [length]="page.length" [pageIndex]="page.pageIndex"
        [pageSizeOptions]="pageSizeOptions" (page)="customePaginator($event)" showFirstLastButtons>
    </mat-paginator>

</div>