<div class="container-fluid">
    <div class="d-flex w-100">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Plans </mat-label>
            <mat-select [(ngModel)]="planId" (selectionChange)="showPlanInfo()">
                <input (keyup)="search($event?.target?.value)" class="form-control w-100" placeholder="Search Plan" />
                <mat-option *ngFor="let plan of filteredPlanList" [value]="plan.id">
                    {{ plan.name }}
                </mat-option>
            </mat-select>
            <mat-error> Category is Required </mat-error>
        </mat-form-field>
    </div>
    <div class="w-100" *ngIf="planInfo">
        <h3>Plan Info</h3>
        <div class="row">
            <div class="col-7">
                <div>
                    <p class="text-muted">Posting Allowed</p>
                    <p>{{ planInfo?.posting }}</p>
                </div>
                <div>
                    <p class="text-muted">Marketing Support ?</p>
                    <p>{{ planInfo?.marketing ? 'Yes' : 'No'}}</p>
                </div>
                <div>
                    <p class="text-muted">Advertisement ?</p>
                    <p>{{ planInfo?.advertisement ? 'Yes' : 'No' }}</p>
                </div>
            </div>
            <div class="col-5">
                <div>
                    <p class="text-muted">Quarterly Charge (90 Days)</p>
                    <p>{{ planInfo?.quarterly_charge | currency: '$' }} till {{ endDate?.quarterly | date: 'dd MMM, YYYY' }}</p>
                </div>
                <div>
                    <p class="text-muted">Monthly Charge (30 Days)</p>
                    <p>{{ planInfo?.monthly_charge | currency: '$'}} till {{ endDate?.monthly | date: 'dd MMM, YYYY' }}</p>
                </div>
            </div>
        </div>
    </div>
    <p class="text-danger" *ngIf="planId && (latestPostCount > planInfo?.posting)">Can't able to downgrade plan</p>
    <div class="d-flex justify-content-end" *ngIf="planId && planInfo && (latestPostCount <= planInfo?.posting)">
        <button class="btn btn-success mr-3" (click)="purchasePlan(1)">Buy Monthly {{ planInfo?.monthly_charge | currency: '$' }}</button>
        <button class="btn btn-success" (click)="purchasePlan(2)">Buy Quarterly {{ planInfo?.quarterly_charge | currency: '$'  }}</button>
    </div>
</div>