import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReqProdByUserComponent } from './req-prod-by-user/req-prod-by-user.component';

const routes: Routes = [
  { path: 'home', component: ReqProdByUserComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProdNotFoundRoutingModule { }
