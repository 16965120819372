import { Component, OnInit, ViewChild } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PaymentHistoryService } from './payment-history.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { RestaurantService } from '../restaurant/restaurant.service';
import { FormControl, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {

  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTableDataSource();
  selectedFilter: any;
  todaydate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  spinnerText: string = '';
  paymentHistoryList: any = [];
  displayedColumns: string[] = ['sno', 'date', 'orders', 'commission', 'tax', 'discount', 'order_total'];
  page = { length: 0, pageIndex: 0, pageSize: 10 };
  userRole: number;
  restaurantList: any = [];
  filteredRestaurantList: any = [];
  paymentFilterForm = new FormGroup({
    restaurant: new FormControl('0'),
    from: new FormControl(new Date(moment().subtract(7,'d').format('YYYY-MM-DD'))),
    to: new FormControl(new Date())
  });

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  constructor(
    private paymentHistoryService: PaymentHistoryService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private toaster: ToastrService,
    private appService: AppService,
    private restaurantService: RestaurantService
  ) { }

  ngOnInit(): void {
    this.userRole = this.appService.getUserRole();
    if(this.userRole == 1) {
    this.displayedColumns.splice(2, 0, 'restaurant');
    this.displayedColumns.splice(3, 0, 'mobile');
    }
    this.getPaymentHisoty();
    this.getRestaurantList();
  }

  customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  filterData(type: number) {
    this.selectedFilter = type;
    console.log(this.selectedFilter)
    this.spinner.show();
    if (type == 1) {
      let rawData = this.paymentHistoryService.getPaymentHisotyData();
      let x = rawData.filter(item => item.from_date == this.todaydate);
      this.dataSource.data = x;
      this.spinner.hide();
    } else if (type == 3) {
      let date = new Date();
      date.setDate(date.getDate() - 3);
      let date1 = this.datepipe.transform(date, 'dd-MM-yyyy');
      let rawData = this.paymentHistoryService.getPaymentHisotyData();
      let x = rawData.filter(item => item.from_date < date1);
      console.log(x);
      this.dataSource.data = x;
      this.spinner.hide();
    } if (type == 7) {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      let date1 = this.datepipe.transform(date, 'dd-MM-yyyy');
      let rawData = this.paymentHistoryService.getPaymentHisotyData();
      let x = rawData.filter(item => item.from_date < date1);
      console.log(x);
      this.dataSource.data = x;
      this.spinner.hide();
    } else if (type == 30) {
      let date = new Date();
      date.setDate(date.getDate() - 30);
      let date1 = this.datepipe.transform(date, 'dd-MM-yyyy');
      let rawData = this.paymentHistoryService.getPaymentHisotyData();
      let x = rawData.filter(item => item.from_date < date1);
      console.log(x);
      this.dataSource.data = x;
      this.spinner.hide();
    }
  }

  search(filter: string) {
    this.filteredRestaurantList = this.restaurantList.filter((rest) => {
      let lowerCase = rest.name.toLocaleLowerCase();
      return rest.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getPaymentHisoty() {
    this.spinner.show();
    this.spinnerText = 'Fetching Payment History Data ...';
    this.paymentHistoryService.getPaymentHisoty(this.paymentFilterForm.value).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.paymentHistoryList = res.data;
        this.dataSource = new MatTableDataSource(this.paymentHistoryList);
        this.page.length = this.paymentHistoryList.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else this.toaster.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      console.log('err', err);
      this.toaster.error(err.message, 'Error');
    })
  }

  getRestaurantList() {
    this.spinner.show();
    this.spinnerText = 'Fetching Payment History Data ...';
    this.restaurantService.findAllRestaurants(this.paymentFilterForm.value).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.restaurantList = res.data;
        this.filteredRestaurantList = this.restaurantList.slice();
      }
      else this.toaster.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      console.log('err', err);
      this.toaster.error(err.message, 'Error');
    })
  }

}
