import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PromotionService } from '../promotion.service';

@Component({
  selector: 'app-promotion-form',
  templateUrl: './promotion-form.component.html',
  styleUrls: ['./promotion-form.component.scss']
})
export class PromotionFormComponent implements OnInit {

  promotion: any = {
    name: '',
    desc: '',
    order_amount: '',
    amount: '',
    percentage: '',
    start_date: '',
    end_date: '',
    is_active: true,
    created_at: '',
    store_id: null,
    id: null
  };
  promotionId: number = null;
  promotionForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    desc: new FormControl(''),
    start: new FormControl('', [Validators.required]),
    end: new FormControl('', [Validators.required]),
    order_amount: new FormControl('', [Validators.required]),
    amount: new FormControl(''),
    percentage: new FormControl('')
  });

  constructor(private promotionService: PromotionService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getPromotion();
  }

  setPriceValidator(type) {
    if (type == 'price') {
      this.promotion.percentage = 0;
      this.promotion.amount = this.promotion.amount;
      this.promotionForm.controls['amount'].setValidators([Validators.required, Validators.min(0)]);
      this.promotionForm.controls['amount'].updateValueAndValidity();
      this.promotionForm.controls['percentage'].clearValidators();
      this.promotionForm.controls['percentage'].updateValueAndValidity();
    }
    else {
      this.promotion.amount = 0;
      this.promotion.percentage = this.promotion.percentage;
      this.promotionForm.controls['percentage'].setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
      this.promotionForm.controls['percentage'].updateValueAndValidity();
      this.promotionForm.controls['amount'].clearValidators();
      this.promotionForm.controls['amount'].updateValueAndValidity();
    }
  }

  getPromotion() {
    const user: any = JSON.parse(localStorage.getItem('user'));
    this.promotionId = this.promotionService.getPromotionId();
    if (this.promotionId != null)
      this.promotion = this.promotionService.getPromotionById(this.promotionId);
    else
      this.promotion.store_id = user.store_id;
  }

  onSubmit() {
    this.promotion.created_at = new Date();
    if (this.promotionId)
      this.promotionService.updatePromotion(this.promotion, this.promotionId);
    else
      this.promotionService.createPromotion(this.promotion);
    this.router.navigate(['/admin/promotion']);
    this.toastr.success('Promotion Created Successfully');
  }

}
