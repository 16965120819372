<div class="container-fluid bg-white">
  <h3 class="mb-4">{{ data?.id ? "Update" : "Create" }} Table</h3>
  <form name="tableForm" [formGroup]="tableForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-sm-12 col-md-9">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Table Name </mat-label>
          <input
            matInput
            minlength="1"
            maxlength="50"
            required
            formControlName="name"
          />
          <mat-error>Table Name is Required </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Person </mat-label>
          <input
            matInput
            minlength="1"
            maxlength="200"
            formControlName="person"
          />
          <mat-error> Person is Required </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col-12">
        <button class="w-100"
          mat-flat-button
          color="primary"
          type="button"
          (click)="uploadPicture()"
        >
          Upload Product Picture
        </button>
        <input
          type="file"
          #file
          accept="image/png, image/gif, image/jpeg"
          class="d-none"
          (change)="readFile($event)"
          multiple
        />
      </div>
      <div class="px-3 mt-3 w-100" *ngIf="image?.src">
        <img [src]="image?.src" [alt]="image?.name" class="w-100"/>
        <p class="mt-3" *ngIf="image?.name">{{ image?.name }}</p>
      </div>
    </div>
    <div class="row justify-content-end">
      <div
        class="col-8 d-flex align-items-center justify-content-end"
      >
        <button mat-flat-button
          mat-dialog-close
          type="button"
          class="bg-danger text-white w-75 mr-3"
        >
          Cancel
        </button>
        <button [disabled]="tableForm?.invalid"
          mat-flat-button
          type="submit"
          class="bg-success text-white w-75"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
