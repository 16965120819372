<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">
  <ng-container matColumnDef="sno">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
    <td mat-cell *matCellDef="let element; let i = index">{{ (menuPage.pageIndex * menuPage.pageSize) + i +
      1}}
      <i matTooltip="Price not updated" class="fa fa-exclamation-circle text-danger"
        *ngIf="appService?.getUserRole() != 1 && appService?.getUserRole() != 9 && !element?.price"></i>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element?.name?.length > 15" title="{{element?.name}}">{{element?.name?.substr(0,15)}} ... </span>
      <span *ngIf="element?.name?.length <= 15" title="{{element?.name}}">{{element?.name}}</span>
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="brand">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element?.brand?.name?.length > 15"
        title="{{element?.brand?.name}}">{{element?.brand?.name?.substr(0,15)}} ... </span>
      <span *ngIf="element?.brand?.name?.length <= 15" title="{{element?.brand?.name}}">{{element?.brand?.name}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
    <td mat-cell *matCellDef="let element"> {{element?.category.name}} </td>
  </ng-container>

  <ng-container matColumnDef="sub_category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Category</th>
    <td mat-cell *matCellDef="let element"> {{element?.sub_category.name}} </td>
  </ng-container> -->

  <ng-container matColumnDef="picture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
    <td mat-cell *matCellDef="let element" style="width:0">
      <img [src]="apiUrl+'/'+element?.picture" [alt]="element?.picture_name" class="w-100 orderImage"
        *ngIf="element?.picture">
    </td>
  </ng-container>

  <ng-container matColumnDef="mob_picture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mob Img</th>
    <td mat-cell *matCellDef="let element" style="width:0"
      [ngClass]="{'text-success': element?.mob_picture, 'text-danger': !element?.mob_picture}">
      {{ element?.mob_picture ? 'Y' : 'N' }}
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
      <td mat-cell *matCellDef="let element"> {{element?.created_at | date: 'MMMM d'}} </td>
    </ng-container> -->

  <ng-container matColumnDef="unit">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit</th>
    <td mat-cell *matCellDef="let element"> {{element?.unit_weight + ' ' + element?.unit}} </td>
  </ng-container>

  <ng-container matColumnDef="limit" *ngIf="(appService?.getUserRole() != 1 && appService?.getUserRole() != 9) || fromPage === 'store_list'">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Limit</th>
    <td mat-cell *matCellDef="let element"> {{element?.has_limit ? element?.limit : 'Unlimited'}} </td>
  </ng-container>

  <ng-container matColumnDef="quantity" *ngIf="(appService?.getUserRole() != 1 && appService?.getUserRole() != 9) || fromPage === 'store_list'">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Available Quantity</th>
    <td mat-cell *matCellDef="let element"> {{element?.has_limit ? element?.quantity : 'Unlimited'}} </td>
  </ng-container>

  <ng-container matColumnDef="price" *ngIf="(appService?.getUserRole() != 1 && appService?.getUserRole() != 9) || fromPage === 'store_list'">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
    <td mat-cell *matCellDef="let element"> {{ element?.price | currency : '$'}} </td>
  </ng-container>

  <ng-container matColumnDef="taxable" *ngIf="(appService?.getUserRole() != 1 && appService?.getUserRole() != 9) || fromPage === 'store_list'">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Taxable</th>
    <td mat-cell *matCellDef="let element"> {{ element?.taxable ? 'Yes' : 'No'}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let element"
      [ngClass]="{'text-success': element?.variant_status, 'text-danger': !element?.variant_status }">
      {{ element?.variant_status ? 'Active' : 'Deactivated' }} </td>
  </ng-container>

  <ng-container matColumnDef="action" *ngIf="fromPage === 'product'">
    <th mat-header-cell *matHeaderCellDef class="text-center"> Action</th>
    <td mat-cell *matCellDef="let element, index as i" class="text-center">
      <!-- <button class="btn btn-primary mr-2" (click)="openVariants(element.id, element?.posssible_variants)">
        <i class="fa fa-list text-white" aria-hidden="true"></i>
      </button> -->
      <button class="btn btn-primary mr-2" (click)="openMenuForm(element)">
        <i class="fa fa-edit text-white"></i>
      </button>
      <button class="btn btn-primary" (click)="showPriceHistory(element?.store_item_variant_id, menuPriceHistory)">
        <i class="fa fa-eye text-white"></i>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell text-center" colspan="12">No data matching</td>
  </tr>
</table>

<!-- <mat-paginator [pageSize]="10" [pageIndex]="menuPage.pageIndex" [length]="menuPage.length"
  (page)="customePaginator($event)" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
</mat-paginator> -->


<!-- <mat-paginator class="basic-paginataor" [pageSize]="menuPage.pageSize" [pageSizeOptions]="pageSizeOptions"
  (page)="customePaginator($event)" showFirstLastButtons>
</mat-paginator> -->

<ng-template #menuForm>
  <div>
    <div class="h-8 d-flex justify-content-between mb-2">
      <h3 class="m-0">Menu Form</h3>
      <button class="button-size btn bg-danger border-danger rounded-corner p-0" mat-dialog-close #menuDialogclose>
        <i class="fa fa-times text-white" aria-hidden="true"></i>
      </button>
    </div>
    <div class="h-90">
      <app-menu-form (closeDialogModal)="closeMenuForm($event)" [userRole]="userRole" [setImage]="menuImagePath"
        [store_id]="store_id" [Product]="selectedProduct"></app-menu-form>
    </div>
  </div>
</ng-template>

<ng-template #productVariants>
  <div class="d-flex justify-content-between mb-4">
    <h3 class="m-0">{{ selectedMenuName | titlecase }} Variants</h3>
    <button class="btn bg-danger border-danger rounded-corner" mat-dialog-close>
      <i class="fa fa-times text-white" aria-hidden="true"></i>
    </button>
  </div>
  <div>
    <app-product-info [productId]="selectedItemId"></app-product-info>
    <div class="d-flex justify-content-end my-3">
      <button class="btn btn-primary" (click)="openProductVariantForm(productVariantForm)">Add Product Variant</button>
    </div>
    <app-product-variant-list [fromPage]="'Product List'" (editProductVariant)="editProductVariant($event)"
      [productId]="selectedItemId"></app-product-variant-list>
  </div>
</ng-template>

<ng-template #productVariantForm>
  <div>
    <div class="h-8 d-flex justify-content-between mb-2">
      <h3 class="m-0">{{ productVariantId ? 'Update': 'Create' }} {{ selectedMenuName | titlecase }} Variant</h3>
      <button class="btn bg-danger border-danger rounded-corner p-0 w-5" mat-dialog-close #menuDialogclose>
        <i class="fa fa-times text-white" aria-hidden="true"></i>
      </button>
    </div>
    <div class="mt-4">
      <!-- (openProductVariantsPopup)="dialog?.closeAll();openVariants($event)" -->
      <app-product-variant-form (closePopup)="dialog?.closeAll();getMenuList()"
        (openProductVariantsPopup)="dialog?.closeAll();getMenuList()" [posssible_variants]="posssible_variants"
        [productId]="selectedItemId" [setProductVariantId]="productVariantId">
      </app-product-variant-form>
    </div>
  </div>
</ng-template>

<ng-template #menuPriceHistory>
  <div>
    <div class="h-8 d-flex justify-content-between mb-2">
      <h3 class="m-0">Product Variant Price History</h3>
      <button class="btn bg-danger border-danger rounded-corner p-0 w-5" (click)="refreshChildMenuData=true"
        mat-dialog-close #menuDialogclose>
        <i class="fa fa-times text-white" aria-hidden="true"></i>
      </button>
    </div>
    <div class="mt-4">
      <!-- (openProductVariantsPopup)="dialog?.closeAll();openVariants($event)" -->
      <app-menu-price-history (updateList)="dialog?.closeAll();refreshChildMenuData=true"
        [storeItemVariantId]="storeItemVariantId">
      </app-menu-price-history>
    </div>
  </div>
</ng-template>