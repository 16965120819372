import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  url: string = '';

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  createUpdate(data: any) {
    return this.http.post(`${this.url}/v1/table/createUpdate`, data).pipe(catchError(this.errorHandler));
  }

  findAll(data: any) {
    return this.http.get(`${this.url}/v1/table/findAll`, { params: data }).pipe(catchError(this.errorHandler));
  }

  findAllReservation(data: any) {
    return this.http.get(`${this.url}/v1/reservation/findAll`, { params: data }).pipe(catchError(this.errorHandler));
  }

  updateReservationStatus(data: any) {
    return this.http.post(`${this.url}/v1/reservation/updateReservationStatus`, data).pipe(catchError(this.errorHandler));
  }

  delete(data: any) {
    return this.http.post(`${this.url}/v1/table/delete`, data).pipe(catchError(this.errorHandler));
  }

  createReservation() {
    const data = {
      "store_id": 1020,
      "date": new Date(),
      "start_time": new Date(),
      "end_time": new Date(),
      "person": 5,
      "table_id": [1, 2]
    }
    console.log('data', data);


    return this.http.post(`${this.url}/v1/reservation/createUpdate`, data).pipe(catchError(this.errorHandler));
  }


  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}


