import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentHistoryService {

  paymentData: any = [
    {
      head: true, id: 1, level: 1, batch: '001', from_date: '21-08-2021', to_date: '28-08-2021', total_amount: 40000, total_order: 40, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 2, level: 1, batch: '002', from_date: '29-08-2021', to_date: '05-09-2021', total_amount: 60000, total_order: 55, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 3, level: 1, batch: '003', from_date: '06-09-2021', to_date: '13-09-2021', total_amount: 25000, total_order: 75, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, head: true, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 4, level: 1, batch: '004', from_date: '06-09-2021', to_date: '13-09-2021', total_amount: 25000, total_order: 75, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, head: true, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 5, level: 1, batch: '005', from_date: '21-08-2021', to_date: '28-08-2021', total_amount: 40000, total_order: 40, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, head: true, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 6, level: 1, batch: '006', from_date: '29-08-2021', to_date: '05-09-2021', total_amount: 60000, total_order: 55, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, head: true, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 7, level: 1, batch: '007', from_date: '06-09-2021', to_date: '13-09-2021', total_amount: 25000, total_order: 75, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, head: true, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    },
    {
      id: 8, level: 1, batch: '008', from_date: '06-09-2021', to_date: '13-09-2021', total_amount: 25000, total_order: 75, paid_amount: 36000, comission_amount: 4000,
      children: [
        {
          head: true, id: 1, level: 2, store_id: 1, restaurant_name: 'KMS Hakkim', total_amount: 25000, total_order: 30, paid_amount: 22500, comission_amount: 2500, children: [
            {
              head: true, id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Chicken Briyani', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Mutton Briyani', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 4, level: 3, user_id: 1, order_amount: 5000, order_date: '25-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 4, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 4, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 4, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            },
            {
              id: 5, level: 3, user_id: 1, order_amount: 5000, order_date: '26-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 5, menu_name: 'Chicken Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 5, menu_name: 'Mutton Noodles', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 5, menu_name: 'Beef Briyani', price: 600, qty: 4 },
              ]
            }
          ]
        },
        {
          id: 2, head: true, level: 2, store_id: 2, restaurant_name: 'Saravana Bavan', total_amount: 15000, total_order: 10, paid_amount: 13500, comission_amount: 1500, children: [
            {
              id: 1, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 1, order_id: 1, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 2, order_id: 1, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 1, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 2, level: 3, user_id: 1, order_amount: 5000, order_date: '21-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 2, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 5, order_id: 2, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 6, order_id: 2, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            },
            {
              id: 3, level: 3, user_id: 1, order_amount: 5000, order_date: '22-08-2021', children: [
                { head: true, level: 4, item_id: 4, order_id: 3, menu_name: 'Sambar Rice', price: 500, qty: 1 },
                { level: 4, item_id: 7, order_id: 3, menu_name: 'Curd Rice', price: 700, qty: 3 },
                { level: 4, item_id: 3, order_id: 3, menu_name: 'Lemon Rice', price: 600, qty: 4 },
              ]
            }
          ]
        }
      ]
    }
  ]
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  getPaymentHisotyData() {
    return this.paymentData;
  }

  getPaymentHisoty(data: any) {
    return this.http.get(`${this.url}/v1/payment/paymentHistory`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
