import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../product.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CategoryService } from '../category.service';
import { SubcategoryService } from '../subcategory.service';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  Product: any = {
    name: '',
    code: '',
    desc: '',
    category_id: '',
    sub_category_id: '',
    price: '',
    is_active: true,
    created_at: new Date(),
    store_id: null,
    picture: '',
    picture_name: '',
    id: null
  };
  ProductForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    code: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(6)]),
    desc: new FormControl('', [Validators.minLength(1), Validators.maxLength(200)]),
    category: new FormControl('', [Validators.required]),
    sub_category: new FormControl('', [Validators.required]),
    price: new FormControl(''),
    is_active: new FormControl('')
  });
  image: any = {
    name: '',
    src: '',
    file: ''
  }
  userRole: number = 0;
  @ViewChild('file') file!: ElementRef;
  categoryList: any = [];
  filterCategoryList: any = [];
  subCategoryList: any = [];
  filterSubCategoryList: any = [];
  apiUrl: string = '';

  constructor(private ProductService: ProductService, private toastr: ToastrService, private router: Router, private categoryService: CategoryService, private subcategoryService: SubcategoryService, private appService: AppService) { }

  ngOnInit(): void {
    this.userRole = this.appService.getUserRole();
    this.getCategoryList();
    this.getMenu();
    this.apiUrl = environment.API_URL;
  }

  getMenu() {
    // const menu: any = this.ProductService.getMenu();
    // if (menu && menu.id) {
    //   this.Product = menu;
    //   if (this.Product.menu_price)
    //     this.Product.price = this.Product.menu_price.price;
    //   this.getSubCategoryList();
    // }
    // else
    //   this.Product.store_id = this.appService.getRestaurantId();
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.Product.picture_name = file.name;
    this.image.file = file;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.Product.picture = reader.result as string;
      };
    }
  }

  getCategoryList() {
    const data = {
      store_id: this.appService.getRestaurantId()
    }
    this.categoryService.findAllCatgegories(data).subscribe((res: any) => {
      if (res.status) {
        this.categoryList = res.data;
        this.filterCategoryList = [...this.categoryList]
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getSubCategoryList() {
    const data = { store_id: this.appService.getRestaurantId(), category_id: this.Product.category_id };
    this.subcategoryService.findAllSubCatgegories(data).subscribe((res: any) => {
      if (res.status) {
        this.subCategoryList = res.data;
        this.filterSubCategoryList = [...this.subCategoryList]
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  search(value: string, type: string) {
    if (type == 'category') {
      this.filterCategoryList = this.categoryList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
    else {
      this.filterSubCategoryList = this.subCategoryList.filter((city) => {
        let lowerCase = city.name.toLocaleLowerCase();
        return city.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
  }

  onSubmit() {
    if (this.ProductForm.invalid)
      return
    else {
      this.Product.store_id = this.appService.getRestaurantId();
      const formData = new FormData();
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(this.Product));
      this.ProductService.createUpdateMenu(formData).subscribe((res: any) => {
        if (res.status) {
          this.router.navigate(['/admin/product']);
          this.toastr.success(res.message, 'Success');
        }
        else this.toastr.error(res.message, 'Error')
      },
        (err) => {
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }

}
