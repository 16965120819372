import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { ProductService } from 'src/app/admin/product/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

  apiUrl: string = environment.API_URL;
  prodId: number;
  @Input() set productId(value: number) {
    if (value) {
      this.prodId = value;
      this.getProductInfo();
    }
  }
  productInfo: any;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, public appService: AppService) { }

  ngOnInit(): void {
  }

  getProductInfo() {
    this.spinner.show();
    const data = { item_id: this.prodId }
    this.productService.findAllMenus(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.productInfo = res.data;
        if (this.productInfo.posssible_variants)
          this.productInfo.posssible_variants = this.productInfo.posssible_variants.split(',');
        this.productInfo.picture = this.productInfo.picture ? (this.apiUrl + '/' + this.productInfo.picture) : '../../../assets//images/placeholder.jpeg';
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

}
