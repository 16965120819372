import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';

@Component({
  selector: 'app-plan-activation',
  templateUrl: './plan-activation.component.html',
  styleUrls: ['./plan-activation.component.scss']
})
export class PlanActivationComponent implements OnInit {

  public planId!: number;
  private planList!: any;
  public filteredPlanList!: any;
  @Input() private storeId!: number;
  public planInfo!: any;
  public endDate!: any
  @Input() public latestPostCount: number;

  constructor(
    private restaurantService: RestaurantService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getPlanList();
  }

  private getPlanList(): void {
    this.spinner.show();
    const data = { store_id: this.storeId };
    this.restaurantService.getPlans(data).subscribe((res: any) => {
      if (res?.status) {
        this.planList = res?.data;
        const diffDays = res?.diffDay || 0;
        this.filteredPlanList = [...this.planList];
        this.endDate = {
          quarterly: moment().add(90, 'days'),
          monthly: moment().add(30, 'days')
        }
      }
      else {
        this.toastr.error(res?.message, 'Error');
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  public search(value: string) {
    this.filteredPlanList = this.planList.filter((state) => {
      let lowerCase = state.name.toLocaleLowerCase();
      return state.name.startsWith(value) || lowerCase.startsWith(value);
    });
  }

  public showPlanInfo(): void {
    this.planInfo = this.planList?.find(pl => pl.id === this.planId);
  }

  public purchasePlan(type: number): void {
    const data = {
      store_id: this.storeId,
      plan_id: this.planId,
      type
    };
    this.spinner.show();
    this.restaurantService.createStorePlan(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res?.status) {
        this.dialog.closeAll();
        this.toastr.success(res?.message, 'Success');
      }
      else {
        this.toastr.error(res?.message, 'Error');
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err?.message, 'Error');
    })
  }

}
