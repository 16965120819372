import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { MatTableDataSource } from '@angular/material/table';
import { SubcategoryService } from 'src/app/admin/product/subcategory.service';

@Component({
  selector: 'app-global-sub-category',
  templateUrl: './global-sub-category.component.html',
  styleUrls: ['./global-sub-category.component.scss']
})
export class GlobalSubCategoryComponent implements OnInit {
  selectedAllSubCategory: boolean = false;
  subCategoryPage = { length: 0, pageIndex: 0, pageSize: 5 };
  subCategoryList: any = [];
  subCategoryDataSource: any = new MatTableDataSource();
  subCategoryColumns: string[] = ['sno', 'name', 'category', 'desc', 'picture'];
  @Output() refreshSubCategoryList = new EventEmitter();

  constructor(private spinner: NgxSpinnerService, private subCategoryService: SubcategoryService, private toastr: ToastrService, public appService: AppService) { }

  ngOnInit(): void {
    if (this.appService.getUserRole() != 1)
      this.subCategoryColumns.unshift('selected');
    else
      this.subCategoryColumns.push('action');
    this.getCategoryList();
  }

  getCategoryList() {
    this.spinner.show();
    const data = { global: true }
    this.subCategoryService.findAllSubCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.subCategoryList = res.data;
        if (this.appService.getUserRole() != 1) {
          this.subCategoryList.map(subCategory => {
            subCategory.selected = subCategory.store_sub_categories.find(st => st.store_id == this.appService.getRestaurantId()) ? true : false;
          })
        }
        this.subCategoryDataSource = new MatTableDataSource(this.subCategoryList);
        this.subCategoryPage.length = this.subCategoryList.length;
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  selectAll(event) {
    this.subCategoryList.map(category => category.selected = event.checked)
  }

  updateGlobalList() {
    this.spinner.show();
    const selectedData = [];
    this.subCategoryList.map(subCategory => {
      if (subCategory.selected)
        selectedData.push({ category_id: subCategory.category_id, sub_category_id: subCategory.id });
    });
    this.subCategoryService.updateGlobalSubCategory({ data: selectedData }).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        this.refreshSubCategoryList.emit();
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )

  }


}
