import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { HeaderInterceptor } from './http-interceptor/http-interceptor';
import { AuthGuard } from './guards/auth.guard';
import { LayoutModule } from './layout/layout.module';
import { OrderModule } from './order/order.module';
import { SettingsModule } from './settings/settings.module';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { DatePipe } from '@angular/common';
import { ReportModule } from './report/report.module';
import { ProdNotFoundModule } from './prod-not-found/prod-not-found.module';

@NgModule({
  declarations: [
    AppComponent,
    PaymentGatewayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule,
    LayoutModule,
    AdminModule,
    OrderModule,
    SettingsModule,
    ReportModule,
    ProdNotFoundModule
  ],
  providers: [AuthGuard,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
