import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'name', 'email', 'phone', 'created_at', 'role', 'status', 'picture', 'action'];
  userList = [];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  apiUrl: string = '';

  constructor(private userService: UserService, private router: Router, private appService: AppService, public toastr: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getUserList();
    this.apiUrl = environment.API_URL;
  }

  getUserList() {
    this.spinner.show();
    const data = {
      store_id: this.appService.getRestaurantId()
    }
    this.userService.findAll(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.userList = res.data;
        this.dataSource = new MatTableDataSource(this.userList);
        this.page.length = this.userList.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else
        this.toastr.error(res.message, 'Error')
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  editUser(user: any) {
    this.userService.setUser(user);
    this.router.navigate(['/auth/user-form']);
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

}
