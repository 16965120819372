<div class="container-fluid">
    <div class="wrapper">
        <div class="content">
            <div class="item">
                <!-- Place your content here to have it be centered vertically and horizontally  -->
                <h1>COMING SOON</h1>
                <p>This website is under construction.</p>
            </div>
        </div>
    </div>
</div>