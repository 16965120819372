import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/admin/product/category.service';
import { AppService } from 'src/app/app.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-global-category',
  templateUrl: './global-category.component.html',
  styleUrls: ['./global-category.component.scss']
})
export class GlobalCategoryComponent implements OnInit {

  selectedAllCategory: boolean = false;
  categoryPage = { length: 0, pageIndex: 0, pageSize: 5 };
  categoryList: any = [];
  categoryDataSource: any = new MatTableDataSource();
  categoryColumns: string[] = ['sno', 'name', 'desc', 'picture'];
  @Output() refreshCategoryList = new EventEmitter();

  constructor(private spinner: NgxSpinnerService, private categoryService: CategoryService, private toastr: ToastrService, public appService: AppService) { }

  ngOnInit(): void {
    if (this.appService.getUserRole() != 1)
      this.categoryColumns.unshift('selected');
    else
      this.categoryColumns.push('action');
    this.getCategoryList();
  }

  getCategoryList() {
    this.spinner.show();
    const data = { global: true }
    this.categoryService.findAllCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.categoryList = res.data;
        if (this.appService.getUserRole() != 1) {
          this.categoryList.map(category => {
            category.selected = category.store_categories.find(st => st.store_id == this.appService.getRestaurantId()) ? true : false;
          })
        }
        this.categoryDataSource = new MatTableDataSource(this.categoryList);
        this.categoryPage.length = this.categoryList.length;
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  selectAll(event) {
    this.categoryList.map(category => category.selected = event.checked)
  }

  updateGlobalList() {
    this.spinner.show();
    const selectedData = [];
    this.categoryList.map(category => {
      if (category.selected)
        selectedData.push({ category_id: category.id });
    });
    this.categoryService.updateGlobalCategory({ data: selectedData }).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        this.refreshCategoryList.emit();
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )

  }

}
