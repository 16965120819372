import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { RestaurantService } from '../restaurant/restaurant.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { TransactionService } from './transaction.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {


  dataSource = new MatTableDataSource();
  selectedFilter: any;
  todaydate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  spinnerText: string = '';
  transactionHistoryList: any = [];
  displayedColumns: string[] = ['sno', 'date', 'user', 'phone', 'orders', 'commission', 'discount', 'tax', 'order_total'];
  page = { length: 0, pageIndex: 0, pageSize: 10 };
  userRole: number;
  restaurantList: any = [];
  filteredRestaurantList: any = [];
  paymentFilterForm = new FormGroup({
    store_id: new FormControl([]),
    from: new FormControl(new Date(moment().subtract(7, 'd').format('YYYY-MM-DD'))),
    to: new FormControl(new Date())
  });
  @ViewChild('TransactionModal') transactionModal!: TemplateRef<any>;
  transactionLogData: any = [];
  private allStoreSelected: boolean = false;
  private searchSub!: Subject<string>;

  constructor(
    private transactionHistoryService: TransactionService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private toaster: ToastrService,
    private appService: AppService,
    private restaurantService: RestaurantService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userRole = this.appService.getUserRole();
    if (this.userRole == 1) {
      this.displayedColumns.splice(2, 0, 'store');
      this.getRestaurantList();
    }
    this.getPaymentHisoty();
    this.searchSub = new Subject();
    this.searchSub?.pipe(debounceTime(500))?.subscribe((res) => this.filterRecords(res));
  }

  customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  search(filter: string) {
    this.filteredRestaurantList = this.restaurantList.filter((rest) => {
      let lowerCase = rest.name.toLocaleLowerCase();
      return rest.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getPaymentHisoty() {
    this.spinner.show();
    this.spinnerText = 'Fetching Payment History Data ...';
    this.transactionHistoryService.getTransactionHisoty(this.paymentFilterForm.value).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.transactionHistoryList = res.data;
        this.dataSource = new MatTableDataSource(this.transactionHistoryList);
        this.page.length = this.transactionHistoryList.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else this.toaster.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      console.log('err', err);
      this.toaster.error(err.message, 'Error');
    })
  }

  getPaymentSubHistory(retref: string) {
    this.spinner.show();
    const data = { retref: retref }
    this.transactionHistoryService.getTransactionSubHisoty(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status)
        this.transactionLogData = res.data;
      else
        this.toaster.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      this.toaster.error(err.message, 'Error');
    });
  }

  getRestaurantList() {
    this.spinner.show();
    this.spinnerText = 'Fetching Payment History Data ...';
    this.restaurantService.findAllRestaurants(this.paymentFilterForm.value).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.restaurantList = res?.data;
        this.filteredRestaurantList = this.restaurantList?.slice();
      }
      else this.toaster.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      console.log('err', err);
      this.toaster.error(err.message, 'Error');
    })
  }

  returnJson(data: any, key: string) {
    return JSON.parse(data)[key];
  }

  openConfirmationDialog(retref: string) {
    const dialogRef = this.dialog.open(this.transactionModal);
    this.getPaymentSubHistory(retref);
    dialogRef.afterClosed().subscribe(result => {
      // if (result)
    });
  }
  
  applyDataTableFilter(str: string) {
    this.searchSub.next(str);
  }

  private filterRecords(str: string) {
    const trimmeredVal = str?.trim()?.toLowerCase();
    const filtered = [...this.transactionHistoryList?.filter(val => val?.order_id?.toLowerCase()?.includes(trimmeredVal) || val?.user?.name?.toLowerCase()?.includes(trimmeredVal) || val?.user?.phone?.toLowerCase()?.includes(trimmeredVal))];
    this.dataSource = new MatTableDataSource(filtered);
    this.page.length = filtered?.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

}
