import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../order.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DeliveryPersonService } from 'src/app/settings/delivery-person-list/delivery-person.service';
import { response } from 'src/app/models/response.model';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-del-orders',
  templateUrl: './del-orders.component.html',
  styleUrls: ['./del-orders.component.scss']
})
export class DelOrdersComponent implements OnInit, OnDestroy {

  public searchText!: string;
  public page = { length: 0, pageIndex: 0, pageSize: 10 };
  public orderList: any = [];
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['sno', 'order_id', 'from', 'to', 'delivery_charge','order_items', 'created_at', 'action'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public search!: Subject<boolean>;
  private orderType!: string;
  private subscriptions!: Subscription[];
  spinnerText: string = '';

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private orderService: OrderService,
    private deliveryPersonService: DeliveryPersonService,private dialog: MatDialog,private router:Router
  ) { }

  ngOnInit(): void {
    this.subscriptions = [];
    this.orderType = 'pending';
    this.getOrderList(false);
    this.search = new Subject();
    const subs = this.search.pipe(debounceTime(500)).subscribe((res: boolean) => this.getOrderList(false));
    this.subscriptions?.push(subs);
    this.subsList();
  }

  private subsList(): void {
    const subs = this.deliveryPersonService.updateList.subscribe(r => {
      if (r) {
        this.getOrderList(false);
      }
    });
    this.subscriptions?.push(subs);
  }

  public getOrderList(fromPagination: boolean) {
    const data = {
      offset: this.page.pageIndex * this.page.pageSize,
      limit: this.page.pageSize,
      status: 4,
      orderType: this.orderType
    };
    if (this.searchText) {
      data['searchText'] = this.searchText;
    }
    this.spinner.show();
    this.orderService.getOrderList(data).subscribe((res: response) => {
      this.spinner.hide();
      if (res.status) {
        this.orderList = res?.data;
        this.dataSource = new MatTableDataSource(this.orderList);
        this.page.length = res.ordersCount;
        this.dataSource.sort = this.sort;
        if (!fromPagination)
          this.dataSource.paginator = this.paginator;
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  public customePaginator(event): void {
    this.page.pageIndex = event?.pageIndex;
    this.page.pageSize = event?.pageSize;
    this.getOrderList(true);
  }

  public acceptOrder(orderId: number): void {
    this.spinner.show();
    const data = {
      orderId,
      status: 8
    }
    this.deliveryPersonService.acceptOrder(data).subscribe({
      next: (res: response) => {
        this.spinner.hide();
        if (res?.status) {
          this.toastr.success(res?.message, 'Success');
          this.getOrderList(false);
        } else {
          this.toastr.error(res?.message, 'Error');
        }
      },
      error: (err) => {
        this.spinner.hide();
      }
    })
  }

  public changeTab(event): void {
    this.orderType = event?.index === 1 ? 'accepted' : (event?.index === 2 ? 'deliveried' : 'pending');
    const totalColumn = this.displayedColumns.includes('order_total');
    if (this.orderType === 'pending') {
      if (totalColumn) {
        this.displayedColumns.splice(5, 1);
      }
    } else {
      if (!totalColumn) {
        this.displayedColumns.splice(5, 0, 'order_total');
      }
    }
    this.getOrderList(false);
  }

  public deliveryOrder(orderId: number): void {
    const data = {
      id: orderId,
      status: 5
    }
    this.spinner.show();
    this.orderService.updateOrder(data).subscribe((res: response) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        this.getOrderList(false);
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(r => r?.unsubscribe());
  }
  editOrder(order: any) {
    if(this.orderType=='pending'){
      this.toastr.warning('Try to accept the orders and then order detail will be shown..')

    }else{
      console.log(order)
    const dialogRef = this.dialog.open(OrderDetailComponent, {
      data: order,
      width: '80vw !important',
      height: '92vh', minWidth: '80vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.updateOrderList)
        this.getOrderList(false);
      else if (result)
        this.updateOrderData(result);
    });
  }
  }
  updateOrderData(data: any) {
    this.spinnerText = 'Updating Order';
    this.spinner.show();
    this.orderService.updateOrder(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        this.getOrderList(false);
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }
  viewMap(order:any){
    if(this.orderType=='pending'){
      this.toastr.warning('Try to accept the orders and then direction will be shown..')

    }else if(this.orderType=='accepted'){
      // window.location.reload()

this.router.navigate(['direction/'+ order.id + '/' + order.created_at ])

    }
    else{
      this.toastr.warning('After delivering we wont share the direction')

    }
  }
}
