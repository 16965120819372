<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<div class="container-fluid">
    <h3>Price Report {{ selectedStoreName ? (' With ' + selectedStoreName + ' By Matching Products') : '' }}</h3>
    <form #formDirective="ngForm" [formGroup]="filterForm" class="row" (ngSubmit)="getPriceReport()">
        <div class="col-sm-12 col-md-auto">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Store To Compare </mat-label>
                <mat-select formControlName="store_id" required (selectionChange)="getCategoryDropList()">
                    <input (keyup)="search($event.target.value, 'store')" class="form-control w-100"
                        placeholder="Search Store" />
                    <mat-option [disabled]="appService.getRestaurantId() == st?.id" *ngFor="let st of filteredStoreList" [value]="st.id">
                        {{st.name}}
                    </mat-option>
                </mat-select>
                <mat-error> Store is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Brand </mat-label>
                <mat-select formControlName="brand_id">
                    <input (keyup)="search($event.target.value, 'brand')" class="form-control w-100"
                        placeholder="Search Sub Category" />
                    <mat-option *ngFor="let brand of filteredBrandDropList" [value]="brand.id">
                        {{brand.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Category </mat-label>
                <mat-select formControlName="category_id" (selectionChange)="getSubCategoryDropList()">
                    <input (keyup)="search($event.target.value, 'category')" class="form-control w-100"
                        placeholder="Search Category" />
                    <mat-option *ngFor="let st of categoryDropList" [value]="st.id">
                        {{st.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Sub Category </mat-label>
                <mat-select formControlName="sub_category_id">
                    <input (keyup)="search($event.target.value, 'sub_category')" class="form-control w-100"
                        placeholder="Search Sub Category" />
                    <mat-option *ngFor="let st of filteredSubCategoryDropList" [value]="st.id">
                        {{st.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-auto">
            <button type="submit" class="btn btn-primary mt-2 mr-3">Apply</button>
            <button type="button" (click)="reset(formDirective)" class="btn btn-danger text-white mt-2">reset</button>
        </div>
    </form>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">

        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
            <td mat-cell *matCellDef="let element; let i = index">{{(page?.pageIndex *
                categoryPage?.pageSize) + i + 1}}</td>
        </ng-container>

        <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
            <td mat-cell *matCellDef="let element"> {{element?.item_variant?.item?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit</th>
            <td mat-cell *matCellDef="let element"> {{element?.unit}}
            </td>
        </ng-container>

        <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
            <td mat-cell *matCellDef="let element"> {{element?.item_variant?.item?.brand?.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let element"> {{element?.item_variant?.item?.category?.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="sub_category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Category</th>
            <td mat-cell *matCellDef="let element"> {{element?.item_variant?.item?.sub_category?.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="picture">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
            <td mat-cell *matCellDef="let element">
                <img [src]="(apiUrl+'/'+element?.picture)" [alt]="element?.picture" class="w-50px"
                    *ngIf="element?.picture">
            </td>
        </ng-container>

        <ng-container matColumnDef="analysis">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Price Analysis</th>
            <td mat-cell *matCellDef="let element" class="text-center text-white"
                [ngClass]="{'bg-success': element?.priceAnalysis === 'Low', 'bg-primary': element?.priceAnalysis === 'Equal', 'bg-danger': element?.priceAnalysis === 'High' }">
                {{ element?.priceAnalysis }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No Record Found</td>
        </tr>
    </table>

    <mat-paginator [length]="page.length" [pageIndex]="page.pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="customePaginator($event)" class="basic-paginataor" showFirstLastButtons>
    </mat-paginator>

</div>