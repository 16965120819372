<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
    <p style="font-size: 20px; color: #050a4f"></p>
</ngx-spinner>
<div class="container-fluid">
    <div class="d-flex justify-content-end my-3">
        <button class="btn btn-primary" (click)="editPlan(planForm)">Create Plan</button>
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
            <td mat-cell *matCellDef="let element; let i = index">
                {{ page.pageIndex * page.pageSize + i + 1 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element?.name }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="posting">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Posting</th>
            <td mat-cell *matCellDef="let element">{{ element?.posting }}</td>
        </ng-container>

        <ng-container matColumnDef="advertisement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Advertisement</th>
            <td mat-cell *matCellDef="let element">{{ element?.advertisement ? 'Yes' : 'No' }}</td>
        </ng-container>

        <ng-container matColumnDef="marketing">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Marketing</th>
            <td mat-cell *matCellDef="let element">{{ element.marketing ? 'Yes' : 'No' }}</td>
        </ng-container>

        <ng-container matColumnDef="monthly_charge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Monthly Charge</th>
            <td mat-cell *matCellDef="let element">{{ element.monthly_charge | currency: '$'}}</td>
        </ng-container>

        <ng-container matColumnDef="quarterly_charge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quarterly Charge</th>
            <td mat-cell *matCellDef="let element">{{ element.quarterly_charge | currency: '$' }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let element">
                <button class="btn btn-primary" (click)="editPlan(planForm, element)">
                    <i class="fa fa-pencil"></i>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colspan]="displayedColumns?.length">No data matching</td>
        </tr>
    </table>
    <mat-paginator [length]="page.length" [pageIndex]="page.pageIndex" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
</div>

<ng-template #planForm>
    <div class="d-flex justify-content-between mb-4">
        <h3 class="m-0">{{ planData ? 'Update' : 'Create' }} Plan</h3>
        <button
            class="btn-sm bg-danger border-danger"
            mat-dialog-close>
            <i class="fa fa-times text-white" aria-hidden="true"></i>
        </button>
    </div>
    <app-plan-form [planData]="planData"></app-plan-form>
</ng-template>