import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  url: string = '';
  restaurant: any = null;

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  setRestaurant(restaurant: number) {
    this.restaurant = restaurant;
  }

  getRestaurant() {
    return this.restaurant;
  }

  createUpdateRestaurant(data: any) {
    return this.http.post(`${this.url}/v1/stores/createUpdate`, data).pipe(catchError(this.errorHandler));
  }

  updateSettings(data: any) {
    return this.http.post(`${this.url}/v1/stores/updateSettings`, data).pipe(catchError(this.errorHandler));
  }

  findAllRestaurants(data: any) {
    return this.http.get(`${this.url}/v1/stores/findAll`, { params: data }).pipe(catchError(this.errorHandler));
  }

  findActiveStores(data: any) {
    return this.http.get(`${this.url}/v1/stores/getActiveStore`, { params: data }).pipe(catchError(this.errorHandler));
  }

  getDropdownStores(data: any) {
    return this.http.get(`${this.url}/v1/stores/getDropdownStores`, { params: data }).pipe(catchError(this.errorHandler));
  }

  findStateCityByZip(data: any) {
    return this.http.get(`${this.url}/v1/location/findStateCityByZip`, { params: data }).pipe(catchError(this.errorHandler));
  }

  getStoreByZip(data: any) {
    return this.http.get(`${this.url}/v1/stores/getStoreByZip`, { params: data }).pipe(catchError(this.errorHandler));
  }

  getPlans(data: any) {
    return this.http.get(`${this.url}/v1/stores/getPlans`, { params: data }).pipe(catchError(this.errorHandler));
  }

  getStorePlans(data: any) {
    return this.http.get(`${this.url}/v1/stores/getStorePlans`, { params: data }).pipe(catchError(this.errorHandler));
  }

  createStorePlan(data: any) {
    return this.http.post(`${this.url}/v1/stores/createStorePlan`, data).pipe(catchError(this.errorHandler));
  }

  createPlan(data: any) {
    return this.http.post(`${this.url}/v1/stores/createPlan`, data).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
