import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuPriceHistoryComponent } from './menu-price-history.component';


@NgModule({
    declarations: [MenuPriceHistoryComponent],
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [MenuPriceHistoryComponent]
})
export class MenuPriceHistoryModule { }
