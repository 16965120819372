<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h2 class="w-100"> </h2>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-sm-12 col-md-12">

            <div id="map"></div>
            </div>
    </div>
    </div>

