<button class="btn btn-success" (click)="pay()">Pay</button>
<html>

<head>
    <script language="JavaScript">
        window.addEventListener('message', function (event) {
            var token = JSON.parse(event.data);
            var mytoken = document.getElementById('mytoken');
            mytoken.value = token.message;
        }, false);
    </script>
</head>

<body>
    hello
    <form name="tokenform" id="tokenform" method="post" action="https://fts-uat.cardconnect.com/itoke/outer-page.html?sendcardtypingevent=true">
        <iframe id="tokenFrame" name="tokenFrame" src="https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html" frameborder="0" scrolling="no"></iframe>
        <input type="hidden" name="mytoken" id="mytoken" />
        <button class="btn btn-success" type="submit">Pay</button>
    </form>
</body>

</html>