import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HelpDeskService } from '../help-desk.service';

@Component({
  selector: 'app-help-desk-form',
  templateUrl: './help-desk-form.component.html',
  styleUrls: ['./help-desk-form.component.scss']
})
export class HelpDeskFormComponent implements OnInit {

  help: any = {
    issue: '',
    desc: '',
    priority: '',
    status: 1,
    created_at: new Date(),
    created_by: '',
    store_id: null,
    picture: '',
    picture_name: '',
    id: null
  };
  helpId: number = null;
  helpForm = new FormGroup({
    issue: new FormControl('', [Validators.required]),
    desc: new FormControl('', [Validators.minLength(1), Validators.maxLength(500)]),
    priority: new FormControl('', [Validators.required])
  });
  image = {
    name: '',
    src: ''
  }
  @ViewChild('file') file!: ElementRef;
  issueList: any = [];
  priorityList: any = [];

  constructor(private toastr: ToastrService, private router: Router, private helpDeskService: HelpDeskService) { }

  ngOnInit(): void {
    this.issueList = this.helpDeskService.getIssueList();
    this.priorityList = this.helpDeskService.getPriorityList();
    this.setRestauant();
  }

  setRestauant() {
    const user: any = JSON.parse(localStorage.getItem('user'));
    this.help.created_by = user.id;
    this.helpId = this.helpDeskService.getHelpId();
    if (this.helpId)
    this.help = this.helpDeskService.getHelpById(this.helpId);
    else
      this.help.store_id = user.store_id;
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.help.picture_name = file.name;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.help.picture = reader.result as string;
      };
    }
  }

  updateValidation() {
    if (this.help.issue == 6) {
      this.helpForm.controls['desc'].setValidators([Validators.required]);
      this.helpForm.controls['desc'].updateValueAndValidity();
    }
    else {
      this.helpForm.controls['desc'].clearValidators();
      this.helpForm.controls['desc'].updateValueAndValidity();
    }
  }

  onSubmit() {
    if (this.helpForm.invalid)
      return;
    else {
      this.help.created_at = new Date();
      if (this.helpId)
        this.helpDeskService.updateHelp(this.help, this.helpId);
      else
        this.helpDeskService.createHelp(this.help);
      this.router.navigate(['/admin/helpdesk']);
      const message = this.helpId ? 'Help Request Updated Successfully' : 'Help Request Created Successfully';
      this.toastr.success(message, 'Success');
    }
  }

  closeHelp() {
    this.help.status = 0;
    this.onSubmit();
  }

}
