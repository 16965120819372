import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanComponent } from './plan/plan.component';
import { SharedModule } from '../shared/shared.module';
import { StorePlanComponent } from './store-plan/store-plan.component';
import { PlanActivationComponent } from './plan-activation/plan-activation.component';
import { PlanFormComponent } from './plan-form/plan-form.component';



@NgModule({
  declarations: [PlanComponent, StorePlanComponent, PlanActivationComponent, PlanFormComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [PlanComponent, StorePlanComponent, PlanActivationComponent, PlanFormComponent],
})
export class PlanModule { }
