import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
// import { io } from "socket.io-client";
import { io, Socket } from 'socket.io-client';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  url: string = environment.API_URL;
  orderId: number = null;
  private socket: Socket
  constructor(private http: HttpClient,
    ) {
    this.url = environment.API_URL;
    this.socket = io(environment.API_URL);
  }
  public sendMessage(message: any,roomName:any): void {
    this.socket.emit('joinRoom',roomName);

    this.socket.emit('message', message,roomName);
  }

  public onMessage(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('message', (data: any) => {
        observer.next(data);
      });
    });
  }
  leaveRoom(roomName: string) {
    this.socket.emit('leaveRoom', roomName);
  }
  getOrderList(data: any) {
    return this.http.get(`${this.url}/v1/orders/findOrder`, { params: data }).pipe(catchError(this.errorHandler));
  }

  findOrderLog(data: any) {
    return this.http.get(`${this.url}/v1/orders/findOrderLog`, { params: data }).pipe(catchError(this.errorHandler));
  }

  findOrderInfo(data: any) {
    return this.http.get(`${this.url}/v1/orders/findOrderInfo`, { params: data }).pipe(catchError(this.errorHandler));
  }

  createOrder(data: any) {
    return this.http.post(`${this.url}/v1/orders/create`, data).pipe(catchError(this.errorHandler));
  }

  updateOrder(data: any) {
    return this.http.post(`${this.url}/v1/orders/updateOrder`, data).pipe(catchError(this.errorHandler));
  }

  cancelOrder(data: any) {
    return this.http.post(`${this.url}/v1/orders/cancelOrder`, data).pipe(catchError(this.errorHandler));
  }

  capture(data: any) {
    return this.http.post(`${this.url}/v1/payment/capture`, data).pipe(catchError(this.errorHandler));
  }

  updateOrderItem(data: any) {
    return this.http.post(`${this.url}/v1/orders/updateOrderItem`, data).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
