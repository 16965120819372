<div class="container-fluid bg-white">
    <form name="infoForm" [formGroup]="infoForm" (ngSubmit)="onSubmit()">
        <h3 class="text-blue mb-4">Notification When an order is made</h3>
        <h6 class="text-grey">We use this email to inform you when a new order has been made. If you want to use more
            than one Email
            please seperate them with a comma. <br> Example: tim@gmail.com,susan@msn.com,bob@yahoo.com</h6>
        <div class="row align-items-center justify-content-center my-4">
            <div class="col-7">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Your Email(s)</mat-label>
                    <input matInput [(ngModel)]="infoSetting.info_email" required type="email" formControlName="email">
                    <mat-error> Enter a valid email </mat-error>
                </mat-form-field>
            </div>
        </div>
        <h6 class="text-grey">We use this cell phone number to notify you via text message when a new order has been
            made.
            <b class="text-blue">To use this feature, you must have the Text message subscription plan.</b> <br>
            Enter just one phone number. Do not use paranthesis. Example: 5552341212
        </h6>
        <div class="row align-items-center justify-content-center my-4">
            <div class="col-7">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Your Phone</mat-label>
                    <input matInput minlength="8" maxlength="15" [(ngModel)]="infoSetting.info_mobile" required
                        formControlName="mobile">
                    <mat-error> Enter a valid phone number </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center my-3">
            <button mat-flat-button type="submit" class="bg-success text-white w-25">Update </button>
        </div>
    </form>

</div>