import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuFormComponent } from './menu-form.component';


@NgModule({
    declarations: [MenuFormComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [MenuFormComponent]
})
export class MenuFormModule { }
