import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextEllipsisModule } from '../text-ellipsis/text-ellipsis.module';
import { GlobalProductComponent } from './global-product.component';


@NgModule({
    declarations: [GlobalProductComponent],
    imports: [
        CommonModule,
        SharedModule,
        TextEllipsisModule
    ],
    exports: [GlobalProductComponent]
})
export class GlobalProductModule { }
