<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h2 class="w-100">Orders List </h2>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Search By OrderID, Store Name, User Name, User Phone</mat-label>
                <input matInput [(ngModel)]="searchText" (input)="search?.next(true)" class="input-text"
                    autocomplete="off" />
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 d-flex justify-content-end">
            <button mat-flat-button class="bg-primary text-white" (click)="getOrderList(false)">Refresh</button>
        </div>
    </div>

    <mat-tab-group (selectedTabChange)="changeTab($event)">
        <mat-tab label="Pending">
          <ng-template *ngTemplateOutlet="orderList; context: { orderType: 'pending' }"></ng-template>
        </mat-tab>
        <mat-tab label="Accepted">
          <ng-template *ngTemplateOutlet="orderList; context: { orderType: 'accepted' }"></ng-template>
        </mat-tab>
        <mat-tab label="Delivered">
            <ng-template *ngTemplateOutlet="orderList; context: { orderType: 'deliveried' }"></ng-template>
          </mat-tab>
      </mat-tab-group>

</div>

<ng-template #orderList let-orderType="orderType">
    <table mat-table [dataSource]="dataSource" [class.isMobile]="isMobile" matSort class="mat-elevation-z8 w-100">
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
            <td mat-cell *matCellDef="let element; let i = index">
                {{ (page.pageIndex *
                page.pageSize) + i + 1}}</td>
        </ng-container>

        <ng-container matColumnDef="order_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Id </th>

            <td mat-cell *matCellDef="let element" class="text-success"  (click)="editOrder(element)">{{ element.order_id }}</td>
        </ng-container>

        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pickup </th>
            <td mat-cell *matCellDef="let element">{{ element?.store_address }}</td>
        </ng-container>

        <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Drop </th>
            <td mat-cell *matCellDef="let element">{{ element?.user_address }}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="store">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Store</th>
            <td mat-cell *matCellDef="let element">
                <app-text-ellipsis [length]="40" [text]="element?.store_name">
                </app-text-ellipsis>
                ({{element?.store_phone}})
            </td>
        </ng-container> -->

        <ng-container matColumnDef="order_items">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Items</th>
            <td mat-cell *matCellDef="let element"> {{element?.items}} </td>
        </ng-container>
        <ng-container matColumnDef="delivery_charge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Charge</th>
            <td mat-cell *matCellDef="let element" class="text-success" (click)="viewMap(element)"> {{element?.delivery_charge| currency: '$'}} </td>
        </ng-container>

        <ng-container matColumnDef="order_total" *ngIf="orderType !== 'pending'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Total</th>
            <td mat-cell *matCellDef="let element"> {{element?.order_total | currency: '$'}} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordered At</th>
            <td mat-cell *matCellDef="let element">
                <p style="margin: 5px 0px 0px 0px;">{{element.created_at | date: 'MMM d, h:mm a'}}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Action</th>
            <td mat-cell *matCellDef="let element, index as i" class="text-center">
                <button *ngIf="orderType === 'pending'" mat-flat-button color="primary" class="bg-success border-success mr-2"
                    (click)="acceptOrder(element?.id)">
                    Accept
                </button>
                <button *ngIf="orderType === 'accepted'" mat-flat-button color="primary" class="bg-success border-success" (click)="deliveryOrder(element?.id)">
                    Mark As Deliveried
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching
            </td>
        </tr>
    </table>

    <mat-paginator [pageSize]="10" [pageIndex]="page?.pageIndex" [length]="page?.length"
        (page)="customePaginator($event)" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
</ng-template>