import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ReportService {

  url: string = '';
  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  getPriceReport(data: any) {
    return this.http.get(`${this.url}/v1/report/getPriceReport`, { params: data }).pipe(catchError(this.errorHandler));
  }

  getTopUsers(data: any) {
    return this.http.get(`${this.url}/v1/report/getTopUsers`, { params: data }).pipe(catchError(this.errorHandler));
  }

  getAboutToExpireStores(data: any) {
    return this.http.get(`${this.url}/v1/report/getAboutToExpireStores`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
