import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public dataSource: any = new MatTableDataSource();
  public displayedColumns: string[] = ['sno', 'name', 'posting', 'advertisement', 'marketing', 'monthly_charge', 'quarterly_charge', 'action'];
  public page = { length: 0, pageIndex: 0, pageSize: 10 };
  public planList: any = [];
  @Input() set getPlan(value: boolean) {
    if (value) {
      this.getPlanList();
    }
  }
  public planData!: any;

  constructor(
    private restaurantService: RestaurantService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  private getPlanList(): void {
    this.spinner.show();
    this.restaurantService.getPlans({}).subscribe((res: any) => {
      if (res?.status) {
        this.planList = res?.data;
        this.dataSource = new MatTableDataSource(this.planList);
        this.page.length = this.planList?.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.error(res?.message, 'Error');
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  public editPlan(template: TemplateRef<any>, planData?: any): void {
    this.planData = { ...planData };
    const dialog = this.dialog.open(template, {
      width: '40vw !important',
      height: '88vh',
      minWidth: '40vw',
      maxWidth: '40vw'
    });
    dialog.afterClosed().subscribe((_res: any) => {
      this.planData = {};
      this.getPlanList();
    })
  }

  private getStorePlans(): void {
    this.spinner.show();
    const data = { from: 'store_creation' };
    this.restaurantService.getStorePlans(data).subscribe((res: any) => {
      if (res?.status) {
        this.planList = res?.data;
        this.dataSource = new MatTableDataSource(this.planList);
        this.page.length = this.planList?.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.error(res?.message, 'Error');
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

}
