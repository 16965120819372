import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TableService } from '../table.service';
import { TableFormComponent } from '../table-form/table-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'name', 'person', 'picture', 'created_at', 'action'];
  page = { length: 0, pageIndex: 0, pageSize: 10 };
  tableList: any = [];
  apiUrl: string = environment.API_URL;

  constructor(private tableService: TableService, private dialog: MatDialog, private appService: AppService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getTableList();
  }

  createReservation() {
    this.tableService.createReservation().subscribe((res: any) => {
      console.log('res fomr reservation', res);

    })
  }

  getTableList() {
    const data = { store_id: this.appService.getRestaurantId() };
    this.spinner.show();
    this.tableService.findAll(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.tableList = res.data;
        this.dataSource = new MatTableDataSource(this.tableList);
        this.page.length = res.ordersCount;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  openPopup(data?: any) {
    const dialogRef = this.dialog.open(TableFormComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.getTableList();
    });
  }

  customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }


}
