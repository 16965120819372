import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { RestaurantService } from '../restaurant.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '../../location/location.service';
import { UserService } from 'src/app/auth/user/user.service';
import { environment } from 'src/environments/environment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { CategoryService } from '../../product/category.service';
import { SubcategoryService } from '../../product/subcategory.service';

@Component({
  selector: 'app-restaurant-form',
  templateUrl: './restaurant-form.component.html',
  styleUrls: ['./restaurant-form.component.scss']
})
export class RestaurantFormComponent implements OnInit {

  res_phoneobj: any = { number: '', countryCode: '' };
  admin_phoneobj: any = { number: '', countryCode: '' };
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  PhoneNumberFormat = PhoneNumberFormat
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates]
  phone: any;
  restaurantId: number;
  categoryDropOpened: boolean = false;

  emailPattern = /[a-zA-Z0-9_.+-,;]+@(?:(?:[a-zA-Z0-9-]+\.,;)?[a-zA-Z]+\.,;)?(gmail)\.com/;
  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      var emails = control.value.split(',');
      const forbidden = emails.some((email: any) => Validators.email(new FormControl(email)));
      return forbidden ? { 'email': { value: control.value.trim() } } : null;
    }
  };
  resSetting: any = {
    order_status: true,
    
  };
  restaurantForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    address3: new FormControl(''),
    state_id: new FormControl('', [Validators.required]),
    city_id: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(5), Validators.minLength(5)]),
    info_mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(7), Validators.maxLength(10)]),
    info_email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern(this.emailPattern),
      this.commaSepEmail,
      Validators.minLength(5),
      Validators.maxLength(80)
    ])),
    desc: new FormControl('', [Validators.maxLength(200)]),
    is_active: new FormControl(true),
    lat: new FormControl('', [Validators.required]),
    long: new FormControl('', [Validators.required]),
    avg_time_order: new FormControl(20, [Validators.required, Validators.min(0), Validators.max(30)]),
    avg_time_pending_order: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(10)]),
    tax: new FormControl(8, [Validators.required, Validators.min(5), Validators.max(15), Validators.pattern("^[0-9]*$")]),
    admin_name: new FormControl('', [Validators.required]),
    admin_email: new FormControl('', [Validators.required, Validators.email]),
    admin_mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(7), Validators.maxLength(10)]),
    category: new FormControl('', [Validators.required]),
    sub_category: new FormControl('', [Validators.required]),
    store: new FormControl(null),
    order_status: new FormControl(''),
    alter_zip1: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(5), Validators.minLength(5)]),
    alter_zip2: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(5), Validators.minLength(5)]),
    alter_zip3: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(5), Validators.minLength(5)]),
    alter_zip4: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.maxLength(5), Validators.minLength(5)]),
    alter_zip5: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.maxLength(5), Validators.minLength(5)]),
    has_delivery: new FormControl(false, [Validators.required])
  });
  image = { name: '', src: '../../../../assets/images/placeholder.jpeg', file: '' }
  @ViewChild('file') file!: ElementRef;
  stateList: any = [];
  filteredStateList: any = [];
  cityList: any = [];
  filteredCityList: any = [];
  apiUrl: string = '';
  spinnerText: string = '';
  checkAlreadyExistsObj = { info_email: false, info_mobile: false, admin_email: false, admin_mobile: false };
  categoryList: any = [];
  filteredCategoryList: any = [];
  subCategoryList: any = [];
  filteredSubCategoryList: any = [];
  storeList: any = [];
  filteredStoreList: any = [];
  public filteredPlanList: any = [];
  public planList: any = [];

  constructor(private restaurantService: RestaurantService, private toastr: ToastrService, private router: Router, private categoryService: CategoryService, private userService: UserService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private appService: AppService, private subcategoryService: SubcategoryService, private locationService: LocationService) { }

  ngOnInit(): void {
    this.getRestaurant();
    this.getStateList();
    this.getCategoryList();
    this.getActiveStores();
    this.apiUrl = environment.API_URL;

    this.route.queryParams.subscribe(params => {
      this.restaurantId = params.id;
      if (this.restaurantId) {
        this.fetchRestaurantData(this.restaurantId);
      }
      // else {
      //   this.getPlanList();
      // }
    })
    // this.getInfo();
  }

  private getPlanList(): void {
    this.spinner.show();
    const data = { from: 'store_creation' };
    this.restaurantService.getPlans(data).subscribe((res: any) => {
      if (res?.status) {
        this.planList = res?.data;
        this.filteredPlanList = [...this.planList];
      }
      else {
        this.toastr.error(res?.message, 'Error');
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  ngAfterViewInit() {
    const nodes: any = document.querySelectorAll('#phone');
    if (nodes && nodes.length) {
      for (const node of nodes) {
        node.style.border = 'unset';
        if (this.restaurantId && this.restaurantForm.controls.info_mobile.valid && this.restaurantForm.controls.admin_mobile.valid) {
          node.readOnly = true;
        }
      }
    }
  }

  getActiveStores() {
    this.spinner.show();
    this.restaurantService.findActiveStores({}).subscribe((res: any) => {
      if (res.status) {
        this.storeList = res?.data;
        this.filteredStoreList = [...this.storeList];
      }
      else this.toastr.error(res.message, 'Error');
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getInfo() {
    const data = 620001
    this.spinner.show();
    this.locationService.autoCompleteAddress(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getCategoryList() {
    const data = { dropDown: true }
    this.spinner.show();
    this.categoryService.findAllCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.categoryList = res.data;
        this.filteredCategoryList = [...this.categoryList]
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getSubCategoryList(category_id) {
    const data = { store_id: this.appService.getRestaurantId(), category_id: category_id };
    this.spinner.show();
    this.subcategoryService.findAllSubCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.subCategoryList = res.data;
        this.filteredSubCategoryList = [...this.subCategoryList];
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getStateList() {
    this.spinnerText = 'Fetching State Data ...';
    this.spinner.show();
    this.userService.getStates().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.stateList = res.data;
        this.filteredStateList = [...this.stateList];
      }
      else
        this.toastr.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  getCityList(state_id: number) {
    this.spinnerText = 'Fetching City Data ...';
    this.spinner.show();
    const data = { state_id: state_id };
    this.userService.getCities(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.cityList = res.data;
        this.filteredCityList = [...this.cityList];
      }
      else
        this.toastr.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  fetchRestaurantData(store_id: number) {
    this.spinnerText = 'Fetching Store Data ...';
    this.spinner.show();
    const data = { store_id: store_id, from_edit_res: true };
    this.restaurantService.findAllRestaurants(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.resSetting.order_status = res.data.order_status ? true : false;
        this.restaurantForm.controls.order_status.patchValue(res.data.order_status ? true : false)
        this.restaurantForm.controls.name.patchValue(res.data.name);
        this.restaurantForm.controls.address1.patchValue(res.data.address1);
        this.restaurantForm.controls.address2.patchValue(res.data.address2);
        this.restaurantForm.controls.address3.patchValue(res.data.address3);
        this.restaurantForm.controls.state_id.patchValue(res.data.state_id);
        this.restaurantForm.controls.city_id.patchValue(res.data.city_id);
        this.restaurantForm.controls.info_mobile.patchValue(res.data.info_mobile);
        this.restaurantForm.controls.info_email.patchValue(res.data.info_email);
        this.restaurantForm.controls.desc.patchValue(res.data.desc);
        this.restaurantForm.controls.is_active.patchValue(res.data.is_active ? true : false);
        this.restaurantForm.controls.lat.patchValue(res.data.lat);
        this.restaurantForm.controls.long.patchValue(res.data.long);
        this.restaurantForm.controls.avg_time_order.patchValue(res.data.avg_time_order);
        this.restaurantForm.controls.avg_time_pending_order.patchValue(res.data.avg_time_pending_order);
        this.restaurantForm.controls.tax.patchValue(res.data.tax);
        this.restaurantForm.controls.admin_name.patchValue(res.data.admin.name);
        this.restaurantForm.controls.admin_mobile.patchValue(res.data.admin.phone);
        this.restaurantForm.controls.admin_email.patchValue(res.data.admin.email);
        this.restaurantForm.controls.zip.patchValue(res.data.zip);
        this.restaurantForm.controls.alter_zip1.patchValue(res.data.alter_zip1);
        this.restaurantForm.controls.alter_zip2.patchValue(res.data.alter_zip2);
        this.restaurantForm.controls.alter_zip3.patchValue(res.data.alter_zip3);
        this.restaurantForm.controls.alter_zip4.patchValue(res.data.alter_zip4);
        this.restaurantForm.controls.alter_zip5.patchValue(res.data.alter_zip5);
        this.restaurantForm.controls.has_delivery.patchValue(res.data.has_delivery ? true : false);
        // this.restaurantForm.controls.plan.patchValue(res?.data?.plan);
        if (res.data.category)
          this.restaurantForm.controls.category.patchValue(res.data.category.split(',').map(val => Number(val)));
        if (res.data.sub_category)
          this.restaurantForm.controls.sub_category.patchValue(res.data.sub_category.split(',').map(val => Number(val)));
        // this.restaurant = res.data;
        if (res.data.admin) {
          const admin_phoneobj = { number: res.data.admin.phone, dialCode: res.data.admin.dial_code, countryCode: res.data.admin.country_code };
          this.admin_phoneobj = admin_phoneobj;
          // this.restaurant.admin_name = res.data.admin.name;
          // this.restaurant.admin_email = res.data.admin.email;
        }
        const res_phoneobj = { number: res.data.info_mobile, dialCode: res.data.dial_code, countryCode: res.data.country_code };
        this.res_phoneobj = res_phoneobj;
        this.getCityList(res.data.state_id);
        this.getSubCategoryList(this.restaurantForm.value.category);
      }
      else
        this.toastr.error(res.message, 'Error');
      this.ngAfterViewInit();
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  getRestaurant() {
    let res_id;
    this.route.queryParamMap.subscribe((params: any) => {
      res_id = params.params.id;
    });
    if (res_id)
      this.fetchRestaurantData(res_id);
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }

  readFile(event: any) {
    // if (this.restaurant.picture)
    //   this.restaurant.picture = '';
    const file = event.target.files[0];
    if (file && (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png')) {
      this.image.name = file.name;
      this.image.file = file;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.image.src = reader.result as string;
        };
      }
    }
    else {
      this.toastr.error('Please choose jpeg, jpg or png image format only', 'Error')
      return;
    }

  }

  search(value: string, type: string) {
    let filter = value;
    if (type == 'state') {
      this.filteredStateList = this.stateList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else if (type == 'category') {
      this.filteredCategoryList = this.categoryList.filter((category) => {
        let lowerCase = category.name.toLocaleLowerCase();
        return category.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else if (type == 'sub_category') {
      this.filteredSubCategoryList = this.subCategoryList.filter((sub_category) => {
        let lowerCase = sub_category.name.toLocaleLowerCase();
        return sub_category.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else if (type == 'store') {
      this.filteredStoreList = this.storeList.filter((store) => {
        let lowerCase = store.name.toLocaleLowerCase();
        return store.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else if (type == 'plan') {
      this.filteredPlanList = this.planList.filter((plan) => {
        let lowerCase = plan.name.toLocaleLowerCase();
        return plan.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
    else {
      this.filteredCityList = this.cityList.filter((city) => {
        let lowerCase = city.name.toLocaleLowerCase();
        return city.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  public updateValidation(): void {
    if (this.restaurantForm?.value?.store) {
      this.restaurantForm.patchValue({
        category: '',
        sub_category: ''
      })
      this.restaurantForm?.controls['category']?.clearValidators();
      this.restaurantForm.controls['category']?.updateValueAndValidity();
      this.restaurantForm?.controls['sub_category']?.clearValidators();
      this.restaurantForm.controls['sub_category']?.updateValueAndValidity();
    }
    else {
      this.restaurantForm?.controls['category']?.setValidators([Validators.required]);
      this.restaurantForm.controls['category']?.updateValueAndValidity();
      this.restaurantForm?.controls['sub_category']?.setValidators([Validators.required]);
      this.restaurantForm.controls['sub_category']?.updateValueAndValidity();
    }
  }

  onSubmit() {
    if (this.checkAlreadyExistsObj.admin_email) {
      this.toastr.warning('Admin email already exists', 'Warning');
      return;
    }
    else if (this.checkAlreadyExistsObj.admin_mobile) {
      this.toastr.warning('Admin mobile already exists', 'Warning');
      return;
    }
    else if (this.checkAlreadyExistsObj.info_email) {
      this.toastr.warning('Admin email already exists', 'Warning');
      return;
    }
    else if (this.checkAlreadyExistsObj.info_mobile) {
      this.toastr.warning('Admin mobile already exists', 'Warning');
      return;
    }
    else {
      this.restaurantForm.controls.info_mobile.patchValue(this.res_phoneobj?.number);
      this.restaurantForm.controls.admin_mobile.patchValue(this.res_phoneobj?.number);
      if (this.restaurantForm.invalid)
        return
      else {
        const data = this.restaurantForm.value;
        data.info_mobile = this.res_phoneobj.number;
        data.country_code = this.res_phoneobj.countryCode;
        data.admin_mobile = this.admin_phoneobj.number;
        data.adminCountryCode = this.admin_phoneobj.countryCode;
        data.dial_code = this.res_phoneobj.dialCode;
        data.admin_dial_code = this.admin_phoneobj.dialCode;
        if (this.restaurantId)
          data.id = this.restaurantId;

        const formData = new FormData();
        formData.append('Image', this.image.file);
        formData.append('data', JSON.stringify(data));

        this.spinnerText = this.restaurantId ? 'Updating Store Configuration ...' : ' Creating Store Configuration...';
        this.spinner.show();
        this.restaurantService.createUpdateRestaurant(formData).subscribe((res: any) => {
          this.spinner.hide();
          if (res.status) {
            this.router.navigate(['/admin/restaurant']);
            this.toastr.success(res.message, 'Success');
          }
          else this.toastr.error(res.message, 'Error')
        },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err, 'Error')
          }
        )
      }
    }
  }

  findStateCityByZip() {
    this.spinner.show();
    const data = { zip: this.restaurantForm.value.zip }
    this.restaurantService.findStateCityByZip(data).subscribe((res: any) => {
      this.spinner.hide();
      this.restaurantService.setRestaurant(null);
      if (res.status) {
        this.restaurantForm.controls.city_id.patchValue(res.data.id);
        this.restaurantForm.controls.state_id.patchValue(res.data.state.id);
        this.getCityList(this.restaurantForm.value.state_id);
      }
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err, 'Error')
      }
    )
  }

  checkAlreadyExists(type: string, prop: string, value: string, errorProp: string) {
    const obj = { type: type, prop: prop, value: value };
    this.appService.checkExists(obj).subscribe((res: any) => {
      if (res.status) {
        this.checkAlreadyExistsObj[errorProp] = res.data;
        if (res.data)
          this.toastr.warning(`${value} already exists`, 'Warning');
      }
    })
  }

  openedChange($event) {
    this.categoryDropOpened = !this.categoryDropOpened;
    if (!this.categoryDropOpened && this.restaurantForm.value.category.length) {
      this.getSubCategoryList(this.restaurantForm.value.category)
    }
  }
}
