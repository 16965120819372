import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryPersonFormComponent } from './delivery-person-form.component';
import { SharedModule } from '../shared.module';



@NgModule({
  declarations: [DeliveryPersonFormComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [DeliveryPersonFormComponent]
})
export class DeliveryPersonFormModule { }
