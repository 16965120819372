import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { DeliveryPersonFormComponent } from 'src/app/shared/delivery-person-form/delivery-person-form.component';
import { DeliveryPersonService } from './delivery-person.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delivery-person-list',
  templateUrl: './delivery-person-list.component.html',
  styleUrls: ['./delivery-person-list.component.scss']
})
export class DeliveryPersonListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'name', 'email', 'phone', 'state', 'city', 'zipcode', 'status_text', 'action'];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 5 };

  public deliveryPersonList;
  public statusObj!: Object;

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private appService: AppService,
    private deliveryPersonService: DeliveryPersonService,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getDeliveryPerson();
  }

  private getDeliveryPerson(): void {
    this.spinner.show();
    const data = {
      store_id: this.appService.getRestaurantId()
    }
    this.deliveryPersonService.getDeliveryPerson(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.deliveryPersonList = res?.data;
        this.dataSource = new MatTableDataSource(this.deliveryPersonList);
        this.page.length = this.deliveryPersonList.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.statusObj = res?.statusList;
      }
      else
        this.toastr.error(res.message, 'Error')
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  public openDialog(delPersonId?: number | null) {
    const dialogRef = this.dialog.open(DeliveryPersonFormComponent, {
      data: {
        delPersonId,
        statusObj: this.statusObj
      },
      width: '50vw !important',
      height: '88vh', maxWidth: '50vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDeliveryPerson();
      }
    });
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

}
