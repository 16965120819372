import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/auth/user/user.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { RestaurantService } from '../restaurant/restaurant.service';

@Component({
  selector: 'app-login-log',
  templateUrl: './login-log.component.html',
  styleUrls: ['./login-log.component.scss']
})
export class LoginLogComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'activity', 'client', 'user', 'created_at'];
  loginLogs = [];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  userRole: number;
  paymentFilterForm = new FormGroup({
    restaurant: new FormControl('0'),
    payment_status: new FormControl('1'),
    from: new FormControl(new Date(moment().subtract(7, 'd').format('YYYY-MM-DD'))),
    to: new FormControl(new Date())
  });
  restaurantList = [];
  filteredRestaurantList = [];

  constructor(private userService: UserService, private spinner: NgxSpinnerService, public toastr: ToastrService, private appService: AppService, private restaurantService: RestaurantService, private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.userRole = this.appService.getUserRole();
    if (this.userRole == 1) {
      this.displayedColumns.splice(2, 0, 'restaurant');
    }
    this.getLoginLogs();
    this.getRestaurantList();
  }

  getLoginLogs() {
    this.spinner.show();
    this.userService.getLoginLog(this.paymentFilterForm.value).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.loginLogs = res.data;
        this.dataSource = new MatTableDataSource(this.loginLogs);
        this.page.length = this.loginLogs.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else
        this.toastr.error(res.message, 'Error')
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  returnJson(data: any, key: string) {
    return JSON.parse(data)[key];
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  search(filter: string) {
    this.filteredRestaurantList = this.restaurantList.filter((rest) => {
      let lowerCase = rest.name.toLocaleLowerCase();
      return rest.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getRestaurantList() {
    this.spinner.show();
    this.restaurantService.findAllRestaurants(this.paymentFilterForm.value).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.restaurantList = res.data;
        this.filteredRestaurantList = this.restaurantList.slice();
      }
      else this.toaster.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      console.log('err', err);
      this.toaster.error(err.message, 'Error');
    })
  }

}
