import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuListComponent } from './menu-list.component';
import { MenuFormModule } from '../menu-form/menu-form.module';
import { MenuPriceHistoryModule } from '../menu-price-history/menu-price-history.module';
import { ProductVariantListModule } from '../product-variant-list/product-variant-list.module';
import { ProductInfoModule } from '../product-info/product-info.module';
import { ProductVariantFormModule } from '../product-variant-form/product-variant-form.module';


@NgModule({
    declarations: [MenuListComponent],
    imports: [
        CommonModule,
        SharedModule,
        MenuFormModule,
        MenuPriceHistoryModule,
        ProductVariantListModule,
        ProductInfoModule,
        ProductVariantFormModule
    ],
    exports: [MenuListComponent]
})
export class MenuModule { }
