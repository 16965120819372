import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-store-plan',
  templateUrl: './store-plan.component.html',
  styleUrls: ['./store-plan.component.scss']
})
export class StorePlanComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public dataSource: any = new MatTableDataSource();
  public displayedColumns: string[] = ['sno', 'plan', 'posting', 'start_date', 'end_date', 'amount', 'active'];
  public page = { length: 0, pageIndex: 0, pageSize: 10 };
  public planList: any = [];
  public storeId!: number;
  public latestPostCount: number = 0;
  @Input() set setStoreId(value: number) {
    if (value) {
      this.storeId = value;
      this.getStorePlans();
    }
  }

  constructor(
    private restaurantService: RestaurantService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public appService: AppService
  ) { }

  ngOnInit(): void {
  }

  private getStorePlans(): void {
    this.spinner.show();
    const data = { store_id: this.storeId };
    this.restaurantService.getStorePlans(data).subscribe((res: any) => {
      if (res?.status) {
        this.planList = res?.data;
        this.latestPostCount = Math.max(...this.planList?.map(pln => pln?.posting)) || 0;
        this.dataSource = new MatTableDataSource(this.planList);
        this.page.length = this.planList?.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.error(res?.message, 'Error');
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  public openPlanActivationModal(template: TemplateRef<any>): void {
    const dialog = this.dialog.open(template, {
      width: '50vw !important',
      height: '68vh', minWidth: '50vw'
    });
    dialog.afterClosed().subscribe((_res) => {
      this.getStorePlans();
    })
  }

}
