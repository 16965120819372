import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { PromotionService } from '../promotion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'name', 'desc', 'start_date', 'end_date', 'order_amount', 'amount', 'percentage', 'created_by', 'action'];
  promotionList = [];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  user: any;

  constructor(private promotionService: PromotionService, private router: Router) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getPromotionList();
  }

  getPromotionList() {
    this.promotionList = this.promotionService.getPromotionList();
    this.dataSource = new MatTableDataSource(this.promotionList);
    this.page.length = this.promotionList.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  editPromotion(promotionId: number) {
    this.promotionService.setPromotionId(promotionId);
    this.router.navigate(['/admin/promotion-form']);
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

}
