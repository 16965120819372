import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-store-plan-view',
  templateUrl: './store-plan-view.component.html',
  styleUrls: ['./store-plan-view.component.scss']
})
export class StorePlanViewComponent implements OnInit {

  public storeId!: number;

  constructor(
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.storeId = parseInt(this.appService.getRestaurantId());
  }

}
