<div class="dialog-container">

    <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="text-color">{{ data?.delPersonId ? 'Update' : 'Create' }} Delivery Person</h3>
        <button
            class="btn-sm bg-danger border-danger rounded-corner p-0 d-flex justify-content-center align-items-center closeBtn"
            mat-dialog-close>
            <i class="fa fa-times text-white" aria-hidden="true"></i>
        </button>
    </div>
    <form name="deliveryPersonForm" [formGroup]="deliveryPersonForm" (ngSubmit)="onSubmit()">

        <mat-dialog-content class="mat-typography">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Name </mat-label>
                        <input matInput required formControlName="name">
                        <mat-error *ngIf="deliveryPersonForm?.controls?.name?.errors?.required"> Name is Required
                        </mat-error>
                        <mat-error *ngIf="deliveryPersonForm?.controls?.name?.errors?.maxlength"> Maximum characters
                            should be
                            {{deliveryPersonForm?.controls?.name?.errors?.maxlength?.requiredLength}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- <div class="col-sm-12 col-md-6" *ngIf="appService?.getUserRole() == 1">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Store </mat-label>
                        <mat-select formControlName="store_id" (selectionChange)="getPaymentBatch()">
                            <input (keyup)="search($event.target.value, 'Z')" class="form-control w-100"
                                placeholder="Search Store" />
                            <mat-option *ngFor="let st of filteredStoreList" [value]="st.id">
                                {{st.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <div class="col-sm-12 col-md-6">
                    <ngx-intl-tel-input class="form-control h-52px mt-1 d-flex align-items-center" matInput
                        [cssClass]="'phoneNumberClass'" [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10"
                        [phoneValidation]="true" [separateDialCode]="false" [numberFormat]="PhoneNumberFormat.National"
                        name="phoneno" [(ngModel)]="phoneObj" [ngModelOptions]="{standalone: true}">
                    </ngx-intl-tel-input>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email </mat-label>
                        <input matInput
                            (input)="deliveryPersonForm?.controls?.email?.status === 'VALID' && checkAlreadyExists('users', 'email', deliveryPersonForm?.value?.admin_email)"
                            type="email" formControlName="email" required>
                        <mat-error>Enter a Valid Email </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Zip Code</mat-label>
                        <input matInput formControlName="zipcode">
                        <mat-error>Enter a Valid Zip Code </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Address Line 1</mat-label>
                        <input matInput required formControlName="address1">
                        <mat-error> Address 1 is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Address Line 2</mat-label>
                        <input matInput formControlName="address2">
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Address Line 3</mat-label>
                        <input matInput formControlName="address3">
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>State</mat-label>
                        <mat-select
                            (selectionChange)="deliveryPersonForm?.value?.state_id && getCityList(deliveryPersonForm?.value?.state_id)"
                            required formControlName="state_id">
                            <input (keyup)="search($event.target.value, 'state')" class="form-control w-100"
                                placeholder="Search State">
                            <mat-option>Select State</mat-option>
                            <mat-option *ngFor="let state of filteredStateList" [value]="state.id">
                                {{state.name + ' (' + state.code + ')'}}
                            </mat-option>
                        </mat-select>
                        <mat-error> State is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>City</mat-label>
                        <mat-select required formControlName="city_id">
                            <input (keyup)="search($event.target.value, 'city')" class="form-control w-100"
                                placeholder="Search City">
                            <mat-option>Select City</mat-option>
                            <mat-option *ngFor="let city of filteredCityList" [value]="city.id">
                                {{city.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error> City is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Status </mat-label>
                        <mat-select formControlName="status">
                            <mat-option *ngFor="let st of statusObj | keyvalue" [value]="st.key">
                                {{st?.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row justify-content-start px-3 my-2">
                    <div class="col-sm-12 col-md-2">
                        <img class="choose-img rounded cursor-pointer" [src]="image?.src" (click)="uploadPicture()">
                        <input type="file" name="file" #file accept="image/png, image/jpg, image/jpeg" class="d-none"
                            (change)="readFile($event)">
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="d-flex align-items-center justify-content-end">
            <div>
                <button mat-button mat-dialog-close class="bg-warning text-white">Cancel</button>
                <button mat-button class="bg-success text-white" cdkFocusInitial>{{data?.delPersonId ? 'Update' :
                    'Create'}}</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>