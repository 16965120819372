import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'payment', component: PaymentGatewayComponent },
      { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
      { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
      { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
      { path: 'order', loadChildren: () => import('./order/order.module').then(m => m.OrderModule), canActivate: [AuthGuard] },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },
      { path: 'reports', loadChildren: () => import('./report/report.module').then(m => m.ReportModule), canActivate: [AuthGuard] },
      { path: 'user-req-prod', loadChildren: () => import('./prod-not-found/prod-not-found.module').then(m => m.ProdNotFoundModule), canActivate: [AuthGuard] }
    ]
  },
  { path: '**', redirectTo: 'Home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
