<table mat-table [dataSource]="categoryDataSource" matSort class="mat-elevation-z8 w-100">

    <ng-container matColumnDef="selected" *ngIf="appService?.getUserRole() != 1">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="selectAll($event)" [(ngModel)]="selectedAllCategory"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element" class="pr-3">
            <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
        <td mat-cell *matCellDef="let element; let i = index">{{ (categoryPage.pageIndex *
            categoryPage.pageSize) + i + 1}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
    </ng-container>

    <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element"> {{element?.desc}} </td>
    </ng-container>

    <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
        <td mat-cell *matCellDef="let element">
            <img [src]="apiUrl+'/'+element?.picture" [alt]="element?.picture" class="w-50px" *ngIf="element?.picture">
        </td>
    </ng-container>

    <ng-container matColumnDef="action" *ngIf="appService?.getUserRole() == 1">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Action</th>
        <td mat-cell *matCellDef="let element, index as i" class="text-center">
            <button mat-flat-button color="primary" (click)="editCategory(element)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                        fill="white" />
                </svg>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="categoryColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: categoryColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{language && language.hasOwnProperty('No data matching') ?
            language['No
            data matching'] : 'No data matching' }}
        </td>
    </tr>
</table>

<div class="d-flex justify-content-end mt-4 w-100">
    <button class="btn btn-success" (click)="updateGlobalList()">Update</button>
</div>