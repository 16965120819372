import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { AppService } from 'src/app/app.service';
import { UserService } from 'src/app/auth/user/user.service';
import { DeliveryPersonService } from 'src/app/settings/delivery-person-list/delivery-person.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delivery-person-form',
  templateUrl: './delivery-person-form.component.html',
  styleUrls: ['./delivery-person-form.component.scss']
})
export class DeliveryPersonFormComponent implements OnInit {

  public deliveryPersonForm!: FormGroup;
  public phoneObj: any = { number: '', countryCode: '', dialCode: '' };
  public SearchCountryField = SearchCountryField
  public CountryISO = CountryISO
  public PhoneNumberFormat = PhoneNumberFormat
  public preferredCountries: CountryISO[] = [CountryISO.UnitedStates]
  public image = { name: '', src: '../../../../assets/images/placeholder.jpeg', file: '' }
  @ViewChild('file') file!: ElementRef;
  public storeList!: any[];
  public filteredStoreList!: any[];
  public statusObj!: Object;
  public stateList = [];
  public filteredStateList = [];
  public cityList = [];
  public filteredCityList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DeliveryPersonFormComponent>,
    public toastr: ToastrService,
    private deliveryPersonService: DeliveryPersonService,
    private spinner: NgxSpinnerService,
    public appService: AppService,
    private restaurantService: RestaurantService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.statusObj = this.data?.statusObj;
    this.intializeForm();
    if (this.data?.delPersonId) {
      this.getDeliveryPersonInfo();
    }
    // if (this.appService?.getUserRole() == 1) {
    //   this.getDropdownStores();
    // }
    this.getStateList();
  }

  private getDropdownStores(): void {
    this.spinner.show();
    this.restaurantService.getDropdownStores({}).subscribe((res: any) => {
      if (res?.status) {
        this.storeList = res?.data;
        this.filteredStoreList = [...this.storeList];
      }
      else
        this.toastr.error(res?.message, 'Error');
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  private getDeliveryPersonInfo(): void {
    this.spinner.show();
    const data = {
      id: this.data.delPersonId
    };
    this.deliveryPersonService.getDeliveryPerson(data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        if (res?.status) {
          this.phoneObj = {
            number: res?.data?.phone,
            countryCode: res?.data?.country_code,
            dialCode: res?.data?.dial_code
          }
          res.data.status = res?.data?.status?.toString();
          this.deliveryPersonForm?.patchValue(res?.data);
          if (res?.data?.attachment) {
            this.image.src = `${environment.API_URL}/${res?.data?.attachment}`;
          }
          if (res?.data?.state_id) {
            this.getCityList(res?.data?.state_id);
          }
        }
      },
      error: (err) => {
        this.spinner.hide();
      }
    })
  }

  private intializeForm(): void {
    this.deliveryPersonForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      // store_id: new FormControl(null),
      attachment: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      address3: new FormControl(''),
      zipcode: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(5), Validators.minLength(5)]),
      vechile_no: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(8)]),
      status: new FormControl('3', [Validators.required]),
      state_id: new FormControl('', [Validators.required]),
      city_id: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  public uploadPicture() {
    this.file.nativeElement.click();
  }

  public readFile(event: any) {
    const file = event.target.files[0];
    if (file && (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png')) {
      this.image.name = file.name;
      this.image.file = file;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.image.src = reader.result as string;
        };
      }
    }
    else {
      this.toastr.error('Please choose jpeg, jpg or png image format only', 'Error')
      return;
    }

  }

  public onSubmit(): void {
    if (!this.phoneObj?.dialCode || !this.phoneObj?.number) {
      this.toastr.warning('Please enter phone number', 'Warning');
      return; 
    }
    const data = this.deliveryPersonForm?.value;
    data['country_code'] = this.phoneObj?.countryCode;
    data['dial_code'] = this.phoneObj?.dialCode;
    data['phone'] = this.phoneObj?.number;
    if (this.data?.delPersonId) {
      data['id'] = this.data?.delPersonId;
    }
    const formData = new FormData();
    formData.append('attachment', this.image.file);
    formData.append('data', JSON.stringify(data));
    this.spinner.show();
    this.deliveryPersonService.createUpdate(formData).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        this.toastr.success(res?.message, 'Success');
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.spinner.hide();
      }
    });
  }

  private getStateList(): void {
    this.spinner.show();
    this.userService.getStates().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.stateList = res?.data || [];
        this.filteredStateList = [...this.stateList];
      }
      else
        this.toastr.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  search(value: string, type: string) {
    let filter = value;
    if (type == 'state') {
      this.filteredStateList = this.stateList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else if (type == 'store') {
      this.filteredStoreList = this.storeList.filter((store) => {
        let lowerCase = store.name.toLocaleLowerCase();
        return store.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else {
      this.filteredCityList = this.cityList.filter((city) => {
        let lowerCase = city.name.toLocaleLowerCase();
        return city.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  public getCityList(state_id: number) {
    this.spinner.show();
    const data = { state_id: state_id };
    this.userService.getCities(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.cityList = res.data;
        this.filteredCityList = [...this.cityList];
      }
      else
        this.toastr.error(res.message, 'Error');
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    })
  }

  checkAlreadyExists(type: string, prop: string, value: string) {
    const obj = { type: type, prop: prop, value: value };
    this.appService.checkExists(obj).subscribe((res: any) => {
      if (res?.status && res?.data) {
        this.toastr.warning(`${value} already exists`, 'Warning');
      }
    })
  }

}
