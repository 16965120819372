import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotficationSettingsComponent } from './notfication-settings/notfication-settings.component';
import { ResSettingsComponent } from './res-settings/res-settings.component';
import { DeliveryPersonListComponent } from './delivery-person-list/delivery-person-list.component';

const routes: Routes = [
  { path: '', component: ResSettingsComponent },
  { path: 'restaurant', component: ResSettingsComponent },
  { path: 'notification', component: NotficationSettingsComponent },
  { path: 'delivery', component: DeliveryPersonListComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
