import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PriceReportComponent } from './price-report/price-report.component';
import { StrPlanRprtComponent } from './str-plan-rprt/str-plan-rprt.component';
import { UserReportComponent } from './user-report/user-report.component';

const routes: Routes = [
  { path: '', component: PriceReportComponent },
  { path: 'user-report', component: UserReportComponent },
  { path: 'store-plan-report', component: StrPlanRprtComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
