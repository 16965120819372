<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<div class="container-fluid">
    <div class="my-2 d-flex align-items-center justify-content-between">
        <h2 class="text-color">Payment Batches </h2>
        <button mat-flat-button class="bg-success text-white" (click)="openPaymentModal(paymentModal)" *ngIf="appService?.getUserRole() == 1">Create
            Payment Batch</button>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-3" *ngIf="appService?.getUserRole() == 1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Store </mat-label>
                <mat-select [(ngModel)]="batchFilter.store_id" (selectionChange)="getPaymentBatch()" required>
                    <input (keyup)="search($event.target.value)" class="form-control w-100" placeholder="Search Store" />
                    <mat-option *ngFor="let st of filteredStoreList" [value]="st.id">
                        {{st.name}}
                    </mat-option>
                </mat-select>
                <mat-error> Payment Type is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>From</mat-label>
                <input (dateChange)="getPaymentBatch()" readonly [(ngModel)]="batchFilter.from" matInput
                    [matDatepicker]="picker" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>To</mat-label>
                <input (dateChange)="getPaymentBatch()" readonly [(ngModel)]="batchFilter.to" matInput
                    [matDatepicker]="to" />
                <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                <mat-datepicker #to></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <table mat-table [dataSource]="paymentDataSource" matSort class="mat-elevation-z8 w-100">
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
            <td mat-cell *matCellDef="let element; let i = index">{{ i + 1}}</td>
        </ng-container>

        <ng-container matColumnDef="store" *ngIf="appService?.getUserRole() == 1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
            <td mat-cell *matCellDef="let element"> {{ element?.store?.name }} </td>
        </ng-container>

        <ng-container matColumnDef="commission">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Commission </th>
            <td mat-cell *matCellDef="let element" class="text-success">{{ element?.commission | currency : '$' }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Amount Without Commission</th>
            <td mat-cell *matCellDef="let element"> {{ element?.amount | currency : '$' }} </td>
        </ng-container>

        <ng-container matColumnDef="payment_method">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Method</th>
            <td mat-cell *matCellDef="let element"> {{ element.type }} </td>
        </ng-container>

        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> From</th>
            <td mat-cell *matCellDef="let element"> {{ element.from | date: 'dd-MM-yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> To</th>
            <td mat-cell *matCellDef="let element"> {{ element.to | date: 'dd-MM-yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
            <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy hh:mm:ss a'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Action</th>
            <td mat-cell *matCellDef="let element, index as i" class="text-center">
                <button mat-flat-button color="primary" (click)="getPaymentBatchInfo(paymentModal, element?.id)">
                    <i class="fa fa-eye text-white"></i>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="paymentBatchDataColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: paymentBatchDataColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12" style="text-align: center;">No data matching </td>
        </tr>
    </table>

    <mat-paginator [pageSize]="10" [pageIndex]="page?.pageIndex" [length]="page?.length"
        (page)="customePaginator($event)" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
</div>


<ng-template #paymentModal>
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="text-color">Payment Batch <span *ngIf="paymentBatchInfo?.id">- {{ paymentBatchInfo?.created_at | date: 'ddMM' }}{{ paymentBatchInfo?.id }}</span></h3>
        <button mat-dialog-close mat-flat-button>Close</button>
    </div>
    <div *ngIf="isView" class="row">
        <div class="col-sm-12 col-md-3">
            <label class="title text-muted">Store</label>
            <p class="values">{{ paymentBatchInfo?.store?.name }}</p>
        </div>
        <div class="col-sm-12 col-md-3">
            <label class="title text-muted">Payment Type</label>
            <p class="values">{{ paymentBatchInfo?.type }}</p>
        </div>
        <div class="col-sm-12 col-md-3">
            <label class="title text-muted">From</label>
            <p class="values">{{ paymentBatchInfo?.from | date : 'dd MMM, yyyy' }}</p>
        </div>
        <div class="col-sm-12 col-md-3">
            <label class="title text-muted">To</label>
            <p class="values">{{ paymentBatchInfo?.to | date : 'dd MMM, yyyy' }}</p>
        </div>
    </div>
    <div *ngIf="!isView" class="row">
        <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Store </mat-label>
                <mat-select [(ngModel)]="payment.store_id" (selectionChange)="getPendingPayments()" required>
                    <input (keyup)="search($event.target.value)" class="form-control w-100" placeholder="Search Store" />
                    <mat-option *ngFor="let st of filteredStoreList" [value]="st.id">
                        {{st.name}}
                    </mat-option>
                </mat-select>
                <mat-error> Payment Type is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Payment Type </mat-label>
                <mat-select [(ngModel)]="payment.type" required>
                    <mat-option *ngFor="let pt of paymentTypeList" [value]="pt">
                        {{pt}}
                    </mat-option>
                </mat-select>
                <mat-error> Payment Type is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>From</mat-label>
                <input (dateChange)="getPendingPayments()" readonly [(ngModel)]="payment.from" matInput
                    [matDatepicker]="picker" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>To</mat-label>
                <input (dateChange)="getPendingPayments()" readonly [(ngModel)]="payment.to" matInput
                    [matDatepicker]="to" />
                <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                <mat-datepicker #to></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="w-100 my-2">
        <div class="d-flex justify-content-between align-items-center mb-3" *ngIf="ordersList?.length">
            <h5 class="text-color">Orders we deliveried on {{ payment.from | date: 'dd-MM-yyyy' }} to {{ payment.to | date: 'dd-MM-yyyy' }}
            </h5>
            <h5 class="text-success">Total Commission Amount {{ commissionTotal | currency: '$' }}</h5>
        </div>
        <table mat-table [dataSource]="orderDataSource" matSort class="mat-elevation-z8 w-100">
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
                <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
            </ng-container>

            <ng-container matColumnDef="order_id">
                <th mat-header-cell *matHeaderCellDef>Order Id </th>
                <td mat-cell *matCellDef="let element">{{ element.order_id }}</td>
            </ng-container>

            <ng-container matColumnDef="order_total">
                <th mat-header-cell *matHeaderCellDef>Order Total</th>
                <td mat-cell *matCellDef="let element"> {{ element.order_total | currency : '$' }} </td>
            </ng-container>
            
            <ng-container matColumnDef="commission">
                <th mat-header-cell *matHeaderCellDef>Commission </th>
                <td mat-cell *matCellDef="let element">{{ element.commission | currency : '$' }}</td>
            </ng-container>

            <ng-container matColumnDef="total_without_commission">
                <th mat-header-cell *matHeaderCellDef>Order Total Without Commission</th>
                <td mat-cell *matCellDef="let element"> {{ (element?.order_total - element?.commission) | currency : '$' }} </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef>Order Date</th>
                <td mat-cell *matCellDef="let element"> {{ element.created_at | date: 'dd MMM, yyyy' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="orderDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: orderDataColumns;"></tr>
        </table>
    </div>

    <div class="d-flex float-right mt-3">
        <button mat-dialog-close mat-flat-button class="bg-warning">Cancel</button>
        <button *ngIf="ordersList?.length && payment?.store_id && payment?.type && !isView" type="submit" mat-flat-button class="bg-success text-white float-right mx-3" (click)="generatePaymentBatch()">Create
            Payment Batch</button>
    </div>
</ng-template>