import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/admin/product/product.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-product-variant-form',
  templateUrl: './product-variant-form.component.html',
  styleUrls: ['./product-variant-form.component.scss']
})
export class ProductVariantFormComponent implements OnInit {

  unitList = [
    { value: 'kg', name: 'kg' },
    { value: 'lb', name: 'lb' },
    { value: 'lt', name: 'lt' }
  ]
  @ViewChild('file') file!: ElementRef;
  apiUrl: string = environment.API_URL;
  image: any = { name: '', src: '../../../assets/images/placeholder.jpeg', file: '' };
  productVariantId: number;
  @Input() productId: number;
  @Input() set setProductVariantId(value) {
    this.productVariantId = value ? value : null;
    if (this.productVariantId)
      this.getProductVariantInfo();
  };
  editProduct: any;
  productVariantForm;
  @Output() closePopup = new EventEmitter();
  @Input() posssible_variants;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, public appService: AppService) { }

  ngOnInit(): void {
    this.productVariantForm = new FormGroup({
      unit: new FormControl('', [Validators.required]),
      desc: new FormControl('', [Validators.minLength(1), Validators.maxLength(200)]),
      is_active: new FormControl(true, [Validators.required]),
      limit: new FormControl(''),
      has_limit: new FormControl(''),
      quantity: new FormControl(''),
      price: new FormControl('', (this.appService.getUserRole() != 1 && this.appService?.getUserRole() != 9) ? [Validators.required] : [])
    });
  }

  updateValidation() {
    if (this.productVariantForm.value.has_limit) {
      this.productVariantForm.controls['limit'].setValidators([Validators.required]);
      this.productVariantForm.controls['limit'].updateValueAndValidity();
      this.productVariantForm.controls['quantity'].setValidators([Validators.required]);
      this.productVariantForm.controls['quantity'].updateValueAndValidity();
    }
    else {
      this.productVariantForm.controls['limit'].clearValidators();
      this.productVariantForm.controls['limit'].updateValueAndValidity();
      this.productVariantForm.controls['quantity'].clearValidators();
      this.productVariantForm.controls['quantity'].updateValueAndValidity();
    }
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.image.name = file.name;
    this.image.file = file;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.src = reader.result as string;
      };
    }
  }

  choosePicture() {
    this.file.nativeElement.click();
  }

  getProductVariantInfo() {
    this.spinner.show();
    const data = {
      id: this.productVariantId
    }
    this.productService.findAllVariants(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.productVariantForm.controls.unit.patchValue(res.data.unit);
        this.productVariantForm.controls.desc.patchValue(res.data.desc);
        if (res.data.store_item_variants && res.data.store_item_variants.length) {
          this.productVariantForm.controls.limit.patchValue(res.data.store_item_variants[0].limit);
          this.productVariantForm.controls.has_limit.patchValue(res.data.store_item_variants[0].has_limit);
          this.productVariantForm.controls.quantity.patchValue(res.data.store_item_variants[0].quantity);
          this.productVariantForm.controls.price.patchValue(res.data.store_item_variants[0].price);
          this.productVariantForm.controls.is_active.patchValue(res.data.store_item_variants[0].is_active);
        }
        if (res.data.picture)
          this.image.src = this.apiUrl + '/' + res.data.picture;
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  onSubmit() {
    if (this.productVariantForm.invalid)
      return;
    else {
      const formData = new FormData();
      const data = this.productVariantForm.value;
      data.item_id = this.productId;
      if (this.productVariantId)
        data.id = this.productVariantId;
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(data));
      this.spinner.show();
      this.productService.createProductVariant(formData).subscribe((res: any) => {
        if (res.status) {
          this.toastr.success(res.message, 'Success');
          // this.openProductVariantsPopup.emit({ productId: this.productId, posssible_variants: this.posssible_variants });
          this.productVariantForm.reset();
          this.image = { name: '', src: '', file: '' };
          this.spinner.hide();
          this.closePopup.emit();
        }
        else this.toastr.error(res.message, 'Error')
      },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }

}
