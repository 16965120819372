import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { TableService } from '../table_/table.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

  @ViewChild('reservationModal') reservationModal!: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'user', 'phone', 'date', 'start_time', 'end_time', 'person', 'status', 'cancel', 'confirm', 'action'];
  page = { length: 0, pageIndex: 0, pageSize: 10 };
  tableList: any = [];
  apiUrl: string = environment.API_URL;
  reservationInfo: any;

  constructor(private tableService: TableService, private dialog: MatDialog, private appService: AppService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getReservationList();
  }

  getReservationList() {
    const data = { store_id: this.appService.getRestaurantId() };
    this.spinner.show();
    this.tableService.findAllReservation(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.tableList = res.data;
        this.dataSource = new MatTableDataSource(this.tableList);
        this.page.length = res.ordersCount;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  updateStatus(id: number, status: number) {
    const data = { id: id, status: status };
    this.spinner.show();
    this.tableService.updateReservationStatus(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message);
        this.getReservationList();
      }
      else
        this.toastr.error(res.message, 'Error')
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  openPopup(data?: any) {
    console.log('data', data);

    this.reservationInfo = data;
    const dialogRef = this.dialog.open(this.reservationModal);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.getReservationList();
    });
  }

  customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

}
