import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  userDetails: any;
  userName: string;
  currentLang = 'en';
  apiUrl: string = '';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.API_URL;
  }

  getRestaurantId() {
    let store_id = localStorage.getItem('store_id');
    return store_id;
  }

  setUserDetails(res: any) {
    localStorage.setItem('token', res.token);
    localStorage.setItem('user', JSON.stringify(res.user));
    localStorage.setItem('store_id', res.user.store_id);
  }

  getUserId() {
    let user: any = localStorage.getItem('user');
    user = user ? JSON.parse(user) : '';
    return user ? user.id : '';
  }

  getRestaurantDetails() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user && user.restaurant ? user.restaurant : null;
  }

  getUserDetails() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  getUserRole() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user.role : null;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  createRoles() {
    return this.http.get(`${this.apiUrl}/v1/users/createRoles`)
      .pipe(catchError(this.errorHandler));
  }

  checkExists(obj: any) {
    return this.http.get(`${this.apiUrl}/v1/users/checkEmailPhoneExists`, { params: obj })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}
