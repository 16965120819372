<div class="container-fluid">
    <div class="row">
        <div class="col-3">
            <img [src]="productInfo?.picture" class="rounded">
        </div>
        <div class="col-9">
            <div class="row">
                <div class="col-3">
                    <p>Product Name</p>
                    <p>Product Description</p>
                    <p>Brand</p>
                    <p>Category</p>
                    <p>Sub Category</p>
                </div>
                <div class="col-5">
                    <p>: {{ productInfo?.name }}</p>
                    <p>: {{ productInfo?.desc }}</p>
                    <p>: {{ productInfo?.brand?.name }}</p>
                    <p>: {{ productInfo?.category?.name }}</p>
                    <p>: {{ productInfo?.sub_category?.name }}</p>
                </div>
                <div class="col-4">
                    <h3>Possible Variants</h3>
                    <p class="pl-3" *ngFor="let variant of productInfo?.posssible_variants">{{ variant}}</p>
                </div>
            </div>
        </div>
    </div>
</div>