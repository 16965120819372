import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    httpCnt = 0; // TO CHECK ANY HTTP CALL IS PENDING
    constructor(
        private router: Router,
        private appService: AppService,
        private spinner: NgxSpinnerService
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let clonedReq: HttpRequest<any> = req.clone({
            setHeaders: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'frpswdSess': req.url.split('/')[req.url.split('/').length - 1] === 'updatePassword' ? 'true' : 'false'
            }
        });
        const authToken = this.appService.getToken();
        if (authToken) {
            const headerObj = {
                Authorization: 'Bearer' + ' ' + authToken
            };
            if(this.appService.getRestaurantId() && this.appService.getRestaurantId() !== 'null') {
                headerObj['store_id'] = this.appService.getRestaurantId().toString()
            }
            clonedReq = req.clone({
                setHeaders: headerObj
            });
        }
        else {
            clonedReq = req;
        }

        this.httpCnt += 1;
        return next.handle(clonedReq).pipe(
            catchError((error) => this.httpHandleError(error)),
            finalize(() => {
                this.httpCnt -= 1;
                if (this.httpCnt === 0) {
                    // LOADING MASK STOP
                }
            })
        );
    }

    private httpHandleError(error: HttpErrorResponse): Observable<any> {
        console.log('coming to error handler');
        this.spinner.hide();
        // handle your auth error or rethrow
        if (error.status === 401 || error.status === 403) {
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');        
            localStorage.setItem('unauthorized', 'true');
            this.router.navigate(['/login']);
            // navigate /delete cookies or whatever
            return of(error.message); // or EMPTY may be appropriate here
        }
        return throwError(error);
    }
}
