import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductInfoModule } from '../product-info/product-info.module';
import { ProductVariantFormComponent } from './product-variant-form.component';


@NgModule({
    declarations: [ProductVariantFormComponent],
    imports: [
        CommonModule,
        SharedModule,
        ProductInfoModule
    ],
    exports: [ProductVariantFormComponent]
})
export class ProductVariantFormModule { }
