import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextEllipsisComponent } from './text-ellipsis.component';


@NgModule({
    declarations: [TextEllipsisComponent],
    imports: [
        CommonModule
    ],
    exports: [TextEllipsisComponent]
})
export class TextEllipsisModule { }
