<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>

<div class="container-fluid">
  <div class="my-2 d-flex align-items-center justify-content-between">
    <h2>Reservation List</h2>
  </div>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="mat-elevation-z8 w-100"
  >
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ page.pageIndex * page.pageSize + i + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
      <td mat-cell *matCellDef="let element">{{ element?.user?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone No</th>
      <td mat-cell *matCellDef="let element">{{ element?.user?.phone }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | date: "MMM d" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="start_time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
      <td mat-cell *matCellDef="let element">
        {{ element.start_time | date: "h:mm a" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="end_time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>End Time</th>
      <td mat-cell *matCellDef="let element">
        {{ element.end_time | date: "h:mm a" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="person">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Person</th>
      <td mat-cell *matCellDef="let element">{{ element?.person }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td
        mat-cell
        *matCellDef="let element"
        [class]="
          !element?.status
            ? 'text-center bg-danger text-white'
            : element?.status == 1
            ? 'text-center bg-warning text-black'
            : 'text-center bg-success text-white'
        "
      >
        {{
          !element?.status
            ? "Cancelled"
            : element?.status == 1
            ? "Waiting"
            : "Confirmed"
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cancel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cancel</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <button
          *ngIf="element?.status == 1"
          (click)="updateStatus(element?.id, 0)"
          class="btn bg-danger text-white"
        >
          Cancel
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="confirm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Confirm</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <button
          *ngIf="element?.status == 1"
          (click)="updateStatus(element?.id, 1)"
          class="btn bg-success text-white"
        >
          Confirm
        </button>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
      <td mat-cell *matCellDef="let element">
        <p style="margin: 5px 0px 0px 0px">
          {{ element.created_at | date: "MMM d, y h:mm a" }}
        </p>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-center">Info</th>
      <td mat-cell *matCellDef="let element; index as i" class="text-center">
        <i
          class="fa fa-eye"
          (click)="openPopup(element)"
          aria-hidden="true"
        ></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{
          language && language.hasOwnProperty("No data matching")
            ? language["No
                data matching"]
            : "No data matching"
        }}
      </td>
    </tr>
  </table>

  <mat-paginator
    [pageSize]="10"
    [pageIndex]="page.pageIndex"
    [length]="page.length"
    (page)="customePaginator($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<ng-template #reservationModal>
  <h3>Reservation Info</h3>
  <table class="table table-responsive">
    <tbody>
      <tr>
        <td>User Name</td>
        <td class="text-secondary">: {{ reservationInfo?.user?.name }}</td>
        <td>Phone No</td>
        <td class="text-secondary">: {{ reservationInfo?.user?.phone }}</td>
      </tr>
      <tr>
        <td class="border-0">Reservation Date</td>
        <td class="border-0 text-secondary">: {{ reservationInfo?.date | date: "MMM d" }}</td>
        <td class="border-0">Person</td>
        <td class="border-0 text-secondary">: {{ reservationInfo?.person }}</td>
      </tr>
      <tr>
        <td class="border-0">Start Time</td>
        <td class="border-0 text-secondary">: {{ reservationInfo?.start_time | date: "hh:mm a" }}</td>
        <td class="border-0">End Time</td>
        <td class="border-0 text-secondary">: {{ reservationInfo?.end_time | date: "hh:mm a" }}</td>
      </tr>
      <tr>
        <td class="border-0">Status</td>
        <td
          [class]="
            !reservationInfo?.status
              ? 'border-0 text-danger'
              : reservationInfo?.status == 1
              ? 'border-0 text-warning'
              : 'border-0 text-success'
          "
        >
          :
          {{
            !reservationInfo?.status
              ? "Cancelled"
              : reservationInfo?.status == 1
              ? "Waiting"
              : "Confirmed"
          }}
        </td>
        <td class="border-0">Booked Time</td>
        <td class="border-0 text-secondary">: {{ reservationInfo?.created_at | date: "MMM d, hh:mm a" }}</td>
      </tr>
      <tr *ngIf="reservationInfo?.table_list.length">
        <td colspan="4" class="text-secondary">Table Info</td>
      </tr>
      <tr *ngIf="reservationInfo?.table_list.length">
        <td class="bg-primary text-white" colspan="2">Name</td>
        <td class="bg-primary text-white">Person</td>
        <td class="bg-primary text-white">Picture</td>
      </tr>
      <tr *ngFor="let table of reservationInfo?.table_list">
        <td colspan="2">{{ table?.name }}</td>
        <td>{{ table?.person }}</td>
        <td>
          <img [src]="apiUrl + '/' + table?.picture" [alt]="table?.picture" />
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <button class="btn bg-warning text-black" mat-dialog-close>
            Close
          </button>
        </td>
        <td>
          <button [disabled]="reservationInfo?.status != 1" class="btn bg-danger text-white" (click)="updateStatus(reservationInfo?.id, 0)">Reject Reservation</button>
        </td>
        <td>
          <button [disabled]="reservationInfo?.status != 1" class="btn bg-success text-white" (click)="updateStatus(reservationInfo?.id, 1)">Confirm Reservation</button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
