import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  promotionId: number = null;
  constructor() { }

  setPromotionId(promotionId: number) {
    this.promotionId = promotionId;
  }

  getPromotionId() {
    return this.promotionId;
  }

  createPromotion(promotion: any) {
    let promotionList: any = JSON.parse(localStorage.getItem('promotionList')) || [];
    promotion.id = promotionList && promotionList.length ? promotionList[promotionList.length - 1].id + 1 : 1;
    promotionList.push(promotion);
    localStorage.setItem('promotionList', JSON.stringify(promotionList));
  }

  getPromotionById(promotionId: number) {
    const promotionList: any = JSON.parse(localStorage.getItem('promotionList')) || [];
    const promotionIndex = promotionList.findIndex((promotion: any) => promotion.id == promotionId);
    return promotionList[promotionIndex];
  }

  updatePromotion(promotion: any, promotionId: number) {
    let promotionList: any = JSON.parse(localStorage.getItem('promotionList')) || [];
    const categoryIndex = promotionList.findIndex((pro: any) => pro.id == promotionId);
    promotionList[categoryIndex] = promotion;
    localStorage.setItem('promotionList', JSON.stringify(promotionList));
    this.promotionId = null;
  }

  getPromotionList() {
    const user: any = JSON.parse(localStorage.getItem('user'));
    let promotionList: any = JSON.parse(localStorage.getItem('promotionList')) || [];
    if (user.role == 1)
      promotionList = promotionList.filter((promotion: any) => !promotion.store_id)
    else
      promotionList = promotionList.filter((promotion: any) => !promotion.store_id || (promotion.store_id && promotion.store_id == user.store_id))
    return promotionList;
  }

}
