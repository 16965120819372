import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryPersonService {

  private url!: string;
  public updateList: Subject<boolean>;

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
    this.updateList = new Subject();
  }

  getDeliveryPerson(data) {
    return this.http.get(`${this.url}/v1/delivery`, { params: data }).pipe(catchError(this.errorHandler));
  }

  createUpdate(data) {
    return this.http.post(`${this.url}/v1/delivery/createUpdate`, data).pipe(catchError(this.errorHandler));
  }

  acceptOrder(data) {
    return this.http.post(`${this.url}/v1/delivery/acceptOrder`, data).pipe(catchError(this.errorHandler));
  }

  updateDeliveryPersonStatus(data) {
    return this.http.post(`${this.url}/v1/delivery/updateDeliveryPersonStatus`, data).pipe(catchError(this.errorHandler));
  }

  getDeliveryPersonStatus(data) {
    return this.http.get(`${this.url}/v1/delivery/getDeliveryPersonStatus`, { params: data }).pipe(catchError(this.errorHandler));
  }

  triggerUpdateList(): void {
    this.updateList.next(true);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}

