import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../app.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, public appService: AppService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.appService.getToken();
        if (!token) {
            localStorage.setItem('unauthorized', 'true');
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }
}
