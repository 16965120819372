<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<main class="main-layout">
    <app-sidebar *ngIf="isLogin" [isMobMenuActive]="isMobMenuActive" (menuChangeEvent)="onMenuTogglerChange($event)">
    </app-sidebar>
    <div class="content-area" [ngClass]="{'logged-in': isLogin, 'menu-collapsed': !isMenuOpen}">
        <div class="main-navbar-close-layer" [ngClass]="{'mob-menu-active': isMobMenuActive}" (click)="closeMobMenu()">
        </div>
        <app-header *ngIf="isLogin" [isMenuOpen]="isMenuOpen" (mobMenuTogglerEvent)="onMobMenuChange($event)">
        </app-header>
        <router-outlet></router-outlet>
    </div>
</main>