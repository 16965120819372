<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center">
        <h3>User Report</h3>
        <div class="d-flex justify-content-between align-items-center">
            <div class="w-100 mr-4">
                <mat-label>Show Records By</mat-label>
                <div class="d-flex justify-content-between align-items-center mt-2">
                    <mat-checkbox (change)="updateCheckbox()" [checked]="totalCount" class="mr-4">No Of Orders</mat-checkbox>
                    <mat-checkbox (change)="updateCheckbox()" [checked]="totalAmount">Order Amount</mat-checkbox>    
                </div>
            </div>
            <mat-form-field appearance="outline" class="w-100" *ngIf="appService?.getUserRole() == 1" >
                <mat-label>Select Store</mat-label>
                <mat-select [(ngModel)]="storeId" (selectionChange)="getUserReport()">
                    <input (keyup)="search($event.target.value)" class="form-control w-100"
                        placeholder="Search Store" />
                    <mat-option *ngFor="let st of filteredStoreList" [value]="st.id">
                        {{st.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">

        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
            <td mat-cell *matCellDef="let element; let i = index">{{(page?.pageIndex *
                categoryPage?.pageSize) + i + 1}}</td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
            <td mat-cell *matCellDef="let element"> {{element?.user?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let element"> +{{element?.user?.dial_code + element?.user?.phone}}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let element"> {{element?.user?.email}}
            </td>
        </ng-container>

        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
            <td mat-cell *matCellDef="let element"> {{element?.user?.city?.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
            <td mat-cell *matCellDef="let element"> {{element?.user?.zip}}
            </td>
        </ng-container>

        <ng-container matColumnDef="orders">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">No Of Orders</th>
            <td mat-cell *matCellDef="let element" class="text-right"> {{element?.total_count}}
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">Total Ordered Amount</th>
            <td mat-cell *matCellDef="let element" class="text-right"> {{element?.total_amount | currency: '$'}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No Record Found</td>
        </tr>
    </table>

    <mat-paginator [pageSize]="page?.pageSize" [length]="page.length" [pageIndex]="page.pageIndex"
        [pageSizeOptions]="pageSizeOptions" (page)="customePaginator($event)" showFirstLastButtons>
    </mat-paginator>

</div>