import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
url:string;
  constructor(private http:HttpClient) {
    this.url = environment.API_URL;
   }
   
  getTransactionHisoty(data: any) {
    return this.http.get(`${this.url}/v1/orders/getTransactionHistory`, { params: data }).pipe(catchError(this.errorHandler));
  }
  
  getTransactionSubHisoty(data: any) {
    return this.http.get(`${this.url}/v1/payment/transactionSubHistory`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }
}
