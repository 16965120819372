<form name="brandForm" [formGroup]="brandForm" #formDirective="ngForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Name </mat-label>
                <input matInput required formControlName="name">
                <mat-error> Name is Required </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3 d-flex align-items-center">
            <mat-checkbox formControlName="is_active"></mat-checkbox>
            <label class="mr-3 pl-2">Is Active</label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-2 mr-5">
            <!-- <p>Orginal</p> -->
            <img class="rounded choose-img" [src]="image.src" alt="Choose file" (click)="uploadPicture()">
            <input type="file" #file accept="image/png, image/gif, image/jpeg" class="d-none"
                (change)="readFile($event)" />
        </div>
        <!-- <div class="col-sm-12 col-md-2 mr-5" *ngIf="image?.mob_picture">
            <p>Mobile</p>
            <img class="rounded choose-img" [src]="image.mob_picture" alt="Choose file">
        </div>
        <div class="col-sm-12 col-md-2" *ngIf="image?.tab_picture">
            <p>Tablet</p>
            <img class="rounded choose-img" [src]="image.tab_picture" alt="Choose file">
       </div> -->
    </div>
    <div class="row justify-content-end">
        <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
            <button mat-flat-button type="submit" class="bg-success text-white w-50">Submit </button>
        </div>
    </div>
</form>