import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-res-settings',
  templateUrl: './res-settings.component.html',
  styleUrls: ['./res-settings.component.scss']
})
export class ResSettingsComponent implements OnInit {

  image = { name: '', src: '../../../../assets/images/placeholder.jpeg', file: '' }
  resSetting: any = {
    name: '',
    address1: '',
    address2: '',
    address3: '',
    zip: '',
    alter_zip1: '',
    alter_zip2: '',
    alter_zip3: '',
    alter_zip4: '',
    alter_zip5: '',
    desc: '',
    picture: '',
    start_time: '',
    end_time: '',
    order_status: true,
    holidays: [],
    id: null,
    has_delivery:true
  };
  restaurantForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    address3: new FormControl(''),
    zip: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(0), Validators.maxLength(5)]),
    alter_zip1: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(0), Validators.maxLength(5)]),
    alter_zip2: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(0), Validators.maxLength(5)]),
    alter_zip3: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(0), Validators.maxLength(5)]),
    alter_zip4: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.minLength(0), Validators.maxLength(5)]),
    alter_zip5: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.minLength(0), Validators.maxLength(5)]),
    desc: new FormControl(''),
    order_status: new FormControl(''),
    all_time0: new FormControl(''),
    start_time0: new FormControl(''),
    end_time0: new FormControl(''),
    holiday0: new FormControl(''),
    all_time1: new FormControl(''),
    start_time1: new FormControl(''),
    end_time1: new FormControl(''),
    holiday1: new FormControl(''),
    all_time2: new FormControl(''),
    start_time2: new FormControl(''),
    end_time2: new FormControl(''),
    holiday2: new FormControl(''),
    all_time3: new FormControl(''),
    start_time3: new FormControl(''),
    end_time3: new FormControl(''),
    holiday3: new FormControl(''),
    all_time4: new FormControl(''),
    start_time4: new FormControl(''),
    end_time4: new FormControl(''),
    holiday4: new FormControl(''),
    all_time5: new FormControl(''),
    start_time5: new FormControl(''),
    end_time5: new FormControl(''),
    holiday5: new FormControl(''),
    all_time6: new FormControl(''),
    start_time6: new FormControl(''),
    end_time6: new FormControl(''),
    holiday6: new FormControl(''),
    has_delivery: new FormControl(false, [Validators.required])

  });
  displayedColumns: string[] = ['day', 'all_time', 'start_time', 'end_time', 'holiday', 'commission', 'discount', 'min_order_amount'];
  weekList: any = [
    { day: 1, title: 'MonDay', start_time: new Date(), end_time: new Date(), holiday: false, all_time: false },
    { day: 2, title: 'TuesDay', start_time: new Date(), end_time: new Date(), holiday: false, all_time: false },
    { day: 3, title: 'WedDay', start_time: new Date(), end_time: new Date(), holiday: false, all_time: false },
    { day: 4, title: 'ThursDay', start_time: new Date(), end_time: new Date(), holiday: false, all_time: false },
    { day: 5, title: 'FriDay', start_time: new Date(), end_time: new Date(), holiday: false, all_time: false },
    { day: 6, title: 'SaturDay', start_time: new Date(), end_time: new Date(), holiday: false, all_time: false },
    { day: 7, title: 'SunDay', start_time: new Date(), end_time: new Date(), holiday: true, all_time: false }
  ];
  dataSource = new MatTableDataSource(this.weekList);
  @ViewChild('file') file!: ElementRef;
  apiUrl: string = '';

  constructor(private restaurantService: RestaurantService, private appService: AppService, private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.apiUrl = environment.API_URL;
    this.getRestaurantData();
  }

  getRestaurantData() {
    this.spinner.show();
    const data = {
      store_id: this.appService.getRestaurantId()
    }
    this.restaurantService.findAllRestaurants(data).subscribe((res: any) => {
      if (res.status) {
        this.resSetting = res.data;
        // console.log(this.resSetting)
        this.resSetting.order_status = res.data.order_status ? true : false;
        if (this.resSetting?.store_time?.length) {
          this.weekList = this.resSetting.store_time;
          for (let day of this.weekList) {
            day.start_time = new Date(day.start_time);
            day.end_time = new Date(day.end_time);
            day.title = day.day == 1 ? 'Mon Day' : day.day == 2 ? 'Tues Day' : day.day == 3 ? 'Wed Day' : day.day == 4 ? 'Thurs Day' : day.day == 5 ? 'Fri Day' : day.day == 6 ? 'Satur Day' : 'Sun Day';
          }
          this.dataSource = new MatTableDataSource(this.weekList);
        }
        if(this.resSetting?.store_commission?.length) {
          this.resSetting?.store_commission?.forEach((rec, index) => {
            this.weekList[index].commission = rec.commission;
            this.weekList[index].discount = rec?.discount;
            this.weekList[index].min_order_amount = rec?.min_order_amount;
          })
        }
      }
      else this.toastr.error(res.message, 'Error')
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }

  readFile(event: any) {
    if (this.resSetting.picture)
      this.resSetting.picture = '';
    const file = event.target.files[0];
    this.image.name = file.name;
    this.image.file = file;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.src = reader.result as string;
      };
    }
  }

  onSubmit() {
    if (this.restaurantForm.invalid)
      return
    else {
      this.spinner.show();
      const formData = new FormData();
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(this.resSetting));
      formData.append('timeData', JSON.stringify(this.weekList));

      this.restaurantService.createUpdateRestaurant(formData).subscribe((res: any) => {
        if (res.status) this.toastr.success(res.message, 'Success');
        else this.toastr.error(res.message, 'Error');
        this.spinner.hide();
      },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      )
    }
  }

}
