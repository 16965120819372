import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { UserService } from '../user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public showPassword: boolean;
  lapshow: boolean;
  mobshow: boolean;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });
  newMessage: string;
  messageList: string[] = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private appService: AppService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    var w = window.innerWidth;
    var h = window.innerHeight;
    if (w >= 900) {
      console.log('tablapwidth', w, 'height', h);
      this.lapshow = true;
      this.mobshow = false;
    } else {
      console.log('mobwidth', w, 'height', h);
      this.mobshow = true;
      this.lapshow = false;
    }

    const token = this.appService.getToken();
    if (token) {
      const user = this.appService.getUserDetails();
      if (user?.role == 1 || user?.role == 2)
        this.router.navigate(['/admin/dashboard']);
      else if (user?.role == 8) {
        this.router.navigate(['/order/del-orders']);
      }
      else if (user?.role == 9) {
        this.router.navigate(['/admin/product']);


}
      else this.router.navigate(['/order/list']);
    }
  }

  saveLoginLog() {
    const data = {
      client: JSON.stringify(this.deviceService.getDeviceInfo()),
      activity: 'login',
    };
    this.spinner.show();
    this.userService.createLoginLog(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (
          this.appService.getUserRole() == 1 ||
          this.appService.getUserRole() == 2
        )
          this.router.navigate(['/admin/dashboard']);
        else if (this.appService.getUserRole() == 8) {
          this.router.navigate(['/order/del-orders']); 
        }
        else if (this.appService.getUserRole() == 9) {
          this.router.navigate(['/admin/product']); 
        }
        else this.router.navigate(['/order/list']);
        },
        (err: any) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
    );
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit(form, event) {
    if (this.loginForm.invalid) {
      return;
    } else {
      this.spinner.show();
      const data = form.value;
      data['admin'] = true;
      this.userService.login(data).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status && res.user) {
            this.appService.setUserDetails(res);
            this.saveLoginLog();
          } else this.toastr.error(res.message, 'Error');
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }
}
