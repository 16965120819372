import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  stage: number = 1;
  disableButton: boolean = false;
  mobileForm = new FormGroup({
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  otpForm = new FormGroup({
    val1: new FormControl('', [
      Validators.required,
      Validators.maxLength(1),
      Validators.pattern('^[0-9]*$'),
    ]),
    val2: new FormControl('', [
      Validators.required,
      Validators.maxLength(1),
      Validators.pattern('^[0-9]*$'),
    ]),
    val3: new FormControl('', [
      Validators.required,
      Validators.maxLength(1),
      Validators.pattern('^[0-9]*$'),
    ]),
    val4: new FormControl('', [
      Validators.required,
      Validators.maxLength(1),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    user_id: new FormControl('', [Validators.required]),
  });
  @ViewChild('otpVal1') otpVal1!: ElementRef;
  @ViewChild('otpVal2') otpVal2!: ElementRef;
  @ViewChild('otpVal3') otpVal3!: ElementRef;
  @ViewChild('otpVal4') otpVal4!: ElementRef;
  @ViewChild('otpSubmit') otpSubmit!: ElementRef;
  lapshow: boolean;
  mobshow: boolean;

  constructor(
    private userService: UserService,
    private spiner: NgxSpinnerService,
    public toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    var w = window.innerWidth;
    var h = window.innerHeight;
    if (w >= 900) {
      console.log('tablapwidth', w, 'height', h);
      this.lapshow = true;
      this.mobshow = false;
    } else {
      console.log('mobwidth', w, 'height', h);
      this.mobshow = true;
      this.lapshow = false;
    }
  }

  moveToNextField(field: number) {
    if (field === 1) this.otpVal2.nativeElement.focus();
    else if (field === 2) this.otpVal3.nativeElement.focus();
    else if (field === 3) this.otpVal4.nativeElement.focus();
    else if (field === 4) this.otpSubmit.nativeElement.focus();
  }

  sendOtp() {
    this.disableButton = true;
    this.spiner.show();
    const data = this.mobileForm.value;
    data['admin'] = true;
    this.userService.forgetPassword(data).subscribe(
      (res: any) => {
        this.spiner.hide();
        this.disableButton = false;
        if (res.status) {
          this.passwordForm.controls.user_id.patchValue(res.data.user_id);
          this.stage = 2;
          this.toastr.success(res.message, 'Success');
          setTimeout(() => {
            this.otpVal1.nativeElement.focus();
            this.otpVal1.nativeElement.style.setProperty(
              'border',
              '1px solid #ced4da'
            );
            this.otpVal2.nativeElement.style.setProperty(
              'border',
              '1px solid #ced4da'
            );
            this.otpVal3.nativeElement.style.setProperty(
              'border',
              '1px solid #ced4da'
            );
            this.otpVal4.nativeElement.style.setProperty(
              'border',
              '1px solid #ced4da'
            );
          }, 100);
        } else this.toastr.error(res.message, 'Error');
      },
      (err: any) => {
        this.spiner.hide();
        this.disableButton = false;
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  verfifyOtp() {
    this.disableButton = true;
    this.spiner.show();
    let otp = '';
    for (const control in this.otpForm.value)
      otp += this.otpForm.value[control];
    const data = { otp: otp };

    this.userService.verfifyOtp(data).subscribe(
      (res: any) => {
        this.spiner.hide();
        this.disableButton = false;
        if (res.status) {
          this.stage = 3;
          this.toastr.success(res.message, 'Success');
        } else {
          this.toastr.error(res.message, 'Error');
          this.otpForm.reset();
        }
      },
      (err: any) => {
        this.spiner.hide();
        this.disableButton = false;
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  updatePassword() {
    this.disableButton = true;
    this.spiner.show();
    this.userService.updatePassword(this.passwordForm.value).subscribe(
      (res: any) => {
        this.spiner.hide();
        this.disableButton = false;
        if (res.status) {
          this.toastr.success(res.message, 'Success');
          this.router.navigate(['/auth/login']);
        } else {
          this.toastr.error(res.message, 'Error');
          this.otpForm.reset();
        }
      },
      (err: any) => {
        this.spiner.hide();
        this.disableButton = false;
        this.toastr.error(err.message, 'Error');
      }
    );
  }
}
