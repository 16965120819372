import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  url: string = '';

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  createUpdateMenu(data: any) {
    return this.http.post(`${this.url}/v1/menus/createUpdate`, data).pipe(catchError(this.errorHandler));
  }

  findAllMenus(data: any) {
    return this.http.get(`${this.url}/v1/menus/findAll`, { params: data }).pipe(catchError(this.errorHandler));
  }

  findMenuMasterData() {
    return this.http.get(`${this.url}/v1/menus/findItemMasterData`).pipe(catchError(this.errorHandler));
  }

  createMenuMaster(data: any) {
    return this.http.post(`${this.url}/v1/menus/createMenuMaster`, data).pipe(catchError(this.errorHandler));
  }

  findMenuPriceHistory(data: any) {
    return this.http.get(`${this.url}/v1/menus/findItemPriceHistory`, { params: data }).pipe(catchError(this.errorHandler));
  }

  updateGlobalProduct(data: any) {
    return this.http.post(`${this.url}/v1/menus/updateGlobalProduct`, data).pipe(catchError(this.errorHandler));
  }

  updateGlobalProductVariant(data: any) {
    return this.http.post(`${this.url}/v1/menus/updateGlobalProductVariant`, data).pipe(catchError(this.errorHandler));
  }

  findAllVariants(data: any) {
    return this.http.get(`${this.url}/v1/menus/findAllVariants`, { params: data }).pipe(catchError(this.errorHandler));
  }

  createProductVariant(data: any) {
    return this.http.post(`${this.url}/v1/menus/createItemVariant`, data).pipe(catchError(this.errorHandler));
  }

  getReqProdByUser(data: any) {
    return this.http.get(`${this.url}/v1/menus/getReqProdByUser`, { params: data }).pipe(catchError(this.errorHandler));
  }

  addProductToStoreFromPNF(data: any) {
    return this.http.post(`${this.url}/v1/menus/addProductToStoreFromPNF`, data).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}

