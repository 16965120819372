import { Component, Input, OnInit, Output, ViewChild, EventEmitter, TemplateRef, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/admin/product/product.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {

  @Input() set setDataTableFilter(value: string) {
    this.filterRecords(value);
  }

  displayedColumns: string[] = ['sno', 'name', 'picture', 'mob_picture', 'unit', 'status'];
  menuPage = { length: 0, pageIndex: 0, pageSize: 5 };
  apiUrl: string = environment.API_URL;
  @Input() store_id;
  @Input() fromPage: string = '';
  @Input() set refreshChildMenuData(bool: boolean) {
    if (bool)
      this.getMenuList();
  }
  productList: any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();

  pageSizeOptions = [5, 10, 20];
  @Output() setMenuData = new EventEmitter();
  @Output() emitEditedProductData = new EventEmitter();
  selectedProduct: any;
  @ViewChild('menuForm') private menuForm!: TemplateRef<any>;
  @ViewChild('menuDialogclose') private menuDialogclose!: ElementRef;
  @ViewChild('productVariants') private productVariants!: TemplateRef<any>;
  @ViewChild('productVariantForm') private productVariantForm!: TemplateRef<any>;
  selectedMenuRestaurantId: number;
  selectedItemId: number;
  menuImagePath: string;
  selectedMenuName: string;
  productVariantId: number;
  @Output() listUpdated = new EventEmitter();
  posssible_variants = [];
  filters: any;
  @Input() set setFilters(filters) {
    this.filters = filters;
  }
  storeItemVariantId: number;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, private dialog: MatDialog, public appService: AppService) { }

  ngOnInit(): void {
    if (this.store_id && this.fromPage != 'product')
      this.getMenuList();
    if ((this.appService.getUserRole() != 1 && this.appService?.getUserRole() != 9) || this.fromPage === 'store_list')
      this.displayedColumns = ['sno', 'name', 'picture', 'mob_picture', 'unit', 'limit', 'quantity', 'price', 'taxable', 'status'];
    if (this.fromPage === 'product')
      this.displayedColumns.push('action')
  }

  getMenuList() {
    const data = {
      from_web: true,
      // offset: this.menuPage.pageIndex * this.menuPage.pageSize,
      // limit: this.menuPage.pageSize
    };
    if (this.store_id && this.store_id != 'null' && this.store_id != 'undefined')
      data['store_id'] = this.store_id;
    if (this.filters && this.filters.category_id)
      data['category_id'] = this.filters.category_id;
    if (this.filters && this.filters.sub_category_id)
      data['sub_category_id'] = this.filters.sub_category_id;
    if (this.filters && this.filters.brand_id)
      data['brand_id'] = this.filters.brand_id;
    this.spinner.show();
    this.productService.findAllMenus(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.productList = this.formatData(res.data);
        this.dataSource = new MatTableDataSource(this.productList);
        this.menuPage.length = res.count;
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.listUpdated.emit(res?.itemCountConfig);
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  formatData(list: any) {
    const productList = [];
    if (list?.length) {
      list?.forEach(value => {
        if (value?.item_variants?.length) {
          value?.item_variants?.forEach(item_variant => {
            let obj = {
              name: value?.name,
              desc: value?.desc,
              productPicture: value?.picture,
              picture: item_variant?.picture ? item_variant?.picture : value?.picture,
              mob_picture: item_variant?.mob_picture ? item_variant?.mob_picture : value?.mob_picture,
              posssible_variants: value?.posssible_variants?.split(','),
              unit: item_variant?.unit,
              unit_weight: item_variant?.desc,
              variant_status: item_variant?.is_active,
              item_id: value?.id,
              item_variant_id: item_variant?.id,
              category_id: value?.category_id,
              sub_category_id: value?.sub_category_id,
              brand_id: value?.brand_id
            }
            if (item_variant?.store_item_variants?.length) {
              obj['limit'] = item_variant?.store_item_variants[0].limit;
              obj['has_limit'] = item_variant?.store_item_variants[0].has_limit;
              obj['quantity'] = item_variant?.store_item_variants[0].quantity;
              obj['price'] = item_variant?.store_item_variants[0].price;
              obj['taxable'] = item_variant?.store_item_variants[0].taxable;
              obj['store_item_variant_id'] = item_variant?.store_item_variants[0].id;
              obj['variant_status'] = item_variant?.store_item_variants[0].is_active;
            }
            if (!obj?.picture)
              obj.picture = '../../../assets/images/placeholder.jpeg';
            productList?.push(obj);
          });
        }
      })
    }
    return productList;
  }

  openMenuForm(event: any) {
    this.selectedProduct = event;
    this.menuImagePath = event.picture;
    if (this.appService.getUserRole() != 1 && this.appService?.getUserRole() != 9) {
      this.selectedMenuName = event?.name;
      this.productVariantId = event.item_variant_id;
      this.selectedItemId = event.item_id;
      if (event.posssible_variants)
        this.posssible_variants = event.posssible_variants;
      this.dialog.open(this.productVariantForm, {
        width: '70vw !important',
        height: '80vh', minWidth: '70vw', maxWidth: '70vw'
      });
    }
    else if (this.fromPage === 'product') {
      this.emitEditedProductData.emit(this.selectedProduct);
    }
    else {
      this.dialog.open(this.menuForm, {
        width: '78vw !important',
        height: '78vh', minWidth: '78vw'
      });
    }
  }

  openProductVariantForm(template) {
    this.productVariantId = null
    this.dialog.closeAll();
    this.dialog.open(template, {
      width: '50vw !important',
      height: '70vh', minWidth: '50vw', maxWidth: '50vw'
    });
  }

  openVariants(productId: any, posssible_variants?: string) {
    if (Object.keys(productId).length) {
      this.selectedItemId = productId.productId;
      this.posssible_variants = productId.posssible_variants;
    }
    else {
      this.selectedItemId = productId;
      this.posssible_variants = posssible_variants ? posssible_variants.split(',') : [];
    }
    this.selectedMenuName = this.productList.find(product => product.id == productId).name;
    this.dialog.open(this.productVariants, {
      width: '85vw !important',
      height: '85vh', minWidth: '85vw'
    })
  }

  closeMenuForm(event) {
    if (event) {
      this.menuDialogclose.nativeElement.click();
      this.getMenuList();
    }
  }

  customePaginator(event) {
    this.menuPage.pageIndex = event.pageIndex;
    this.menuPage.pageSize = event.pageSize;
    this.getMenuList();
  }

  editProductVariant(productVariantId: number) {
    this.productVariantId = productVariantId;
    this.dialog.closeAll();
    this.dialog.open(this.productVariantForm, {
      width: '50vw !important',
      height: '70vh', minWidth: '50vw', maxWidth: '50vw'
    });
  }

  showPriceHistory(storeItemVariantId: number, template: any) {
    this.storeItemVariantId = storeItemVariantId;
    this.dialog.open(template, {
      width: '60vw !important',
      height: '60vh', minWidth: '60vw', maxWidth: '60vw'
    });
  }

  private filterRecords(str: string) {
    const trimmeredVal = str?.trim()?.toLowerCase();
    const filtered = [...this.productList?.filter(val => val?.name?.toLowerCase()?.includes(trimmeredVal) || val?.unit?.toLowerCase()?.includes(trimmeredVal))];
    this.dataSource = new MatTableDataSource(filtered);
    this.menuPage.length = filtered?.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
