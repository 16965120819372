import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';

@Component({
  selector: 'app-plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.scss']
})
export class PlanFormComponent implements OnInit {

  public plan: FormGroup;
  @Input() public planData: any;

  constructor(
    private spinner: NgxSpinnerService,
    private restaurantService: RestaurantService,
    private toaster: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.plan = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      posting: new FormControl(null, [Validators.required]),
      marketing: new FormControl(null, [Validators.required]),
      advertisementYes: new FormControl(false),
      advertisementNo: new FormControl(false),
      marketingYes: new FormControl(false),
      marketingNo: new FormControl(false),
      quarterly_charge: new FormControl(null, [Validators.required]),
      monthly_charge: new FormControl(null, [Validators.required]),
    });
    if (this.planData) {
      if (this.planData?.advertisement) {
        this.planData.advertisementYes = true;
      }
      else {
        this.planData.advertisementNo = true;
      }
      if (this.planData?.marketing) {
        this.planData.marketingYes = true;
      }
      else {
        this.planData.marketingNo = true;
      }

      this.plan?.patchValue(this.planData);
    }
  }

  public submit() {
    if (this.plan.invalid) {
      return
    }
    this.spinner.show();
    const data = this.plan?.value;
    data.advertisement = data?.advertisementYes ? 1 : 0;
    data.marketing = data?.marketingYes ? 1 : 0;
    if (this.planData?.id) {
      data.id = this.planData?.id;
    }
    this.restaurantService.createPlan(data).subscribe((res: any) => {
      this.spinner.hide();
      this.dialog.closeAll();
      if (res?.status) {
        this.toaster.success(res?.message, 'Success');
      }
      else {
        this.toaster.error(res?.message, 'Error');
      }
    }, (err: any) => {
      this.toaster.error(err?.message, 'Error');
    });
  }

  public updateCheckbox(event: any, control: string) {
    this.plan?.controls[control].patchValue(!event?.checked);
  }

}
