import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-notfication-settings',
  templateUrl: './notfication-settings.component.html',
  styleUrls: ['./notfication-settings.component.scss']
})
export class NotficationSettingsComponent implements OnInit {

  infoSetting: any = {
    info_email: '',
    info_mobile: ''
  };
  emailPattern = /[a-zA-Z0-9_.+-,;]+@(?:(?:[a-zA-Z0-9-]+\.,;)?[a-zA-Z]+\.,;)?(gmail)\.com/;
  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      var emails = control.value.split(',');
      const forbidden = emails.some((email: any) => Validators.email(new FormControl(email)));
      console.log(forbidden);
      return forbidden ? { 'email': { value: control.value.trim() } } : null;
    }
  };
  infoForm = new FormGroup({
    email: new FormControl('', Validators.compose([
      Validators.required, Validators.pattern(this.emailPattern), this.commaSepEmail
    ])),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(15)])
  });

  constructor(private restaurantService: RestaurantService, private toastr: ToastrService, private appService: AppService) { }

  ngOnInit(): void {
    this.getRestaurantData();
  }

  getRestaurantData() {
    const data = {
      store_id: this.appService.getRestaurantId()
    }
    this.restaurantService.findAllRestaurants(data).subscribe((res: any) => {
      if (res.status)
        this.infoSetting = res.data;
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  onSubmit() {
    if (this.infoForm.invalid)
      return
    else {
      const formData = new FormData();
      formData.append('Image', '');
      formData.append('data', JSON.stringify(this.infoSetting));

      this.restaurantService.createUpdateRestaurant(formData).subscribe((res: any) => {
        if (res.status)
          this.toastr.success(res.message, 'Success');
      },
        (err) => {
          this.toastr.error(err.message, 'Error')
        }
      )
    }
  }

}
