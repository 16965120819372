import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-ellipsis',
  templateUrl: './text-ellipsis.component.html'
})
export class TextEllipsisComponent implements OnInit {

  @Input() length: number = 15;
  showFullText: boolean = false;
  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
