import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { AppService } from 'src/app/app.service';
import { ReportService } from '../report.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-str-plan-rprt',
  templateUrl: './str-plan-rprt.component.html',
  styleUrls: ['./str-plan-rprt.component.scss']
})
export class StrPlanRprtComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;

  public tableColumns: string[] = ['sno', 'store', 'email', 'city', 'admin', 'phone', 'plan', 'start_date', 'end_date'];
  public pageSizeOptions = [5, 10, 20];
  public page = { length: 0, pageIndex: 0, pageSize: 10 };
  private dataListSubscription!: Subscription;
  public daysList: number[] = [5, 10, 15];
  public days: number = 10;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public appService: AppService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.getAboutToExpireStores();
  }

  public customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  public getAboutToExpireStores(): void {
    this.spinner.show();
    const data = {
      days: this.days
    };
    this.dataListSubscription = this.reportService.getAboutToExpireStores(data).subscribe((res: any) => {
      this.spinner.hide();
      if (!res?.status) {
        this.toastr.error(res?.message, 'Error');
      }
      const data = res?.data;
      this.dataSource = new MatTableDataSource(data);
      this.page.length = data?.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    );
  }

  ngOnDestroy(): void {
    this.dataListSubscription?.unsubscribe();
  }

}
