import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { MatTableDataSource } from '@angular/material/table';
import { ProductService } from 'src/app/admin/product/product.service';
import { environment } from 'src/environments/environment';
import { BrandService } from 'src/app/admin/product/brand.service';
import { CategoryService } from 'src/app/admin/product/category.service';
import { SubcategoryService } from 'src/app/admin/product/subcategory.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-global-product',
  templateUrl: './global-product.component.html',
  styleUrls: ['./global-product.component.scss']
})
export class GlobalProductComponent implements OnInit {

  selectedAllProduct: boolean = false;
  productPage = { length: 0, pageIndex: 0, pageSize: 5 };
  productList: any = [];
  productDataSource: any = new MatTableDataSource();
  productColumns: string[] = ['sno', 'name', 'brand', 'category', 'sub_category', 'picture', 'alert', 'variant'];
  @Output() refreshProductList = new EventEmitter();
  @Output() openVariantList = new EventEmitter();
  apiUrl: string;
  public productFilter = {
    category_id: null,
    sub_category_id: null,
    brand_id: null
  }
  public filteredCategoryDropList: any = [];
  private categoryDropList: any = [];
  public filteredSubCategoryDropList: any = [];
  private subCategoryDropList: any = [];
  public filteredBrandDropList: any = [];
  private brandDropList: any = [];
  brandDropOpened: boolean = false;
  categoryDropOpened: boolean = false;
  subCategoryDropOpened: boolean = false;
  public productCount: number = 0;
  private searchSub!: Subject<string>;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, public appService: AppService, private brandService: BrandService, private categoryService: CategoryService, private subcategoryService: SubcategoryService) { }

  ngOnInit(): void {
    this.apiUrl = environment.API_URL;
    if (this.appService.getUserRole() != 1)
      this.productColumns.unshift('selected');
    this.getProductList();
    this.getCategoryDropList();
    this.getBrandList();
    this.searchSub = new Subject();
    this.searchSub?.pipe(debounceTime(500))?.subscribe((res) => this.filterRecords(res));
  }

  getProductList() {
    this.spinner.show();
    const data = { global: true };
    if (this.productFilter?.brand_id?.length)
      data['brand_id'] = this.productFilter.brand_id;
    if (this.productFilter?.category_id?.length)
      data['category_id'] = this.productFilter.category_id;
    if (this.productFilter?.sub_category_id?.length)
      data['sub_category_id'] = this.productFilter.sub_category_id;

    this.productService.findAllMenus(data).subscribe((res: any) => {
      if (res.status) {
        this.productList = res.data;
        this.productCount = 0;
        if (this.appService.getUserRole() != 1) {
          this.productList.map(product => {
            const selected = product.store_items.find(st => st.store_id == this.appService.getRestaurantId()) ? true : false;
            product.selected = selected;
            product.itemSynced = selected;
            this.productCount++;
          })
        }
        this.productDataSource = new MatTableDataSource(this.productList);
        this.productPage.length = this.productList.length;
        this.updateAllCheckbox();
      }
      else this.toastr.error(res.message, 'Error');
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  selectAll(event) {
    this.productList.map(product => product.selected = event.checked)
  }

  updateGlobalList() {
    this.spinner.show();
    const selectedData = [];
    const removedData = [];
    this.productList.forEach(product => {
      if (product?.selected && !product?.itemSynced)
        selectedData.push({ item_id: product.id, brand_id: product.brand.id, category_id: product.category.id, sub_category_id: product.sub_category.id });
      else if (!product?.selected && product?.itemSynced)
        removedData.push(product.id);
    });
    this.productService.updateGlobalProduct({ selectedData: selectedData, removedData: removedData }).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        this.refreshProductList.emit();
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  public search(value: string, type: string) {
    if (type == 'brand') {
      this.filteredBrandDropList = this.brandDropList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
    else if (type == 'category') {
      this.filteredCategoryDropList = this.categoryDropList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
    else {
      this.filteredSubCategoryDropList = this.subCategoryDropList.filter((city) => {
        let lowerCase = city.name.toLocaleLowerCase();
        return city.name.startsWith(value) || lowerCase.startsWith(value);
      });
    }
  }

  getSubCategoryDropList() {
    const data = { dropDown: true, store_id: this.appService.getRestaurantId(), category_id: this.productFilter?.category_id };
    this.spinner.show();
    this.subcategoryService.findAllSubCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.subCategoryDropList = res.data;
        this.filteredSubCategoryDropList = [...this.subCategoryDropList];
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getCategoryDropList() {
    const data = { dropDown: true }
    this.spinner.show();
    this.categoryService.findAllCatgegories(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.categoryDropList = res.data;
        this.filteredCategoryDropList = [...this.categoryDropList]
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  getBrandList() {
    const data = {}
    this.spinner.show();
    this.brandService.findAllBrand(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.brandDropList = res.data;
        this.filteredBrandDropList = [...this.brandDropList];
      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  openedChange(value: string) {
    if (value == 'brand') {
      this.brandDropOpened = !this.brandDropOpened;
      if (!this.brandDropOpened && (this.productFilter.brand_id?.length || this.productFilter.category_id?.length || this.productFilter.sub_category_id?.length))
        this.getProductList();
    }
    else if (value == 'category') {
      this.categoryDropOpened = !this.categoryDropOpened;
      if (!this.categoryDropOpened && this.productFilter.category_id?.length) {
        this.getSubCategoryDropList();
        if (this.productFilter.brand_id?.length || this.productFilter.category_id?.length || this.productFilter.sub_category_id?.length)
          this.getProductList();
      }
    }
    else {
      this.subCategoryDropOpened = !this.subCategoryDropOpened;
      if (!this.subCategoryDropOpened && this.productFilter.sub_category_id?.length && (this.productFilter.brand_id?.length || this.productFilter.category_id?.length || this.productFilter.sub_category_id?.length))
        this.getProductList();
    }
  }

  public applyFilter(str: string): void {
    this.searchSub.next(str);
  }

  public updateAllCheckbox(): void {
    this.selectedAllProduct = this.productList.every(product => product.selected === true);

  }

  private filterRecords(str: string) {
    const trimmeredVal = str?.trim()?.toLowerCase();
    const filtered = [...this.productList?.filter(val => val?.name?.toLowerCase()?.includes(trimmeredVal) || val?.brand?.name?.toLowerCase()?.includes(trimmeredVal) || val?.category?.name?.toLowerCase()?.includes(trimmeredVal) || val?.sub_category?.name?.toLowerCase()?.includes(trimmeredVal))];
    this.productDataSource = new MatTableDataSource(filtered);
    this.productPage.length = filtered?.length;
  }

}
