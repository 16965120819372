import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HelpDeskService } from '../help-desk.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-help-desk',
  templateUrl: './help-desk.component.html',
  styleUrls: ['./help-desk.component.scss']
})
export class HelpDeskComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['sno', 'issue', 'desc', 'priority', 'restaurant', 'user', 'picture', 'created_at', 'status', 'action'];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  helpList: any = [];
  userRole: number = 0;

  constructor(private helpDeskService: HelpDeskService, private router: Router) { }

  ngOnInit(): void {
    const user: any = JSON.parse(localStorage.getItem('user'));
    this.userRole = user.role;
    this.getHelpList();
  }

  getHelpList() {
    this.helpList = this.helpDeskService.getHelpList();
    this.dataSource = new MatTableDataSource(this.helpList);
    this.page.length = this.helpList.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }

  editHelp(helpId: number) {
    this.helpDeskService.setHelpId(helpId);
    this.router.navigate(['/admin/helpdesk-form']);
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

}
