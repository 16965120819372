<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>

<div class="container-fluid bg-white">
    <form name="restaurantForm" [formGroup]="restaurantForm" (ngSubmit)="onSubmit()">
        <div class="row mb-2">
            <div class="col-4">
                <h5>Take Online Orders </h5>
            </div>
            <div class="col-8">
                <mat-slide-toggle [(checked)]="resSetting.order_status"
                    (change)="resSetting.order_status = !resSetting.order_status">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name </mat-label>
                    <input matInput [(ngModel)]="resSetting.name" required formControlName="name">
                    <mat-error> Name is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-8">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Description </mat-label>
                    <input matInput [(ngModel)]="resSetting.desc" required formControlName="desc">
                    <mat-error> Description is Required </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Address Line 1</mat-label>
                    <input matInput [(ngModel)]="resSetting.address1" required formControlName="address1">
                    <mat-error> Address 1 is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Address Line 2</mat-label>
                    <input matInput [(ngModel)]="resSetting.address2" required formControlName="address2">
                    <mat-error> Address 2 is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Address Line 3</mat-label>
                    <input matInput [(ngModel)]="resSetting.address3" formControlName="address3">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Zip Code </mat-label>
                    <input matInput [(ngModel)]="resSetting.zip" type="number" required formControlName="zip">
                    <mat-error> Zip Code is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Alter Zip 1 </mat-label>
                    <input matInput [(ngModel)]="resSetting.alter_zip1" type="number" required
                        formControlName="alter_zip1">
                    <mat-error> Alter Zip 1 is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Alter Zip 2 </mat-label>
                    <input matInput [(ngModel)]="resSetting.alter_zip2" type="number" required
                        formControlName="alter_zip2">
                    <mat-error> Alter Zip 2 is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Alter Zip 3 </mat-label>
                    <input matInput [(ngModel)]="resSetting.alter_zip3" type="number" required
                        formControlName="alter_zip3">
                    <mat-error> Alter Zip 3 is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Alter Zip 4 </mat-label>
                    <input matInput [(ngModel)]="resSetting.alter_zip4" type="number" formControlName="alter_zip4">
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Alter Zip 5 </mat-label>
                    <input matInput [(ngModel)]="resSetting.alter_zip5" type="number" formControlName="alter_zip5">
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-3 d-flex align-items-center">
                <mat-checkbox [(ngModel)]="resSetting.has_delivery" formControlName="has_delivery">Has Delivery</mat-checkbox>
              </div>
            <div class="col-sm-12 col-md-2">
                <img class="choose-img rounded" [src]="image?.src" (click)="uploadPicture()">
                <input type="file" name="file" #file accept="image/png, image/jpg, image/jpeg" class="d-none"
                    (change)="readFile($event)">
            </div>
            <!-- <div class="col-sm-12 col-md-4" *ngIf="(image && image.src) || resSetting.picture">
                <img [src]="(image && image.src) ? image.src : (apiUrl+'/'+resSetting.picture)" [alt]="image.name"
                    class="w-50 h50">
                <p>{{image && image.name ? image.name : ''}}</p>
            </div> -->
            <!-- <div class="col-sm-12 col-md-4">
                <button mat-flat-button color="primary" type="button" (click)="uploadPicture()">Upload Store
                    Picture</button>
                <input type="file" #file accept="image/png, image/gif, image/jpeg" class="d-none"
                    (change)="readFile($event)">
            </div> -->
        </div>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100 my-4">
            <ng-container matColumnDef="day">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Day</th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <ng-container matColumnDef="all_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>24 Hours</th>
                <td mat-cell *matCellDef="let element; let i=index">
                    <mat-checkbox [name]="'all_time'+i" ngDefaultControl [formControlName]="'all_time'+i"
                        [(ngModel)]="element.all_time"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="start_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Opening Time</th>
                <td mat-cell *matCellDef="let element; let i=index">
                    <input [name]="'start_time'+i" ngDefaultControl mode="12h" [formControlName]="'start_time'+i"
                        matTimepicker [(ngModel)]="element.start_time">
                </td>
            </ng-container>

            <ng-container matColumnDef="end_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Closing Time</th>
                <td mat-cell *matCellDef="let element; let i=index">
                    <input [name]="'end_time'+i" ngDefaultControl mode="12h" [formControlName]="'end_time'+i"
                        matTimepicker [(ngModel)]="element.end_time">
                </td>
            </ng-container>

            <ng-container matColumnDef="holiday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Holidays</th>
                <td mat-cell *matCellDef="let element; let i=index">
                    <mat-checkbox [name]="'holiday'+i" ngDefaultControl [formControlName]="'holiday'+i"
                        [(ngModel)]="element.holiday"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="commission">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Commission</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.commission | currency: '$' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.discount | currency: '$' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="min_order_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MinOrder Amount</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.min_order_amount | currency: '$' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching </td>
            </tr>
        </table>

        <div class="d-flex align-items-center justify-content-end">
            <button mat-flat-button type="submit" class="bg-success text-white w-25">Update </button>
        </div>
    </form>

</div>