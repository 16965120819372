import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/admin/product/product.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-req-prod-by-user',
  templateUrl: './req-prod-by-user.component.html',
  styleUrls: ['./req-prod-by-user.component.scss']
})
export class ReqProdByUserComponent implements OnInit {

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  public dataSource: any = new MatTableDataSource();
  public tableColumns: string[] = ['sno', 'product', 'picture', 'brand', 'category', 'sub_category', 'user', 'phone', 'action'];
  public pageSizeOptions = [5, 10, 20];
  public page = { length: 0, pageIndex: 0, pageSize: 5 };
  public apiUrl!: string;
  public productId!: number;
  public productName!: string;
  public possibleVariants!: any;
  public productVariantId!: number;

  @ViewChild('productVariants') public productVariants: TemplateRef<any>;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.apiUrl = 'http://admin.order2pay.net:3000';
    this.getReqProdByUser();
  }

  private getReqProdByUser(): void {
    this.spinner.show();
    this.productService.getReqProdByUser({}).subscribe((res: any) => {
      if (!res?.status) {
        this.toastr.error(res?.message, 'Error');
      }
      const data = res?.data || []
      this.dataSource = new MatTableDataSource(data);
      this.page.length = data?.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  public addProductToStore(item_id: number, item_name: string, posssible_variants: string): void {
    this.spinner.show();
    this.productId = item_id;
    this.productName = item_name;
    this.possibleVariants = posssible_variants?.split(',');
    this.productService.addProductToStoreFromPNF({ item_id: item_id }).subscribe((res: any) => {
      if (!res?.status) {
        this.toastr.error(res?.message, 'Error');
      }
      else {
        this.toastr.success(res?.message, 'Success');
        this.openProductVariantList();
      }
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    );
  }

  public openProductVariantList(): void {
    this.dialog.open(this.productVariants, {
      width: '60vw !important',
      height: '75vh', minWidth: '60vw'
    })
  }

  public customePaginator(event: any): void {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  public closeVariantDialog(): void {
    this.dialog.closeAll();
    this.getReqProdByUser();
  }

  public openProductVariantForm(tempalte: TemplateRef<any>, productVariantId: number): void {
    if (productVariantId) {
      this.productVariantId = productVariantId;
    }
    this.dialog.closeAll();
    this.dialog.open(tempalte, {
      width: '60vw !important',
      height: '75vh', minWidth: '60vw'
    })
  }

  public closeProductVariantForm() {
    this.dialog.closeAll();
    this.openProductVariantList();
  }



}
