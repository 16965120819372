import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as $ from 'jquery';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'order2Pay';
  isLogin: string;
  showProfile: boolean = false;
  isMenuOpen: boolean = false;
  isMobMenuActive = false;

  constructor(private router: Router, private appService: AppService) { }

  ngOnInit() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isLogin = localStorage.getItem('token');
        if (this.isLogin)
          this.showProfile = true;
        else
          this.showProfile = false;
      }
    });
  }

  onMenuTogglerChange(isMenuOpenStatus: boolean): void {
    this.isMenuOpen = isMenuOpenStatus ? false : true;
  }

  // sidebar menu change event function
  onMobMenuChange(isMobMenuOpenStatus: boolean): void {
    this.isMobMenuActive = isMobMenuOpenStatus ? false : true;
  }

  // close sidebar mobile menu layer
  closeMobMenu(): void {
    this.isMobMenuActive = !this.isMobMenuActive;
    this.isMenuOpen = false;
  }

  createRoles() {
    this.appService.createRoles().subscribe((res: any) => {
      console.log('res', res)
    }, (err: any) => {
      console.log('err', err)
    })
  }

}
