<br><br>
<div class="footer">
    <div class="container d-flex justify-content-around text-white p-2">
        <div>
            &#169;All Rights Reserved.
        </div>
        <div>
            <!-- Powered by <a href="">.</a> -->
        </div>
    </div>
</div>