import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/auth/user/user.service';
import { DeliveryPersonService } from 'src/app/settings/delivery-person-list/delivery-person.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() isMenuOpen!: boolean;
  @Output() mobMenuTogglerEvent = new EventEmitter();
  notifcationCount: number = 10;
  userImage: string = '';
  user: any;
  resName: string = '';
  public delStatus!: number;
  public delStatusObj;

  constructor(private router: Router,
    private appService: AppService, public toastr: ToastrService, private dialog: MatDialog,
    private deviceService: DeviceDetectorService, private userService: UserService, private spinner: NgxSpinnerService,
    private deliveryPersonService: DeliveryPersonService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.user = this.appService.getUserDetails();
    if (this.user.role == 8) {
      this.resName = `${this.user?.user_role?.name} - ${this.user?.name} - ${this.user?.state?.name}  - ${this.user?.city?.name}`;
      this.getDeliveryPersonStatus();
    }
    else if (this.user.role !== 1 && this.user.role !== 9) {
      this.resName = `${this.user?.store?.name} - ${this.user?.user_role?.name} - ${this.user?.name}`;
    }
    else
      this.resName = `Order 2 Pay - ${this.user.user_role.name} - ${this.user?.name}`;
  }

  mobMenuToggler(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.mobMenuTogglerEvent.emit(this.isMenuOpen);
  }

  logout() {
    const data = {
      client: JSON.stringify(this.deviceService.getDeviceInfo()),
      activity: 'logout'
    }
    this.spinner.show();
    this.userService.createLoginLog(data).subscribe((res: any) => {
      this.spinner.hide();
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      this.router.navigate(['/login']);
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      })
  }

  private getDeliveryPersonStatus(): void {
    this.spinner.show();
    this.deliveryPersonService.getDeliveryPersonStatus({}).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        if (res?.status) {
          this.delStatusObj = res?.data;
          this.delStatus = res?.delPersonStatus?.toString();
          this.cd.detectChanges();
        }
      },
      error: (err) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Success')
      }
    })
  }

  public updateStatus(status: number): void {
    const data = {
      status
    }
    this.spinner.show();
    this.deliveryPersonService.updateDeliveryPersonStatus(data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        if (res?.status) {
          this.deliveryPersonService.triggerUpdateList();
          this.toastr.success(res?.message, 'Success');
        }
      },
      error: (err) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Success')
      }
    })
  }

}
