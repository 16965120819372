<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
  <p style="font-size: 20px; color: #050a4f">{{ spinnerText }}</p>
</ngx-spinner>

<div class="container-fluid">
  <div class="my-2 d-flex align-items-center justify-content-between">
    <h2>Mass Communication Mail List</h2>
    <button class="bg-primary text-white" (click)="openEmailModal('')" mat-flat-button>
      Send New Mail
    </button>
  </div>
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ page.pageIndex * page.pageSize + i + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="restaurant">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
      <td mat-cell *matCellDef="let element">
        <span title="{{element?.store?.name}}" *ngIf="element?.store?.name?.length > 15">
          {{ element?.store?.name ? element?.store?.name?.substr(0,15) : 'All Stores' }} ...
        </span>
        <span title="{{element?.store?.name}}" *ngIf="element?.store?.name?.length <= 15">
          {{ element?.store?.name ? element?.store?.name : 'All Stores' }}
        </span>
        <!-- {{ element?.restaurant_name ? element?.restaurant_name : "" }} -->
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.role?.name ? element?.role?.name : "All Users" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{
          'text-success': element.status,
          'text-danger': !element.status
        }">
        {{ element?.status ? "Send" : "Failed" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="recipients">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Recipients</th>
      <td mat-cell *matCellDef="let element">
        <span title="{{element?.recipients}}" *ngIf="element?.recipients?.length > 15">
          {{ element?.recipients.substr(0,15) }} ...
        </span>
        <span title="{{element?.recipients}}" *ngIf="element?.recipients?.length <= 15">
          {{ element?.recipients }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
      <td mat-cell *matCellDef="let element">
        <span title="{{element?.subject}}" *ngIf="element?.subject?.length > 15">
          {{ element?.subject.substr(0,15) }} ...
        </span>
        <span title="{{element?.subject}}" *ngIf="element?.subject?.length <= 15">
          {{ element?.subject }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="content">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Content</th>
      <td mat-cell *matCellDef="let element">
        <span title="{{element?.content}}" *ngIf="element?.content?.length > 15">
          {{ element?.content.substr(0,15) }} ...
        </span>
        <span title="{{element?.content}}" *ngIf="element?.content?.length <= 15">
          {{ element?.content }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date: "dd-MM-yyyy hh:mm a" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
      <td mat-cell *matCellDef="let element; index as i" class="text-center">
        <button mat-flat-button color="primary" (click)="openEmailModal(element)">
          <i class="fa fa-eye"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{
        language && language.hasOwnProperty("No data matching")
        ? language["No data matching"]
        : "No data matching"
        }}
      </td>
    </tr>
  </table>

  <mat-paginator [length]="page.length" [pageIndex]="page.pageIndex" [pageSizeOptions]="pageSizeOptions"
    (page)="customePaginator($event)" showFirstLastButtons>
  </mat-paginator>
</div>

<ng-template #sendMailModal>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h3 class="text-color">Send Mass Email</h3>
    <button mat-dialog-close mat-flat-button>Close</button>
  </div>
  <form name="emailForm" #formDirective="ngForm" [formGroup]="emailForm" (ngSubmit)="onSubmit(formDirective)">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Store </mat-label>
          <mat-select [disabled]="viewMode" required formControlName="store">
            <mat-select-filter [placeholder]="'Search Restaurant'" [displayMember]="'name'" [array]="restaurantList"
              (filteredReturn)="filteredRestaurantList = $event"></mat-select-filter>
            <mat-option *ngFor="let rest of filteredRestaurantList" [value]="rest.id">
              {{ rest.name }}
            </mat-option>
          </mat-select>
          <mat-error> Restaurant is Required </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Role </mat-label>
          <mat-select [disabled]="viewMode" required formControlName="role">
            <mat-option *ngFor="let role of roleList" [value]="role.id">
              {{ role.name }}
            </mat-option>
          </mat-select>
          <mat-error> Role is Required </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Subject </mat-label>
          <input [readonly]="viewMode" required matInput type="text" maxlength="255" formControlName="subject" />
          <mat-error> Subject is Required </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Message Body </mat-label>
          <textarea [readonly]="viewMode" required matInput type="text" maxlength="1000" rows="6"
            formControlName="content"></textarea>
          <mat-error> Message Body is Required </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="w-100 text-center">
      <a [href]="fileSrc" target="_blank" download *ngIf="viewMode && fileSrc">{{ this.fileName }}</a>
      <button *ngIf="!viewMode" mat-flat-button color="primary" class="w-100" type="button"
        (click)="uploadAttachment()">
        Upload Attachment
      </button>
      <span *ngIf="!viewMode">{{ fileName }}</span>
      <input type="file" name="file" #file class="d-none" (change)="readFile($event)" />
    </div>
    <div class="d-flex float-right mt-3">
      <button mat-dialog-close mat-flat-button>Close</button>
      <button *ngIf="!viewMode" type="submit" mat-flat-button
        class="bg-success text-white float-right mx-3">
        Send Email
      </button>
    </div>
  </form>
</ng-template>
