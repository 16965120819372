import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductComponent } from './product/product.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { MenuModule } from 'src/app/shared/menu-list/menu.module';
import { MenuFormModule } from 'src/app/shared/menu-form/menu-form.module';
import { GlobalCategoryModule } from 'src/app/shared/global-category/global-category.module';
import { GlobalSubCategoryModule } from 'src/app/shared/global-sub-category/global-sub-category.module';
import { GlobalProductModule } from 'src/app/shared/global-product/global-product.module';
import { ProductVariantListModule } from 'src/app/shared/product-variant-list/product-variant-list.module';
import { BrandListModule } from 'src/app/shared/brand-list/brand-list.module';
import { BrandFormModule } from 'src/app/shared/brand-form/brand-form.module';
import { ProductVariantFormModule } from 'src/app/shared/product-variant-form/product-variant-form.module';


@NgModule({
  declarations: [ProductComponent, ProductFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    MenuModule,
    MenuFormModule,
    GlobalCategoryModule,
    GlobalSubCategoryModule,
    GlobalProductModule,
    ProductVariantListModule,
    BrandListModule,
    BrandFormModule,
    ProductVariantFormModule
  ],
  exports: [ProductComponent, ProductFormComponent]
})
export class ProductModule { }
