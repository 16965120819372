import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatbot-form',
  templateUrl: './chatbot-form.component.html',
  styleUrls: ['./chatbot-form.component.scss']
})
export class ChatbotFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
