import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from './payment.service';
import { RestaurantService } from '../restaurant/restaurant.service';
import { AppService } from 'src/app/app.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  paymentBatchDataColumns: string[] = ['sno', 'commission', 'amount', 'payment_method', 'from', 'to', 'created_at', 'action'];
  orderDataColumns: string[] = ['sno', 'order_id', 'order_total', 'commission', 'total_without_commission', 'created_at'];
  paymentTypeList: string[] = [
    'UPI',
    'Bank Transaction',
    'Cash'
  ];
  orderDataSource: any = new MatTableDataSource();
  paymentDataSource: any = new MatTableDataSource();
  payment = {
    store_id: '',
    type: '',
    from: new Date(),
    to: new Date()
  };
  public batchFilter = {
    store_id: '',
    type: '',
    from: new Date(),
    to: new Date()
  };
  ordersList!: any;
  storeList!: any[];
  filteredStoreList!: any[];
  public commissionTotal = 0;
  paymentBatchList!: any[];
  public page = { length: 0, pageIndex: 0, pageSize: 10 };
  public isView: boolean = false;
  public paymentBatchInfo!: any;

  constructor(private spinner: NgxSpinnerService, private dialog: MatDialog, private restaurantService: RestaurantService, private toastr: ToastrService, private paymentService: PaymentService, public appService: AppService) { }

  ngOnInit(): void {
    this.payment.from = moment().subtract(14, 'days').toDate();
    this.batchFilter.from = moment().subtract(14, 'days').toDate();
    this.getPaymentBatch();
    this.getDropdownStores();
    if (this.appService.getUserRole() == 1)
      this.paymentBatchDataColumns.splice(1, 0, 'store');
  }

  getDropdownStores() {
    this.spinner.show();
    this.restaurantService.getDropdownStores({}).subscribe((res: any) => {
      if (res?.status) {
        this.storeList = res?.data;
        this.filteredStoreList = [...this.storeList];
      }
      else
        this.toastr.error(res?.message, 'Error');
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  search(filter: string) {
    this.filteredStoreList = this.storeList.filter((store) => {
      let lowerCase = store.name.toLocaleLowerCase();
      return store.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  getPaymentBatch() {
    this.spinner.show();
    const data = {
      from: this.batchFilter.from,
      to: this.batchFilter.to,
      offset: this.page.pageIndex * this.page.pageSize,
      limit: this.page.pageSize
    };
    if (this.batchFilter.store_id)
      data['store_id'] = this.batchFilter.store_id;
    this.paymentService.getPaymentBatch(data).subscribe((res: any) => {
      if (res.status) {
        this.paymentBatchList = res?.data;
        this.paymentDataSource = new MatTableDataSource(this.paymentBatchList);
        this.page.length = res?.ordersCount;
        this.paymentDataSource.sort = this.sort;
        this.paymentDataSource.paginator = this.paginator;
      }
      else
        this.toastr.error(res?.message, 'Error');
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  getPaymentBatchInfo(template: any, batch_id: number) {
    this.isView = true;
    this.spinner.show();
    const data = { batch_id: batch_id };
    this.paymentService.getPaymentBatch(data).subscribe((res: any) => {
      if (res.status) {
        this.dialog.open(template, {
          width: '80vw !important',
          height: '80vh', minWidth: '80vw'
        });
        this.paymentBatchInfo = res?.data;
        this.payment.store_id = res?.data?.store?.id;
        this.payment.type = res?.data?.type;
        this.payment.from = new Date(res?.data?.from);
        this.payment.to = new Date(res?.data?.to);
        this.commissionTotal = res?.data?.commission;
        this.orderDataSource = new MatTableDataSource(res?.data?.orders);
      }
      else
        this.toastr.error(res?.message, 'Error');
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  public customePaginator(event: any): void {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
    this.getPaymentBatch();
  }


  getPendingPayments() {
    this.spinner.show();
    const data = {
      store_id: this.payment.store_id,
      from: this.payment.from,
      to: this.payment.to
    }
    this.paymentService.getPendingPayments(data).subscribe((res: any) => {
      if (res.status) {
        this.commissionTotal = 0;
        this.ordersList = res.data;
        this.ordersList?.forEach(order => this.commissionTotal += order.commission);
        this.orderDataSource = new MatTableDataSource(this.ordersList);
      }
      else
        this.toastr.error(res?.message, 'Error');
      this.spinner.hide();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  openPaymentModal(template: any) {
    this.isView = false;
    this.payment = {
      store_id: '',
      type: '',
      from: moment().subtract(6, 'days').toDate(),
      to: new Date()
    };
    this.ordersList = [];
    this.orderDataSource = new MatTableDataSource();
    this.dialog.open(template, {
      width: '80vw !important',
      height: '80vh', minWidth: '80vw'
    });
  }

  public generatePaymentBatch(): void {
    this.spinner.show();
    const data: any = {
      type: this.payment.type,
      order_ids: [],
      commission: this.commissionTotal,
      amount: 0,
      store_id: this.payment.store_id,
      from: this.payment.from,
      to: this.payment.to
    };
    this.ordersList.forEach(order => {
      data.amount += (order.order_total - order.commission);
      data.order_ids.push(order.id);
    });

    this.paymentService.generatePaymentBatch(data).subscribe((res: any) => {
      if (res.status)
        this.toastr.success(res?.message, 'Success');
      else
        this.toastr.error(res?.message, 'Error');
      this.spinner.hide();
      this.dialog.closeAll();
      this.getPaymentBatch();
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    );

  }

}
