<div class="container-fluid bg-white">
    <form name="ProductForm" [formGroup]="ProductForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name </mat-label>
                    <input matInput [(ngModel)]="Product.name" minlength="1" maxlength="50" required
                        formControlName="name" [readonly]="!Product.store_id">
                    <mat-error> Name is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-8">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Description </mat-label>
                    <input matInput [(ngModel)]="Product.desc" minlength="1" maxlength="200" formControlName="desc"
                        [readonly]="!Product.store_id">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Product Code </mat-label>
                    <input matInput [(ngModel)]="Product.code" minlength="1" maxlength="6" required
                        [readonly]="!Product.store_id" formControlName="code">
                    <mat-error>Product Code is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Category </mat-label>
                    <mat-select [(ngModel)]="Product.category_id" required formControlName="category"
                        (selectionChange)="Product.category_id && getSubCategoryList()"
                        [disabled]="!Product.store_id">
                        <input (keyup)="search($event.target.value, 'category')" class="form-control w-100"
                            placeholder="Search Category">
                        <mat-option *ngFor="let category of filterCategoryList" [value]="category.id">{{category.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error> Category is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Sub Category </mat-label>
                    <mat-select [(ngModel)]="Product.sub_category_id" required formControlName="sub_category"
                        [disabled]="!Product.store_id">
                        <input (keyup)="search($event.target.value, 'subcategory')" class="form-control w-100"
                            placeholder="Search Sub Category">
                        <mat-option *ngFor="let subCategory of filterSubCategoryList" [value]="subCategory.id">
                            {{subCategory.name}}</mat-option>
                    </mat-select>
                    <mat-error> Sub Category is Required </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-4" *ngIf="userRole != 1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Product Price </mat-label>
                    <input matInput type="number" [(ngModel)]="Product.price" min="0" formControlName="price">
                    <mat-error>Product Price is Required </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-2 d-flex align-items-center">
                <label class="mr-3">Is Active</label>
                <mat-checkbox [(ngModel)]="Product.is_active" formControlName="is_active" [disabled]="!Product.store_id"></mat-checkbox>
            </div>
            <div class="col-sm-12 col-md-4">
                <button mat-flat-button color="primary" type="button" (click)="uploadPicture()"
                    [disabled]="!Product.store_id">Upload Product
                    Picture</button>
                <input type="file" #file accept="image/png, image/gif, image/jpeg" class="d-none"
                    (change)="readFile($event)" multiple>
            </div>
            <div class="w-50" *ngIf="Product && Product.picture">
                <img [src]="apiUrl+'/'+Product.picture" [alt]="Product.picture_name">
                <p class="mt-3">{{Product.picture_name}}</p>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
                <button mat-flat-button type="submit" class="bg-success text-white w-50">Submit </button>
            </div>
        </div>
    </form>
</div>