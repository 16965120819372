<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
    <p style="font-size: 20px; color: #050a4f"></p>
</ngx-spinner>
<div class="container-fluid p-0">
    <div class="d-flex justify-content-end my-3" *ngIf="appService.getUserRole() == 1">
        <button [disabled]="!storeId" class="btn btn-primary"
            (click)="openPlanActivationModal(planActivateModal)">Upgrade / Renew Plan</button>
    </div>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
            <td mat-cell *matCellDef="let element; let i = index">
                {{ page.pageIndex * page.pageSize + i + 1 }}
            </td>
        </ng-container>

        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element?.plan?.name }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="posting">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Allowed Product</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element?.posting }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="start_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
            <td mat-cell *matCellDef="let element">{{ element?.start_date | date: 'dd MMM, yyyy hh:mm a' }}</td>
        </ng-container>

        <ng-container matColumnDef="end_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let element">{{ element?.end_date | date: 'dd MMM, yyyy hh:mm a' }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchased Amount</th>
            <td mat-cell *matCellDef="let element">{{ element?.amount | currency: '$' }}</td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element"
                [ngClass]="{'text-success': element?.active, 'text-danger': !element?.active}">{{ element?.active ?
                'Active' : 'Expired' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colspan]="displayedColumns?.length">No data matching</td>
        </tr>
    </table>
    <mat-paginator [length]="page.length" [pageIndex]="page.pageIndex" [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons>
    </mat-paginator>
</div>

<ng-template #planActivateModal>
    <div class="d-flex justify-content-between mb-4">
        <h3 class="m-0">Upgrade / Renew Plan</h3>
        <button class="btn bg-danger br-50" mat-dialog-close>
            <i class="fa fa-times text-white" aria-hidden="true"></i>
        </button>
    </div>
    <app-plan-activation [storeId]="storeId" [latestPostCount]="latestPostCount"></app-plan-activation>
</ng-template>