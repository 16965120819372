import { Component, Input, OnInit, Output, ViewChild, EventEmitter, TemplateRef, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from 'src/app/admin/product/product.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-menu-price-history',
  templateUrl: './menu-price-history.component.html',
  styleUrls: ['./menu-price-history.component.scss']
})
export class MenuPriceHistoryComponent implements OnInit {

  displayedColumns: string[] = ['sno', 'menu', 'unit', 'price', 'updated_price', 'updated_at', 'user'];
  menuPriceHistoryPage = { length: 0, pageIndex: 0, pageSize: 10 };
  apiUrl: string = environment.API_URL;
  @Input() storeItemVariantId;
  menuPriceHistoryList: any = [];
  dataSource: any = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  pageSizeOptions = [5, 10, 20];

  menuImagePath: string;

  constructor(private spinner: NgxSpinnerService, private productService: ProductService, private toastr: ToastrService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getMenuPriceHistoryList();
  }

  getMenuPriceHistoryList(): void {
    const data = { store_item_variant_id: this.storeItemVariantId };
    this.spinner.show();
    this.productService.findMenuPriceHistory(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.menuPriceHistoryList = res.data;
        this.dataSource = new MatTableDataSource(this.menuPriceHistoryList);
        this.menuPriceHistoryPage.length = this.menuPriceHistoryList.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

      }
      else this.toastr.error(res.message, 'Error')
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      }
    )
  }

  customePaginator(event, type: string): void {
    this.menuPriceHistoryPage.pageIndex = event.pageIndex;
    this.menuPriceHistoryPage.pageSize = event.pageSize;
  }

}
