import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextEllipsisModule } from '../text-ellipsis/text-ellipsis.module';
import { GlobalCategoryComponent } from './global-category.component';


@NgModule({
    declarations: [GlobalCategoryComponent],
    imports: [
        CommonModule,
        SharedModule,
        TextEllipsisModule
    ],
    exports: [GlobalCategoryComponent]
})
export class GlobalCategoryModule { }
