import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  url: string = '';
  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  fetchDashboardData(data: any) {
    return this.http.get(`${this.url}/v1/dashboard/fetchDashboardData`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }
}
