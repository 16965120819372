<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>

<div class="dialog-container">

    <div class="d-flex align-items-center justify-content-between">
        <h2 mat-dialog-title class="text-bold">Order Details </h2>
        <h2 mat-dialog-title class="text-bold">Order Id - {{order?.order_id}}</h2>
    </div>
    <div class="progress-bar mb-4">
        <div class="progress-step" [ngClass]="{'is-active' : order?.status == 1}">
            <div class="step-count" [ngClass]="{'primary-color':order?.status >= 0}">1</div>
            <div class="step-description">Queue</div>
        </div>
        <div class="progress-step" [ngClass]="{'is-active' : order?.status == 2}">
            <div class="step-count" [ngClass]="{'primary-color':order?.status > 1, 'bg-danger': !order?.status}">2</div>
            <div class="step-description">{{ order?.status == 0 ? 'Cancelled' : 'Confirmed' }} </div>
        </div>
        <div class="progress-step" [ngClass]="{'is-active' : order?.status == 3}">
            <div class="step-count" [ngClass]="{'primary-color':order?.status > 2}">3</div>
            <div class="step-description">In Processing</div>
        </div>
        <div class="progress-step" [ngClass]="{'is-active' : order?.status == 4}">
            <div class="step-count" [ngClass]="{'primary-color':order?.status > 3}">4</div>
            <div class="step-description">Ready For Delivery</div>
        </div>
        <div class="progress-step" [ngClass]="{'is-active' : order?.status == 5}">
            <div class="step-count" [ngClass]="{'primary-color':order?.status > 4}">5</div>
            <div class="step-description">Delivered</div>
        </div>
        <div class="progress-step" [ngClass]="{'is-active' : order?.status == 6 }">
            <div class="step-count" [ngClass]="{'primary-color': order?.status == 6 }">6</div>
            <div class="step-description">Reviewed</div>
        </div>
        <div class="progress-step" [ngClass]="{'is-active' : order?.status == 7 }">
            <div class="step-count" [ngClass]="{'primary-color': order?.status == 7 }">7</div>
            <div class="step-description">Settled</div>
        </div>
    </div>

    <table class="table">
        <tbody>
            <tr>
                <td class="text-bold">User Name</td>
                <td class="text-left">: {{ order?.user.name }}</td>
                <td class="text-bold">Mobile</td>
                <td class="text-left">: +{{ order?.user?.dial_code + order?.user?.phone }}</td>
                <td class="text-bold">Zip</td>
                <td class="text-left">: {{ order?.user?.zip }}</td>
                <td class="text-bold">City</td>
                <td class="text-left">: {{ order?.user?.city?.name }}</td>
            </tr>
            <tr>
                <td class="text-bold">Commission</td>
                <td class="text-left text-success">: {{ order?.commission | currency : '$' }}</td>
                <td class="text-bold">Address</td>
                <td class="text-left" colspan="5">: {{ order?.user?.address }}</td>
            </tr>
            <tr *ngIf="order?.is_delivery && order?.delivery_info" class="del-info">
                <td class="text-bold">Delivery Info</td>
                <td colspan="6">: {{ order?.delivery_info?.address1 + ', ' + order?.delivery_info?.address2 + ', ' +
                    order?.delivery_info?.landmark + ', ' + order?.delivery_info?.city?.name + ', ' +
                    order?.delivery_info?.zipcode + '.'}}</td>
                <td>
                    <mat-form-field appearance="outline" class="w-100 ml-2"
                        *ngIf="order?.is_delivery && order?.status >= 4">
                        <mat-label>Delivery Person </mat-label>
                        <mat-select [disabled]="order?.status != 4 || userRole<=8" [(ngModel)]="delPerson"
                            (selectionChange)="assignDeliveryPerson()">
                            <mat-option disabled selected hidden>Select</mat-option>
                            <mat-option *ngFor="let delPerson of deliveryPersons" [value]="delPerson?.id">
                                {{delPerson?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </tbody>
    </table>

    <mat-dialog-content class="mat-typography">
        <table class="table table-responsive order-table m-0">
            <thead>
                <tr>
                    <!-- <th></th> -->
                    <th class="text-center w-5">Image</th>
                    <th class="text-center w-20">Name</th>
                    <th class="text-center w-8">Unit</th>
                    <th class="text-center w-10">Brand</th>
                    <th class="text-center w-10">Category</th>
                    <th class="text-center w-10">Sub Category</th>
                    <th class="text-center w-10">Qty</th>
                    <th class="text-center w-13">Price</th>
                    <th class="text-center w-14">Total</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let order_item of order?.order_items; let i=index">
                    <tr *ngIf="!order_item?.deleted">
                        <td *ngIf="!order_item?.isTitle && !canEditOrder">
                            <i *ngIf="!order_item?.editOrgOrder && order?.status === 1" class="fa fa-pencil cursor-pointer p-2"
                                (click)="openConfirmationDialog()" aria-hidden="true"></i>
                            <i *ngIf="order_item?.editOrgOrder" class="fa fa-minus cursor-pointer p-2 text-danger"
                                (click)="removeOrderItem(i)" aria-hidden="true"></i>
                        </td>
                        <td *ngIf="order_item?.isTitle" class="w-100 text-bold text-success" colspan="9">{{
                            order_item?.vendor }}</td>
                        <td *ngIf="order_item?.isTitle" class="w-50" colspan="4"
                            [ngClass]="{'text-danger': order_item?.status == 0 || order_item?.status == 1, 'text-success': order_item?.status != 1 && order_item?.status != 0}">
                            {{ order_item?.statusText }} </td>
                        <td *ngIf="!order_item?.isTitle" class="w-5">
                            <img [src]="apiUrl+'/'+(order_item?.store_item_variant?.item_variant?.picture ? order_item?.store_item_variant?.item_variant?.picture : order_item?.store_item_variant?.item_variant?.item?.picture)"
                                *ngIf="order_item?.store_item_variant?.item_variant?.picture || order_item?.store_item_variant?.item_variant?.item?.picture"
                                else defaultImg>
                            <ng-template #defaultImg>
                                <img src="../../../assets/images/placeholder.jpeg">
                            </ng-template>
                        </td>

                        <td *ngIf="!order_item?.isTitle" class="w-20">{{
                            order_item?.store_item_variant?.item_variant?.item?.name }}</td>
                        <td *ngIf="!order_item?.isTitle" class="w-8">{{
                            order_item?.store_item_variant?.item_variant?.unit +
                            '(' +
                            order_item?.store_item_variant?.item_variant?.desc + ')'}}</td>
                        <td *ngIf="!order_item?.isTitle" class="w-10">{{
                            order_item?.store_item_variant?.item_variant?.item?.brand?.name }}</td>
                        <td *ngIf="!order_item?.isTitle" class="w-10">{{
                            order_item?.store_item_variant?.item_variant?.item?.category?.name }}</td>
                        <td *ngIf="!order_item?.isTitle" class="w-10">{{
                            order_item?.store_item_variant?.item_variant?.item?.sub_category?.name }}</td>
                        <td *ngIf="!order_item?.isTitle" class="text-right w-10">
                            <span *ngIf="orderDetail.status == 1 && !orderDetail.view_only && canEditOrder" matRipple>
                                <svg width="20" height="24" viewBox="0 0 20 24" fill="none"
                                    (click)="changeOrderDetails(i, false)">
                                    <path
                                        d="M14.776,10c0,0.239-0.195,0.434-0.435,0.434H5.658c-0.239,0-0.434-0.195-0.434-0.434s0.195-0.434,0.434-0.434h8.684C14.581,9.566,14.776,9.762,14.776,10 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.691-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.382,10c0-4.071-3.312-7.381-7.382-7.381C5.929,2.619,2.619,5.93,2.619,10c0,4.07,3.311,7.382,7.381,7.382C14.07,17.383,17.382,14.07,17.382,10"
                                        fill="#dc3545" />
                                </svg>
                            </span>
                            <span class="mx-2 w-10">{{ order_item?.qty }}</span>
                            <span *ngIf="orderDetail.status == 1 && !orderDetail.view_only && canEditOrder" matRipple>
                                <svg width="20" height="24" viewBox="0 0 20 24" fill="none"
                                    (click)="changeOrderDetails(i, true)">
                                    <path
                                        d="M14.613,10c0,0.23-0.188,0.419-0.419,0.419H10.42v3.774c0,0.23-0.189,0.42-0.42,0.42s-0.419-0.189-0.419-0.42v-3.774H5.806c-0.23,0-0.419-0.189-0.419-0.419s0.189-0.419,0.419-0.419h3.775V5.806c0-0.23,0.189-0.419,0.419-0.419s0.42,0.189,0.42,0.419v3.775h3.774C14.425,9.581,14.613,9.77,14.613,10 M17.969,10c0,4.401-3.567,7.969-7.969,7.969c-4.402,0-7.969-3.567-7.969-7.969c0-4.402,3.567-7.969,7.969-7.969C14.401,2.031,17.969,5.598,17.969,10 M17.13,10c0-3.932-3.198-7.13-7.13-7.13S2.87,6.068,2.87,10c0,3.933,3.198,7.13,7.13,7.13S17.13,13.933,17.13,10"
                                        fill="#28a745" />
                                </svg>
                            </span>
                        </td>
                        <td *ngIf="!order_item?.isTitle" class="text-right w-13">{{ order_item?.price | currency: '$' }}
                        </td>

                        <td *ngIf="!order_item?.isTitle" class="text-right w-14">
                            <span class="mx-2"> {{ order_item?.price * order_item?.qty | currency: '$' }} </span>
                            <span *ngIf="orderDetail.status == 1 && !orderDetail.view_only && canEditOrder" matRipple>
                                <svg width="20" height="24" viewBox="0 0 20 24" fill="none"
                                    (click)="removeOrderItem(i)">
                                    <path
                                        d="M14.776,10c0,0.239-0.195,0.434-0.435,0.434H5.658c-0.239,0-0.434-0.195-0.434-0.434s0.195-0.434,0.434-0.434h8.684C14.581,9.566,14.776,9.762,14.776,10 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.691-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.382,10c0-4.071-3.312-7.381-7.382-7.381C5.929,2.619,2.619,5.93,2.619,10c0,4.07,3.311,7.382,7.381,7.382C14.07,17.383,17.382,14.07,17.382,10"
                                        fill="#dc3545" />
                                </svg>
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <h3 class="text-bold m-0">Discount</h3>
                    </td>
                    <td class="text-right">
                        <h3 class="text-bold m-0 text-success">- {{ order?.discount | currency: '$' }}</h3>
                    </td>
                    <td>
                        <h3 class="text-bold m-0">Tax</h3>
                    </td>
                    <td class="text-right">
                        <h3 class="text-bold m-0 text-danger">+ {{ order?.tax | currency: '$' }}</h3>
                    </td>
                    <td *ngIf="order?.delivery_charge">
                        <h3 class="text-bold m-0">Delivery charge</h3>
                    </td>
                    <td class="text-right" *ngIf="order?.delivery_charge">
                        <h3 class="text-bold m-0 text-danger">+ {{ order?.delivery_charge | currency: '$' }}</h3>
                    </td>
                    <td>
                        <h3 class="text-bold m-0">Total Amount</h3>
                    </td>
                    <td class="text-right">
                        <h3 class="text-bold m-0">{{ order?.order_total | currency: '$' }}</h3>
                    </td>
                </tr>
                <!-- <tr>
                    <td colspan="5" class="text-right w-90">
                        <h3 class="text-bold m-0">Discount</h3>
                    </td>
                    <td class="text-right mx-3">
                        <h3 class="text-bold m-0 text-success">- {{ orderDetail.discount | currency: '$' }}</h3>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="text-right w-90">
                        <h3 class="text-bold m-0">Tax</h3>
                    </td>
                    <td class="text-right mx-3">
                        <h3 class="text-bold m-0 text-danger">+ {{ orderDetail.tax | currency: '$' }}</h3>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="text-right w-90">
                        <h3 class="text-bold m-0">Total Amount</h3>
                    </td>
                    <td class="text-right mx-3">
                        <h3 class="text-bold m-0">{{ orderDetail.order_total | currency: '$' }}</h3>
                    </td>
                </tr> -->
            </tfoot>
        </table>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex align-items-center justify-content-between">
        <button mat-button class="bg-secondary text-white" (click)="openLogModal()">Order
            Log</button>
            <button mat-button class="bg-secondary text-white" (click)="openMapModal()">Direction Map</button>
        <button mat-button class="bg-primary text-white" (click)="printOrder()">Print</button>

        <button mat-button (click)="canelOrder()" class="bg-danger text-white"
            *ngIf="orderDetail.status == 1 && userRole != 4">Cancel Order</button>
        <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
                <button mat-button mat-dialog-close class="bg-warning text-white">Close</button>
                <button mat-button class="bg-success text-white" cdkFocusInitial (click)="confirmOrder()"
                    *ngIf="!orderDetail.view_only && orderDetail.status && orderDetail.status !== 5 && userRole != 4 && !order?.is_delivery">{{canEditOrder
                    ?
                    'Update Order' : orderDetail.status == 1 ?
                    'Confirm' : orderDetail.status == 2 ? 'In Processing' : orderDetail.status == 3 ? 'Ready For
                    Delivery'
                    : orderDetail.status == 4 && !order?.is_delivery ? 'Ready For Pickup' : 'Delivered'}}</button>
                <button mat-button class="bg-success text-white" cdkFocusInitial (click)="confirmOrder()"
                    *ngIf="orderDetail.status !== 5 && (order?.is_delivery && orderDetail.status != 4) || (order?.is_delivery && orderDetail?.status === 4 && delPerson)">{{canEditOrder
                    ?
                    'Update Order' : orderDetail.status == 1 ?
                    'Confirm' : orderDetail.status == 2 ? 'In Processing' : orderDetail.status == 3 ? 'Ready For
                    Delivery'
                    : orderDetail.status == 4 && !order?.is_delivery ? 'Ready For Pickup' : 'Delivered'}}</button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
<ng-template #logModal>
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="text-color">Order Log</h3>
        <button mat-flat-button (click)="closeLogModal()">Close</button>
    </div>
    <div class="order-log-container">
        <table class="table-striped table table-hover" *ngIf="order && order.hasOrderLog">
            <tbody>
                <tr>
                    <td class="text-bold">Order ID</td>
                    <td class="text-left">: {{ order.order_id }}</td>
                    <td class="text-bold">Ordered Time</td>
                    <td class="text-left">: {{ order.created_at | date: 'dd-MM-yyyy hh:mm a' }}</td>
                </tr>
                <tr>
                    <td class="text-bold">Ordered Price Amount</td>
                    <td class="text-left">: {{ order.ordered_price_amount | currency :'$' }}</td>
                    <td class="text-bold">Changed Price Amount</td>
                    <td class="text-left">: {{ order.order_total | currency :'$' }}</td>
                </tr>
                <tr>
                    <td class="text-bold">Updated By</td>
                    <td class="text-left">: {{ order.user ? order.user.name : ''}}</td>
                    <td class="text-bold">Updated At</td>
                    <td class="text-left">: {{ order.updated_at | date: 'dd-MM-yyyy hh:mm a' }}</td>
                </tr>
            </tbody>
        </table>

        <!-- <table class="table-striped table table-hover mt-4">
            <thead class="thead-dark">
                <tr>
                    <th>Image</th>
                    <th>Menu</th>
                    <th>Price</th>
                    <th class="text-right">Ordered Qty</th>
                    <th class="text-center">Changed Qty</th>
                    <th class="text-right">Ordered Qty Amount</th>
                    <th class="text-right">Changed Qty Amount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order_item of order.order_items; let i=index">
                    <td>
                        <img [src]="apiUrl+'/'+order_item.menu.picture" [alt]="order_item.picture"
                            *ngIf="order_item.menu.picture">
                    </td>
                    <td>{{ order_item.menu.name }}</td>
                    <td>{{ order_item.price }}</td>
                    <td class="text-right">{{ order_item.orderLog ? order_item.orderLog.from_qty : order_item.qty }}
                    </td>
                    <td class="text-right">{{ order_item.orderLog ? order_item.orderLog.to_qty : order_item.qty }}</td>
                    <td class="text-right">{{ (order_item.orderLog ? order_item.orderLog.from_qty * order_item.price :
                        order_item.price * order_item.qty) | currency: '$' }}
                    </td>
                    <td class="text-right">{{ (order_item.orderLog ? order_item.price * order_item.orderLog.to_qty :
                        order_item.price * order_item.qty) | currency: '$' }}</td>
                </tr>
            </tbody>
        </table> -->

        <table class="table-striped table table-hover mt-4" *ngIf="orderStatusLogs?.length">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Order Status</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Updated By</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let status_log of orderStatusLogs">
                    <tr *ngIf="status_log?.storeHeader">
                        <td colspan="3" class="text-primary store-title">{{
                            status_log?.storeName }}</td>
                    </tr>
                    <tr *ngIf="!status_log?.storeHeader">
                        <ng-container *ngIf="!status_log?.changedLog">
                            <td
                                [ngClass]="{'text-success': status_log?.status != 0, 'text-danger': !status_log?.status}">
                                {{
                                !status_log?.status ? 'Cancelled' : status_log?.status == 1 ? 'Queue' :
                                status_log?.status
                                == 2
                                ?
                                'Confirmed' : status_log?.status
                                == 3 ?
                                'In Processing'
                                : status_log?.status == 4 ? 'Ready For Pickup' : status_log?.status == 5 ? 'Delivered' :
                                status_log?.status == 6 ? 'Reviewed' : status_log?.status == 7 ? 'Payment Settled' :
                                'Delivery Person assigned'}}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="status_log?.changedLog">
                            <td>{{ status_log?.message}}</td>
                        </ng-container>
                        <td>{{status_log?.created_at | date: 'dd-MM-yyyy hh:mm a'}}
                        </td>
                        <td>{{ status_log?.user?.user_role?.name + ' - ' +
                            status_log?.user?.name }} </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>
<ng-template #mapModal>
    <!-- <h3 class="text-color">Order </h3> -->
    <button mat-flat-button (click)="closeLogModal()">Close</button>
<!-- </div> -->
    <div id="map" ></div>
</ng-template>
<div class="d-none" id="printSection">
    <table style="width: 100%;">
        <tbody>
            <tr>
                <td style="font-weight: bold; font-size: 16px;"> Order Details </td>
                <td style="text-align: right; font-weight: bold;  font-size: 16px;"> Order Id - {{order?.order_id}}
                </td>
            </tr>
        </tbody>
    </table>
    <table style="width: 100%; margin: 1rem 0;">
        <tbody>
            <tr>
                <td style="font-weight: bold; font-size: 16px;">User Name</td>
                <td style="font-weight: bold; font-size: 14px;">: {{ order?.user?.name }}</td>
                <td style="font-weight: bold; font-size: 16px;">Mobile</td>
                <td style="font-weight: bold; font-size: 14px;">: +{{ order?.user?.dial_code + order?.user?.phone }}
                </td>
                <td style="font-weight: bold; font-size: 16px;">Zip</td>
                <td style="font-weight: bold; font-size: 14px;">: {{ order?.user?.zip }}</td>
                <td style="font-weight: bold; font-size: 16px;">City</td>
                <td style="font-weight: bold; font-size: 14px;">: {{ order?.user?.city?.name }}</td>
            </tr>
            <tr>
                <td style="font-weight: bold; font-size: 16px;">Commission</td>
                <td style="font-weight: bold; font-size: 14px;">: {{ order?.commission | currency : '$' }}</td>
                <td style="font-weight: bold; font-size: 16px;">Address</td>
                <td colspan="5" style="font-weight: bold; font-size: 14px;">: {{ order?.user?.address }}</td>
            </tr>
            <tr>
                <td style="font-weight: bold; font-size: 16px;">Store Name</td>
                <td style="font-weight: bold; font-size: 14px;">: {{
                    order?.store_name }}</td>
                <td style="font-weight: bold; font-size: 16px;">Store Phone</td>
                <td style="font-weight: bold; font-size: 14px;">: {{ order?.store_phone }}</td>
                <td style="font-weight: bold; font-size: 16px;">Status</td>
                <td style="font-weight: bold; font-size: 14px;">
                    : {{ order?.statusText }} </td>
            </tr>
        </tbody>
    </table>
    <table style="width: 100%;">
        <thead>
            <tr>
                <th></th>
                <th style="text-align: left; font-size: 16px;">Name</th>
                <th style="text-align: left; font-size: 16px;">Unit</th>
                <th style="text-align: left; font-size: 16px;">Brand</th>
                <th style="text-align: right; font-size: 16px;">Qty</th>
                <th style="text-align: right; font-size: 16px;">Price</th>
                <th style="text-align: right; font-size: 16px;">Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let order_item of order?.order_items; let i=index"
                [ngStyle]="{ 'margin': order_item?.isTitle ? '2rem 0' : '' }">
                <td style="font-weight: bold; font-size: 14px;" *ngIf="!order_item?.isTitle">{{ order_item?.index }}
                </td>
                <td style="font-weight: bold; font-size: 14px;" *ngIf="!order_item?.isTitle">{{
                    order_item?.store_item_variant?.item_variant?.item?.name }}</td>
                <td style="font-weight: bold; font-size: 14px;" *ngIf="!order_item?.isTitle">{{
                    order_item?.store_item_variant?.item_variant?.desc + ' ' +
                    order_item?.store_item_variant?.item_variant?.unit_short_term }}</td>
                <td style="font-weight: bold; font-size: 14px;" *ngIf="!order_item?.isTitle">{{
                    order_item?.store_item_variant?.item_variant?.item?.brand?.name }}</td>
                <td *ngIf="!order_item?.isTitle" style="text-align: right; font-weight: bold; font-size: 14px;">
                    <span>{{ order_item?.qty }}</span>
                </td>
                <td *ngIf="!order_item?.isTitle" style="text-align: right; font-weight: bold; font-size: 14px;">{{
                    order_item?.price | currency: '$' }}
                </td>
                <td *ngIf="!order_item?.isTitle" style="text-align: right; font-weight: bold; font-size: 14px;">
                    <span> {{ order_item?.price * order_item?.qty | currency: '$' }} </span>
                </td>
            </tr>
        </tbody>
    </table>
    <table style="width: 100%; margin-top: 1rem;">
        <tbody>
            <tr>
                <td style="font-weight: bold; font-size: 16px">Total Items: {{ order?.total_items }}</td>
                <td colspan="6" style="text-align: right; font-weight: bold; font-size: 16px;">
                    Discount
                </td>
                <td colspan="2" style="text-align: right; font-weight: bold; font-size: 14px;">
                    - {{ orderDetail?.discount | currency: '$' }} </td>
            </tr>
            <tr>
                <td colspan="7" style="text-align: right; font-weight: bold; font-size: 14px;">Tax </td>
                <td colspan="2" style="text-align: right; font-weight: bold; font-size: 14px;">+ {{ orderDetail?.tax |
                    currency: '$' }}
                </td>
            </tr>
            <tr>
                <td colspan="7" style="text-align: right; font-weight: bold; font-size: 14px;">
                    Total Amount
                </td>
                <td colspan="2" style="text-align: right; font-weight: bold; font-size: 14px;">
                    {{ order?.order_total | currency: '$' }}
                </td>
            </tr>
        </tbody>
    </table>
</div>

