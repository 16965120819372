import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderRoutingModule } from './order-routing.module';
import { OrderComponent } from './order/order.component';
import { SharedModule } from '../shared/shared.module';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { LogOnClick } from './directive.component';
import { TextEllipsisModule } from '../shared/text-ellipsis/text-ellipsis.module';
import { DelOrdersComponent } from './del-orders/del-orders.component';
import { DirectionsComponent } from './directions/directions.component';


@NgModule({
  declarations: [OrderComponent, OrderDetailComponent, LogOnClick, DelOrdersComponent, DirectionsComponent],
  imports: [
    CommonModule,
    OrderRoutingModule,
    SharedModule,
    TextEllipsisModule
  ]
})
export class OrderModule { }
