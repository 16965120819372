import { Directive } from '@angular/core';

@Directive({
    selector: "[logOnClick]",
    host: {
        'click': 'onClick()'
    }
})
export class LogOnClick {
    constructor() { }

    onClick() {
        console.log('Clicked');

    }
}