import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportRoutingModule } from './report-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PriceReportComponent } from './price-report/price-report.component';
import { UserReportComponent } from './user-report/user-report.component';
import { StrPlanRprtComponent } from './str-plan-rprt/str-plan-rprt.component';



@NgModule({
  declarations: [PriceReportComponent, UserReportComponent, StrPlanRprtComponent],
  imports: [
    CommonModule,
    ReportRoutingModule,
    SharedModule
  ]
})
export class ReportModule { }
