<div class="row mx-0 my-3">
  <div class="col-md-12">
    <form name="ProductForm" [formGroup]="ProductForm" #menuFormDirective="ngForm"
      (ngSubmit)="onSubmit(menuFormDirective)">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name </mat-label>
            <input [readonly]="disalbeForm" matInput minlength="1" maxlength="50" required formControlName="name" />
            <mat-error> Name is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-8">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Description </mat-label>
            <input [readonly]="disalbeForm" matInput minlength="1" maxlength="200" formControlName="desc" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Product Code </mat-label>
            <input [readonly]="disalbeForm"
              matInput
              minlength="1"
              maxlength="6"
              required
              [readonly]="Product.id && !Product.store_id && userRole != 1"
              formControlName="code"
            />
            <mat-error>Product Code is Required </mat-error>
          </mat-form-field>
        </div> -->
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Brand </mat-label>
            <mat-select [disabled]="disalbeForm" required formControlName="brand_id">
              <input [readonly]="disalbeForm" (keyup)="search($event.target.value, 'brand')" class="form-control w-100"
                placeholder="Search Brand" />
              <mat-option *ngFor="let brand of filteredBrandList" [value]="brand.id">
                {{ brand.name }}
              </mat-option>
            </mat-select>
            <mat-error> Brand is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Category </mat-label>
            <mat-select [disabled]="disalbeForm" required formControlName="category_id" (selectionChange)="
              ProductForm?.value?.category_id && getSubCategoryDropList()
              ">
              <input [readonly]="disalbeForm" (keyup)="search($event.target.value, 'category')" class="form-control w-100"
                placeholder="Search Category" />
              <mat-option *ngFor="let category of filteredCategoryDropList" [value]="category.id">
                {{ category.name }}
              </mat-option>
            </mat-select>
            <mat-error> Category is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Sub Category </mat-label>
            <mat-select [disabled]="disalbeForm" required formControlName="sub_category_id">
              <input [readonly]="disalbeForm" (keyup)="search($event.target.value, 'subcategory')" class="form-control w-100"
                placeholder="Search Sub Category" />
              <mat-option *ngFor="let subCategory of filteredSubCategoryDropList" [value]="subCategory.id">
                {{ subCategory.name }}</mat-option>
            </mat-select>
            <mat-error> Sub Category is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Possible Variants </mat-label>
            <mat-select [disabled]="disalbeForm" multiple required formControlName="posssible_variants">
              <input [readonly]="disalbeForm" (keyup)="search($event.target.value, 'posssible_variants')" class="form-control w-100"
                placeholder="Search Variant">
              <mat-option *ngFor="let unit of filteredUnitsList" [value]="unit?.value">{{unit?.value + ' (' + unit?.key
                + ')'}}
              </mat-option>
            </mat-select>
            <mat-error> Variant is Required </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 d-flex align-items-center">
          <mat-checkbox formControlName="is_active"></mat-checkbox>
          <label class="mr-3 pl-2">Is Active</label>
        </div>
      </div>
      <!-- <div class="row my-2">
        <div class="col-sm-12 col-md-2 d-flex align-items-center">
          <mat-checkbox
            [(ngModel)]="Product.vegetarian"
            formControlName="vegetarian"
          >
          </mat-checkbox>
          <label class="mr-3 pl-2">Is Vegetarian</label>
        </div>
        <div class="col-sm-12 col-md-2 d-flex align-items-center">
          <mat-checkbox
            [(ngModel)]="Product.taxable"
            formControlName="taxable"
          >
          </mat-checkbox>
          <label class="mr-3 pl-2">Is Taxable</label>
        </div>
        <div class="col-sm-12 col-md-2 d-flex align-items-center">
          <mat-checkbox
            (change)="updateValidation()"
            [(ngModel)]="Product.has_limit"
            formControlName="has_limit"
          >
          </mat-checkbox>
          <label class="mr-3 pl-2">Has Limit</label>
        </div>
        
        <div class="col-sm-12 col-md-4" *ngIf="userRole != 1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Product Price </mat-label>
            <input [readonly]="disalbeForm"
              matInput
              type="number"
              [(ngModel)]="Product.price"
              min="0"
              formControlName="price"
            />
            <mat-error>Product Price is Required </mat-error>
          </mat-form-field>
        </div>
      </div> -->

      <div class="row">
        <!-- <div class="col-sm-12 col-md-3" *ngIf="Product.has_limit">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Daily Limit </mat-label>
            <input [readonly]="disalbeForm"
              matInput
              type="number"
              [(ngModel)]="Product.limit"
              min="0"
              formControlName="limit"
            />
            <mat-error>Daily Limit is Required </mat-error>
          </mat-form-field>
        </div> -->
        <!-- <div class="mx-3 image" *ngIf="image && image.src">
          <img class="w-100 h-100 border-radius-15px" [src]="image.src" [alt]="image.name" />
          <p class="mt-3">{{ image.name }}</p>
        </div> -->
        <div class="col-sm-12 col-md-2">
          <img class="rounded choose-img" [src]="image.src" alt="Choose file" (click)="uploadPicture()">
          <input [readonly]="disalbeForm" type="file" #file accept="image/png, image/gif, image/jpeg" class="d-none" (change)="readFile($event)"
            multiple />
        </div>
      </div>
      <div class="row justify-content-end" *ngIf="!disalbeForm">
        <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
          <button mat-flat-button type="submit" class="bg-success text-white w-50">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>