import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {

  subCategory: any = null;
  url: string = '';

  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  setSubCategory(category: number) {
    this.subCategory = category;
  }

  getSubCategory() {
    return this.subCategory;
  }

  createUpdateSubCategory(data: any) {
    return this.http.post(`${this.url}/v1/sub_categories/createUpdate`, data).pipe(catchError(this.errorHandler));
  }

  findAllSubCatgegories(data: any) {
    return this.http.get(`${this.url}/v1/sub_categories/findAll`, { params: data }).pipe(catchError(this.errorHandler));
  }

  updateGlobalSubCategory(data: any) {
    return this.http.post(`${this.url}/v1/sub_categories/updateGlobalSubCategory`, data).pipe(catchError(this.errorHandler));
  }

  findSubCategoryWithTargetStore(data: any) {
    return this.http.get(`${this.url}/v1/sub_categories/findSubCategoryWithTargetStore`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}

