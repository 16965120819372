import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment.prod';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
    selector: 'confirmation-dialog',
    styleUrls: ['./confirmation-dialog.component.scss'],
    templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationComponent {

    message: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) public msg: string) {
        this.message = msg;
    }

}