import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { AppService } from 'src/app/app.service';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss']
})
export class UserReportComponent implements OnInit {

  public storeList!: any[];
  public filteredStoreList!: any[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  public totalAmount!: boolean; 
  public totalCount!: boolean; 

  public tableColumns: string[] = ['sno', 'user', 'phone', 'email', 'city', 'zip', 'orders', 'amount'];
  public pageSizeOptions = [5, 10, 20];
  public page = { length: 0, pageIndex: 0, pageSize: 10 };
  public apiUrl!: string;
  public storeId!: number;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public appService: AppService,
    private restaurantService: RestaurantService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.totalAmount = true;
    this.getDropdownStores();
    this.getUserReport();
  }

  private getDropdownStores(): void {
    this.spinner.show();
    this.restaurantService.getDropdownStores({}).subscribe((res: any) => {
      this.spinner.hide();
      if (res?.status) {
        this.storeList = res?.data;
        this.filteredStoreList = [...this.storeList];
      }
      else
        this.toastr.error(res?.message, 'Error');
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  public search(filter: string) {
    this.filteredStoreList = this.storeList.filter((store) => {
      let lowerCase = store.name.toLocaleLowerCase();
      return store.name.startsWith(filter) || lowerCase.startsWith(filter);
    });
  }

  public customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  public getUserReport(): void {
    this.spinner.show();
    const data: any = {
      order: this.totalAmount ? 'total_amount' : 'total_count'
    };
    if (this.storeId) {
      data.store_id = this.storeId;
    }
    this.reportService.getTopUsers(data).subscribe((res: any) => {
      this.spinner.hide();
      if (!res?.status) {
        this.toastr.error(res?.message, 'Error');
      }
      const data = res?.data;
      this.dataSource = new MatTableDataSource(data);
      this.page.length = data?.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    )
  }

  public updateCheckbox(): void {
    this.totalAmount = !this.totalAmount;
    this.totalCount = !this.totalCount;
    this.getUserReport();
  }

}
