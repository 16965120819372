import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpDeskService {

  helpId: number = 0;
  issueList: any = [
    { id: 1, value: 'Payment Issue' },
    { id: 2, value: 'Application Issue' },
    { id: 3, value: "Order Coudn't Update" },
    { id: 4, value: 'Login Issue' },
    { id: 5, value: 'Menu Price Issue' },
    { id: 6, value: 'Others' }
  ];
  priorityList: any = [
    { id: 1, value: 'Normal' },
    { id: 2, value: 'Urgent' },
    { id: 3, value: 'Emergency' },
    { id: 4, value: 'Critical' }
  ];

  constructor() { }

  getIssueList() {
    return this.issueList;
  }

  getPriorityList() {
    return this.priorityList;
  }

  setHelpId(helpId: number) {
    this.helpId = helpId;
  }

  getHelpId() {
    return this.helpId;
  }

  getHelpById(helpId: number) {
    let helpList = JSON.parse(localStorage.getItem('helpList')) || [];
    const help = helpList.find((hlp: any) => hlp.id == helpId);
    return help;
  }

  createHelp(help: any) {
    let helpList = JSON.parse(localStorage.getItem('helpList')) || [];
    help.id = helpList && helpList.length ? helpList[helpList.length - 1].id + 1 : 1;
    helpList.push(help);
    localStorage.setItem('helpList', JSON.stringify(helpList));
  }

  updateHelp(help: any, helpId: number) {
    let helpList = JSON.parse(localStorage.getItem('helpList')) || [];
    const helpIndex = helpList.findIndex((hlp: any) => hlp.id == helpId);
    helpList[helpIndex] = help;
    localStorage.setItem('helpList', JSON.stringify(helpList));
    this.helpId = null;
  }

  getHelpList() {
    const user: any = JSON.parse(localStorage.getItem('user'));
    let helpList = JSON.parse(localStorage.getItem('helpList')) || [];
    if (user.store_id)
      helpList = helpList.filter((hlp: any) => hlp.store_id == user.store_id);
    for (let help of helpList) {
      const issue = this.issueList.find((isue: any) => isue.id == help.issue);
      help.issue_name = issue.value;
      const priority = this.priorityList.find((prty: any) => prty.id == help.priority);
      help.priority_name = priority.value;
      const userList = JSON.parse(localStorage.getItem('usersList'));
      const user = userList.find((usr: any) => usr.id == help.created_by);
      help.created_by_user = user.role == 2 ? 'Admin' : user.name;
      if (user.store_id) {
        const restaurantList = JSON.parse(localStorage.getItem('restaurantList'));
        const restaurant = restaurantList.find((res: any) => res.id == help.store_id);
        help.restaurant = restaurant.name;
      }
    }
    return helpList;
  }

}
