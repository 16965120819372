import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextEllipsisModule } from '../text-ellipsis/text-ellipsis.module';
import { BrandFormComponent } from './brand-form.component';


@NgModule({
    declarations: [BrandFormComponent],
    imports: [
        CommonModule,
        SharedModule,
        TextEllipsisModule
    ],
    exports: [BrandFormComponent]
})
export class BrandFormModule { }
