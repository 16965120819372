
<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<div class="container-fluid bg-white">
    <form name="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <h2>User </h2>
        <div class="position-relative">
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Name </mat-label>
                        <input matInput [(ngModel)]="user.name" required formControlName="name">
                        <mat-error> Name is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Role </mat-label>
                        <mat-select  [(ngModel)]="user.role" formControlName="role">
                            <mat-option value=""> Select Role </mat-option>
                            <mat-option *ngFor="let role of roleList" [value]="role.id"> {{role.title}} </mat-option>
                        </mat-select>
                        <mat-error> Role is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email </mat-label>
                        <input [readonly]="user?.id" matInput type="email" [(ngModel)]="user.email" required formControlName="email">
                        <mat-error> Email is Required </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <ngx-intl-tel-input class="form-control h-52px mt-1 d-flex align-items-center" matInput [cssClass]="'phoneNumberClass'"
                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]" [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [phoneValidation]="true"
                    [separateDialCode]="false" [numberFormat]="PhoneNumberFormat.National" name="phoneno" [ngModelOptions]="{standalone: true}" [(ngModel)]="phoneobj">
                    </ngx-intl-tel-input>     
                    <p class="text-danger" *ngIf="userForm?.controls?.phone?.touched && userForm?.controls?.phone?.errors">Enter a valid phone number</p>   
                    <!-- <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Phone Number </mat-label>
                        <input [readonly]="user?.id" matInput type="number" [(ngModel)]="user.phone" required formControlName="phone">
                        <mat-error> Phone Number is Required </mat-error>
                    </mat-form-field> -->
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="w-100 cursor-pointer" (click)="uploadPicture()">
                        <img class="upload-style" [src]="image.src" [alt]="image.src">
                    </div>
                    <!-- <button mat-flat-button color="primary" type="button" (click)="uploadPicture()">Upload
                        Profile
                        Picture</button> -->
                    <input type="file" #file accept="image/png, image/gif, image/jpeg" class="d-none" (change)="readFile($event)">
                </div>
                <!-- <div class="col-sm-12 col-md-4" *ngIf="user.picture || (image && image.src)">
                    <img [src]="image && image.src ? image.src : (apiUrl+'/'+user.picture)" [alt]="user.picture_name" class="w-50 h-50">
                    <p>{{user.picture_name}}</p>
                </div> -->
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
                <button mat-flat-button type="submit" class="bg-success text-white w-50">Submit </button>
            </div>
        </div>
    </form>
</div>