<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>
<img
  *ngIf="lapshow"
  class="background-img"
  src="../../../assets/images/login-bg.jpg"
/>
<img
  *ngIf="mobshow"
  class="background-img"
  src="../../../assets/images/mobile.jpg"
/>
<!-- <img class="background-img" src="../../../assets/images/e-commerce-bg.png" /> -->
<div class="row">
  <div class="col-sm-10 col-md-3 card-wrapper">
    <h3 class="call-to-action">Admin Login</h3>

    <form
      class="w-75 d-flex align-items-center"
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit(loginForm, $event)"
    >
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          placeholder="Enter email"
        />
        <mat-error> Enter Valid Email </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100 position-relative">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          placeholder="Enter password"
        />
        <i
          class="fa fa-eye p-2 passIcon"
          (click)="showPassword = !showPassword"
        ></i>
        <mat-error> Enter Password </mat-error>
      </mat-form-field>
      <button
        style="
          background-color: #3b8884;
          border: none;
          padding: 15px;
          font-weight: bold;
          color: white;
        "
        class="w-100"
        type="submit"
      >
        Login
      </button>
      <a routerLink="/auth/forgot-password" class="text-blue mt-3"
        >Forgot password?</a
      >
    </form>
  </div>
</div>
