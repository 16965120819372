import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProdNotFoundRoutingModule } from './prod-not-found-routing.module';
import { ReqProdByUserComponent } from './req-prod-by-user/req-prod-by-user.component';
import { SharedModule } from '../shared/shared.module';
import { ProductVariantListModule } from '../shared/product-variant-list/product-variant-list.module';
import { ProductVariantFormModule } from '../shared/product-variant-form/product-variant-form.module';


@NgModule({
  declarations: [ReqProdByUserComponent],
  imports: [
    CommonModule,
    ProdNotFoundRoutingModule,
    SharedModule,
    ProductVariantListModule,
    ProductVariantFormModule
  ]
})
export class ProdNotFoundModule { }
