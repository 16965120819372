import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { DeliveryPersonService } from 'src/app/settings/delivery-person-list/delivery-person.service';
// declare const L: any;
import * as L from 'leaflet';
// declare const google: any;/
import {Loader, LoaderOptions} from 'google-maps';
const options: LoaderOptions = {/* todo */};
const loader = new Loader('AIzaSyCDZ61ibrKPUFbhjTFIcfwF2g9DNcUvutI', options);

@Component({
  selector: 'app-directions',
  templateUrl: './directions.component.html',
  styleUrls: ['./directions.component.scss']
})
export class DirectionsComponent implements OnInit {
id:any;
date:any;
  order: any;
  constructor(private route: ActivatedRoute, private orderService: OrderService, private spinner: NgxSpinnerService,private toaster:ToastrService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.date = this.route.snapshot.paramMap.get('date')
console.log(this.id,this.date)
this.findOrderInfo()
// this.initMap()

  }
  findOrderInfo() {
    const data = { id: this.id, created_at: this.date }
    this.spinner.show();
    this.orderService.findOrderInfo(data).subscribe((res: any) => {
        this.spinner.hide();
        if (res.status) {
            this.order = res.data;
            console.log(this.order)
            this.initMap()
                  }
    }, (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.message, 'Error');
    })
}
initMap() {
  console.log(loader.load)
  // window.location.reload()s
  loader.load().then((google)=>{
  const map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: -34.397, lng: 150.644},
      zoom: 8,
  });
  console.log(map)
  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer();
  directionsRenderer.setMap(map);
if(map.getZoom()){
  console.log(directionsService,directionsRenderer)
  this.calculateAndDisplayRoute(directionsService, directionsRenderer);
}
else{
  window.location.reload()
}
})
 

}
calculateAndDisplayRoute(directionsService, directionsRenderer) {
  directionsService
    .route({
      origin: {
        query: `${this.order.order_items[0].store_item_variant.store.address1},${this.order.order_items[0].store_item_variant.store.address2},${this.order.order_items[0].store_item_variant.store.address3},${this.order.order_items[0].store_item_variant.store.city.name},${this.order.order_items[0].store_item_variant.store.state.name}`
      },
      destination: {
        query: `${this.order.delivery_info.address1},${this.order.delivery_info.city.name},${this.order.delivery_info.state.name}`
      },
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then((response) => {
      console.log('res',response)
      directionsRenderer.setDirections(response);
    })
    .catch((e) => window.alert("Directions request failed due to " + status));
}
}
